import React from 'react';
import { Skeleton } from '@mui/material';
import { CallInfoType, CallTagType } from 'store/calls/calls.types';
import { translate } from 'localizations';

interface IOwnProps {
	language: string;
	tagsAndFragmentsArray: {
		tags: CallTagType[];
	};
	callInfo: CallInfoType | null;
}

const CallsTags: React.FC<IOwnProps> = ({ tagsAndFragmentsArray, language, callInfo }) => {
	return (
		<div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
			{tagsAndFragmentsArray.tags.length > 0 ? (
				tagsAndFragmentsArray.tags.map((tag: CallTagType, index) => {
					return (
						<div style={{ marginRight: '10px', display: 'flex' }} key={`${tag.value}-${index}`}>
							<div
								style={{
									fontSize: '12px',
									color: '#A3AEBE',
								}}
							>
                <span>{tag.name}</span>
                <span>{tag.value && `:${tag.value}`}</span>
							</div>
						</div>
					);
				})
			) : callInfo ? (
				translate('noTags', language)
			) : (
				<div style={{ width: '100%' }}>
					<Skeleton
						style={{ maxWidth: '500px', width: '100%', marginRight: '5px' }}
						height={20}
						variant='text'
					/>
					<Skeleton
						style={{ marginTop: '10px', maxWidth: '500px', width: '100%', marginRight: '5px' }}
						height={20}
						variant='text'
					/>
				</div>
			)}
		</div>
	);
};

export default CallsTags;
