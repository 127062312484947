import { FC } from 'react';
import cls from './styles.module.scss';

interface NotifyTypeItemProps {
	icon: React.ReactNode;
	title: string;
	handleAddNewRule: (notifyType: string) => void;
}

const NotifyTypeItem: FC<NotifyTypeItemProps> = ({ icon, title, handleAddNewRule }) => (
	<div className={cls.root} onClick={() => handleAddNewRule(title)}>
		<div className={cls.icon}>{icon}</div>

		<div className={cls.title}>{title}</div>
	</div>
);

export default NotifyTypeItem;
