import { useDispatch } from 'react-redux';
import { OptionType } from 'components/common/Selects/MultiValueSelect/multiValuesSelect';
import { getUrlForDev, isDevStand } from 'utils/url';
import { getUserToken } from 'store/user/user.slice';
import { useAppSelector } from './redux';

const useUserChange = () => {
	const dispatch = useDispatch();
	const { loggedUser } = useAppSelector(state => state.user);

	const { adminToken } = JSON.parse(localStorage.getItem('adminToken') || '{}');

	const getChildUser = async (value: string) => {
		await dispatch(getUserToken(value));
	};

	const getOriginalUser = () => {
		localStorage.setItem('token', JSON.stringify({ token: adminToken }));
	}

	const handleUserChange = async (event: OptionType, customPath?: string) => {
		if (loggedUser && event) {
			const pathArray = window.location.pathname.split('/');
			const path = isDevStand(pathArray) ? pathArray[4] : pathArray[3];
			const newCustomPath = typeof customPath === 'string' ? customPath : '';
			let id: string

			if (loggedUser.id !== event.value) {
				await getChildUser(event.value);
				id = event.value;
			} else {
				getOriginalUser();
				id = loggedUser.id;
			}

			window.location.href = getUrlForDev(`${loggedUser?.language}/${id}/${newCustomPath || path}`);
		}
	};

	return { handleUserChange }
};

export default useUserChange;
