import React, { FC, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import CircularProgress from '@mui/material/CircularProgress';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';

import Input from 'components/common/Input/AuthInput/Input';
import { RootState } from 'store/store';
import { translate } from 'localizations';
import {
	getAllUserDicts,
	getAllWordInDictionary,
	getCallStt,
	updateDict,
} from 'store/calls/calls.slice';

import { useAppSelector } from 'hooks/redux';
import { createSnackbarOptions } from 'components/common/Snackbar/Snackbar';
import { useSnackbar } from 'notistack';
import { callsActions } from 'store/calls';
import { callAction, getCallInfo } from 'store/calls/actions';
import { selectCurrentCall } from 'store/calls/selectors';
import { SearchSvg } from 'components/Icons/SearchSvg';

import { buildStringByFragmentDictionary, FragmentDictionary } from '../../helpers/fragments';
import '../../style.css';

interface TextMobileStepperPropType {
	selectedText: string,
	changeSelectedText: any,
	choosedDictId: string,
	setChoosedDictId: any,
	closePopupFunc: any,
	fragmentDictionary: FragmentDictionary
}

interface TextareaComponentPropType {
	value: string,
	changeValue: any,
}

interface AllUserDictsComponentPropType {
	allUserDicts: any,
	handleNext: any,
	choosedDictIdFunc: any,
	getAllUserDictsFunc: any,
}

interface AllPhraseInDictComponentPropType {
	phrasesValue: string,
	updatePhrasesValue: any,
	choosedDictId: string,
	getAllPhraseInDict: any,
}

const MAX_ROWS_COUNT = 7;

const TextareaComponent = ({ value, changeValue }: TextareaComponentPropType) => {
	const regex = /\n/g;
	const count = (value.match(regex) || []).length;

	return (
		<textarea
			className="dictionary-textarea"
			name="selected-text"
			id=""
			rows={count <= MAX_ROWS_COUNT ? count : MAX_ROWS_COUNT}
			value={value}
			onChange={(event) => changeValue(event.target.value)}
		/>
	);
};

const AllUserDictsComponent: FC<AllUserDictsComponentPropType> = (
	{
		handleNext,
		allUserDicts,
		getAllUserDictsFunc,
		choosedDictIdFunc,
	},
) => {
	const useStyles = makeStyles(({
		searchInputInputBox: {
			position: 'relative',
			width: '100%',
			margin: '11px 0 25px',
		},
		searchInputSvgBox: {
			textAlign: 'center',
			paddingTop: '9px',
			position: 'absolute',
			top: '-7.5px',
			right: '-1px',
			width: '32px',
			height: 'calc(100% - 9px)',
		},
	}));

	const { language } = useAppSelector((state: RootState) => state.lang);
	const classes = useStyles();
	const [searchValue, setSearchValue] = useState('');

	async function chooseOneDict(id: string) {
		choosedDictIdFunc(id);
		handleNext();
	}

	useEffect(() => {
		getAllUserDictsFunc();
	}, []);

	function renderDicts(item: any) {
		return (
			<div className="dict-item" onClick={() => chooseOneDict(item.id)} key={item.id}>
				<p>{item.title}</p>
			</div>
		);
	}

	const filteredDicts = allUserDicts.filter(
		(item: any) => (
			item.title.toLowerCase().includes(searchValue.toLowerCase())
				|| item.title.toLowerCase().includes(searchValue.toLowerCase())
		),
	);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	function searchList() {
		return (
			allUserDicts.length > 0
				? (
					filteredDicts.map(renderDicts)
				) : (
					<div className="wait-dicts-loader">
						<CircularProgress />
					</div>
				)
		);
	}

	return (
		<>
			<Typography sx={{ height: 40, display: 'flex', alignItems: 'center' }}>{translate('selectTheDictionary', language)}</Typography>
			<div className={classes.searchInputInputBox}>
				<Input
					handleChange={handleChange}
					value={searchValue}
					name="search"
					type="text"
					height="35px"
					bcColor="#F8FAFC"
					border="1px solid #E3E8EF"
					label={translate('searchInputText_dicts', language)}
				/>
				<div className={classes.searchInputSvgBox}>
					<IconButton type="submit">
						<SearchSvg />
					</IconButton>
				</div>
			</div>
			<div className="dicts-block">
				{searchList()}
			</div>
		</>
	);
};

const AllPhraseInDictComponent = ({
	phrasesValue,
	updatePhrasesValue,
	choosedDictId,
	getAllPhraseInDict,
}: AllPhraseInDictComponentPropType) => {
	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions
		choosedDictId && getAllPhraseInDict();
	}, [choosedDictId]);

	return (
		phrasesValue.length > 0
			? (
				<textarea
					className="dictionary-textarea phrases-textarea"
					value={phrasesValue}
					onChange={event => updatePhrasesValue(event.target.value)}
				/>
			) : (
				<div className="wait-dicts-loader">
					<CircularProgress />
				</div>
			)
	);
};

const TextMobileStepper = ({
	selectedText,
	changeSelectedText,
	choosedDictId,
	setChoosedDictId,
	closePopupFunc, fragmentDictionary,
}: TextMobileStepperPropType) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const [activeStep, setActiveStep] = useState(0);
	const [allUserDicts, setAllUserDicts] = useState<any>([]);
	const [phrasesValue, setPhrasesValue] = useState<string>('');
	const { language } = useAppSelector((state: RootState) => state.lang);

	function getPhrasesForTextarea(massive: any) {
		return massive.join('\n');
	}

	const handleNext = (): void => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = (): void => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	async function getAllUserDictsFunc(): Promise<void> {
		const userDicts = await dispatch(getAllUserDicts());
		// @ts-ignore
		setAllUserDicts(userDicts.payload);
	}

	async function getAllPhraseInDict(): Promise<void> {
		const data = await dispatch(getAllWordInDictionary({ id: choosedDictId }));
		// @ts-ignore
		const dataPhrases = data.payload.phrases;
		// @ts-ignore
		dataPhrases.push(selectedText);
		setPhrasesValue(getPhrasesForTextarea(dataPhrases));
	}

	function updatePhrasesValue(value: string): void {
		const newValue = value.split('\n');
		setPhrasesValue(getPhrasesForTextarea(newValue));
	}

	function copyToClipBoard(): void {
		const BUILD_WITH_PHRASE_DIRECTION = true;

		const text = buildStringByFragmentDictionary(fragmentDictionary, language, BUILD_WITH_PHRASE_DIRECTION);

		navigator.clipboard.writeText(text);
		closePopupFunc();
	}

	const steps = [
		{
			label: `${translate('editPhrase', language)}`,
			description: (
				<>
					<TextareaComponent value={selectedText} changeValue={changeSelectedText} />
					<button onClick={copyToClipBoard}>{translate('copy', language)}</button>
					<AllUserDictsComponent
						handleNext={handleNext}
						choosedDictIdFunc={setChoosedDictId}
						allUserDicts={allUserDicts}
						getAllUserDictsFunc={getAllUserDictsFunc}
					/>
				</>
			),
		},
		{
			label: `${translate('addingToTheDictionary', language)}: "${selectedText}"`,
			description:
	<AllPhraseInDictComponent
		phrasesValue={phrasesValue}
		updatePhrasesValue={updatePhrasesValue}
		getAllPhraseInDict={getAllPhraseInDict}
		choosedDictId={choosedDictId}
	/>,
		},
	];

	const currentCall = useAppSelector(selectCurrentCall);
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState<boolean>(false);

	async function sendNewPhraseToDict() {
		const sendPhrases = phrasesValue.split('\n');
		setLoading(true);
		await dispatch(updateDict({
			dictId: choosedDictId,
			phrases: sendPhrases,
		}));
		enqueueSnackbar(null, createSnackbarOptions({
			type: 'success',
			text: translate('dictionaryUpdateSuccess', language),
			time: 2000,
		}));

		closePopupFunc();
		setLoading(false);

		enqueueSnackbar(null, createSnackbarOptions({
			type: 'info',
			text: translate('callAutoRetag', language),
			time: 2000,
		}));
		if (currentCall && currentCall.stt && currentCall.info) {
			const callId = currentCall.info.id;
			await dispatch(callAction({ id: callId, data: { action: 'analyze' } }));
			const newCallInfoData = await dispatch(getCallInfo({ id: callId }));// @ts-ignore
			const newCallInfo = newCallInfoData.payload;
			dispatch(callsActions.replaceCallInfoInArray(newCallInfo));
			dispatch(callsActions.setInfo(newCallInfo));
			await dispatch(getCallStt({ id: callId }));
			enqueueSnackbar(null, createSnackbarOptions({
				type: 'success',
				text: translate('callRetagged', language),
				time: 2000,
			}));
		}
	}

	// const maxSteps = steps.length;

	return (
		<Box sx={{ width: 500, flexGrow: 1 }} className="popup-stepper-content">
			<div style={{ position: 'absolute', top: 5, right: 5, cursor: 'pointer' }}>
				<CloseIcon onClick={closePopupFunc} />
			</div>
			<Paper
				square
				elevation={0}
				sx={{
					display: 'flex',
					alignItems: 'center',
					height: 50,
					pl: 2,
					bgcolor: 'background.default',
				}}
			>
				<Typography>{steps[activeStep].label}</Typography>
			</Paper>
			<Box sx={{ height: 255, width: '100%', p: 2, paddingTop: '0px' }}>
				{steps[activeStep].description}
			</Box>
			<div className="menu-stepper">
				<div className="left-block">
					{
						activeStep > 0 && (
							<Button size="small" onClick={handleBack}>
								{theme.direction === 'rtl' ? (
									<KeyboardArrowRight />
								) : (
									<KeyboardArrowLeft />
								)}
								{translate('back', language)}
							</Button>
						)
					}
				</div>
				<div className="right-block">
					{
						activeStep === 1 && (
							<LoadingButton
								loading={loading}
								onClick={sendNewPhraseToDict}
							>
								{translate('addToTheDictionary', language)}
							</LoadingButton>
						)
					}
				</div>
			</div>
		</Box>
	);
};

export default TextMobileStepper;
