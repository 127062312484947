import { useAppSelector } from './redux';
import { RootState } from '../store/store';

const useAccessRightsVisibility = () => {
	const { loggedUser, childUser, user } = useAppSelector((state: RootState) => state.user);

	return () => {
		// for logged admin
		if (loggedUser?.role === 'admin' && childUser === null) {
			return false;
		}
		if (loggedUser?.role === 'admin' && childUser?.role === 'admin') {
			return false;
		}
		if (loggedUser?.role === 'admin' && ['manager', 'user', 'operator'].includes(childUser?.role || '')) {
			return true;
		}

		// for logged manager
		// TODO: сейчас пользователь, в которого провалился менеджер записывается в user,
		//  а не в childUser. Это связано с проверкой на adminToken в src/Routes стр. 43
		if (loggedUser?.role === 'manager' && user?.role === 'manager') {
			return false;
		}
		if (loggedUser?.role === 'manager' && ['user', 'operator'].includes(user?.role || '')) {
			return true;
		}

		// for logged user
		if (loggedUser?.role === 'user') {
			return false;
		}

		// for logged operator
		if (loggedUser?.role === 'operator') {
			return false;
		}

		return false;
	}
};

export default useAccessRightsVisibility;
