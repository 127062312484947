import { FC, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { OptionType } from 'components/common/Selects/LabelSelect/LabelSelect';
import MultiValueSelect from 'components/common/Selects/MultiValueSelect/MultiValueSelect';
import cls from './styles.module.scss';

interface MultiSelectWithHeaderProps {
	title: string;
	options?: OptionType[];
	initialValue?: OptionType[];
	onSelectedChatsChange?: (newSelectedChats: OptionType[]) => void;
}

const MultiSelectWithHeader: FC<MultiSelectWithHeaderProps> = ({
	title,
	initialValue,
	options = [],
	onSelectedChatsChange,
}) => {
	const [selectedOption, setSelectedOption] = useState<OptionType[]>(initialValue || []);

	useEffect(() => {
		if (initialValue) {
			setSelectedOption(initialValue);
		}
	}, [initialValue, options]);

	const handleValueChange = (value: OptionType[]): void => {
		setSelectedOption(value);

		if (onSelectedChatsChange) {
			onSelectedChatsChange(value);
		}
	};

	return (
		<div className={cls.root}>
			<Typography className={cls.title}>{title}</Typography>

			<MultiValueSelect
				criteriaKey=''
				width="100%"
				value={selectedOption}
				options={options}
				selectType="multiString"
				valueHandler={handleValueChange}
			/>
		</div>
	);
};

export default MultiSelectWithHeader;
