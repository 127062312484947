import React, { FC, SVGProps } from 'react';

type StopIconProps = SVGProps<SVGSVGElement> & { fillColor?: string };

export const StopIcon: FC<StopIconProps> = ({ fillColor = '#738094', ...props }) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 13C10.7614 13 13 10.7614 13 8C13 6.51917 12.3562 5.18869 11.3333 4.27316C10.4487 3.48142 9.28059 3 8 3C5.23858 3 3 5.23858 3 8C3 9.28059 3.48142 10.4487 4.27316 11.3333C5.18869 12.3562 6.51917 13 8 13ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
			fill={fillColor}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.97205 11.321L11.3054 3.6543L12.028 4.34552L4.69469 12.0122L3.97205 11.321Z"
			fill={fillColor}
		/>
	</svg>
);
