import { configureStore, combineReducers, Middleware } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { integrationsAPI } from 'services/IntegrationsService';
import { notificationsAPI } from 'services/NotificationsService';
import { tgChatsAPI } from 'services/tgChatsService';
import calls from './calls';
import { langSlice, mrSlice, userSlice, searchSlice, tasksSlice, reportsSlice, integrations } from './index';
import checkLists from './checkLists';

const rootReducer = combineReducers({
	lang: langSlice.reducer, // common*, общие данные приложения
	user: userSlice.reducer, // данные пользователя
	calls, // данные о звонках
	search: searchSlice.reducer, // данные поисков
	reports: reportsSlice.reducer, // данные отчетов
	tasks: tasksSlice.reducer, // данные задач
	mr: mrSlice.reducer, // данные тегов и словарей
	checkLists, // данные тегов и словарей
	integrations,
	[integrationsAPI.reducerPath]: integrationsAPI.reducer,
	[notificationsAPI.reducerPath]: notificationsAPI.reducer,
	[tgChatsAPI.reducerPath]: tgChatsAPI.reducer,
});

const middleware: Middleware[] = [integrationsAPI.middleware, notificationsAPI.middleware, tgChatsAPI.middleware];

if (process.env.NODE_ENV === 'development') {
	const loggerMiddleware = createLogger({
		collapsed: true,
		diff: true,
	});

	middleware.push(loggerMiddleware);
}

export const setupStore = () =>
	configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false,
			}).concat(middleware),
	});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
