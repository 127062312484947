import {CallSttFragmentType, CallSttWordType} from 'store/calls/calls.types';
import { translate } from 'localizations';

export type FragmentDictionary = Record<
	number | string,
	{
		words: CallSttWordType[];
		direction: string;
	}
>;

export function buildStringByFragmentDictionary(
	dictionary: FragmentDictionary,
	language: string,
	buildWithPhraseDirection = false
): string {
	let str = '';

	for (let fragmentInd in dictionary) {
		if (str) {
			str += '\n';
		}

		if (buildWithPhraseDirection) {
			const condition = dictionary[fragmentInd].direction === 'client';

			str += condition ? translate('callsCustomer', language) : translate('callsEmployee', language);
			str += ':\n';
		}

		str += dictionary[fragmentInd].words.map((x) => x.word).join(' ');
	}

	return str;
}

function getFragmentIndexes(
	startElement: HTMLElement | null | undefined,
	endElement: HTMLElement | null | undefined
): {
	startElementFragmentIndex: number;
	startElementPhraseIndex: number;
	endElementFragmentIndex: number;
	endElementPhraseIndex: number;
} {
	let startElementFragmentIndex = Number(startElement?.dataset['fragmentRowIndex'] as unknown as number);
	let startElementPhraseIndex = Number(startElement?.dataset['fragmentCallIndex'] as unknown as number);

	let endElementFragmentIndex = Number(endElement?.dataset['fragmentRowIndex'] as unknown as number);
	let endElementPhraseIndex = Number(endElement?.dataset['fragmentCallIndex'] as unknown as number);

	if (startElementFragmentIndex > endElementFragmentIndex) {
		[endElementFragmentIndex, startElementFragmentIndex] = [startElementFragmentIndex, endElementFragmentIndex];
		[endElementPhraseIndex, startElementPhraseIndex] = [startElementPhraseIndex, endElementPhraseIndex];
	} else if (startElementFragmentIndex === endElementFragmentIndex) {
		if (startElementPhraseIndex > endElementPhraseIndex) {
			[endElementPhraseIndex, startElementPhraseIndex] = [startElementPhraseIndex, endElementPhraseIndex];
		}
	}

	return {
		startElementFragmentIndex,
		startElementPhraseIndex,
		endElementPhraseIndex,
		endElementFragmentIndex,
	};
}

export function buildFragmentDictionary(
	startElement: HTMLElement | null | undefined,
	endElement: HTMLElement | null | undefined,
	fragments: CallSttFragmentType[]
): FragmentDictionary {
	const dictionary = {} as FragmentDictionary;

	const { startElementFragmentIndex, startElementPhraseIndex, endElementPhraseIndex, endElementFragmentIndex } =
		getFragmentIndexes(startElement, endElement);

	if (startElementFragmentIndex === endElementFragmentIndex) {
		const fragment = fragments[startElementFragmentIndex];

		dictionary[startElementFragmentIndex] = {
			direction: fragment.direction,
			words: fragment.words.slice(startElementPhraseIndex, endElementPhraseIndex + 1),
		};
	} else {
		for (let i = startElementFragmentIndex; i <= endElementFragmentIndex; i++) {
			const fragment = fragments[i];
			if (i === startElementFragmentIndex) {
				dictionary[i] = {
					direction: fragment.direction,
					words: fragment.words.slice(startElementPhraseIndex, fragments[i].words.length),
				};
			} else if (i === endElementFragmentIndex) {
				dictionary[i] = {
					direction: fragment.direction,
					words: fragment.words.slice(0, endElementPhraseIndex + 1),
				};
			} else {
				dictionary[i] = {
					direction: fragment.direction,
					words: fragment.words,
				};
			}
		}
	}

	return dictionary;
}
