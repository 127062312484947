import { useRef, useState } from 'react';
import { MultiValue } from 'react-select';
import { OptionType } from 'components/common/Selects/MultiValueSelect/multiValuesSelect';
import { RootState } from 'store/store';
import { translate } from 'localizations';
import { useAppSelector } from './redux';

export type selectAction = 'clear' | 'select-option' | 'deselect-option' | 'remove-value' | 'pop-value' | 'create-option';

interface IHandleSelectClick {
    newValues: MultiValue<OptionType>,
    action: selectAction;
    option: OptionType | undefined;
    removedValue: OptionType | undefined;
    customVals: OptionType[];
}
const useSelectAll = (
	options: OptionType[],
	selectAll: boolean,
	selectAllName: string,
	customValues: OptionType[],
) => {
	const { language } = useAppSelector((state: RootState) => state.lang);
	const [localValue, setLocalValue] = useState<OptionType[]>([]);

	const valueRef = useRef(localValue);
	valueRef.current = localValue;

	const selectAllOption = {
		value: 'selectAllOptions',
		label: selectAllName
			? `${translate('all', language)} ${selectAllName}`
			: translate('selectAllOptions', language),
	};

	const allOptionsSelected = () => {
		const everyValueIsOption = valueRef.current
			.filter(item => options.some(opt => opt.value === item.value)).length === options.length;
		const hasSelectAllOption = valueRef.current.some(item => item.value === selectAllOption.value);

		return hasSelectAllOption || everyValueIsOption;
	};
	const isSelectAllSelected = () => valueRef.current.length > 0 && allOptionsSelected();
	const isOptionSelected = (option: OptionType) => valueRef.current.some(item => item.value === option.value) || isSelectAllSelected();
	const selectedInInput = isSelectAllSelected() ? [selectAllOption, ...customValues] : localValue;

	const allOptions = selectAll ? [selectAllOption, ...options] : options;
	const inputSelectedValues = selectAll ? selectedInInput : localValue;

	const getDataFromSelectedOption = (
		action: selectAction,
		option: OptionType | undefined,
		removedValue: OptionType | undefined,
	) => {
		const isSelectedOption = option?.value === selectAllOption.value;
		const isSelectAct = action === 'select-option';
		const isDeselectAct = action === 'deselect-option';
		const isRemoveAct = action === 'remove-value';
		const isSelectAll = isSelectedOption && isSelectAct;
		const isRemoveAll = (isSelectedOption && isDeselectAct)
            || (isRemoveAct && removedValue?.value === selectAllOption.value);
		const isOneDeselectedFomAll = isDeselectAct && isSelectAllSelected();

		return {
			isSelectAll,
			isRemoveAll,
			isOneDeselectedFomAll,
		};
	};

	const getCustomOptions = (values: MultiValue<OptionType>) => {
		const customOptions: OptionType[] = [];
		values.forEach(item => {
			if (!options.some(opt => opt.value === item.value || item.value === selectAllOption.value)) {
				customOptions.push(item);
			}
		});

		return customOptions;
	};
	const handleSelectClick = ({
		newValues,
		action,
		option,
		removedValue,
		customVals,
	}: IHandleSelectClick) => {
		const hasCustomValues = customVals.length > 0;
		const { isSelectAll, isRemoveAll, isOneDeselectedFomAll } = getDataFromSelectedOption(
			action,
			option,
			removedValue,
		);

		if (isSelectAll) {
			setLocalValue(hasCustomValues ? options : [...customVals, ...options]);
		} else if (isRemoveAll) {
			setLocalValue(hasCustomValues ? customVals : []);
		} else if (isOneDeselectedFomAll) {
			const newItems = options.filter((item) => item.value !== option?.value);
			setLocalValue(hasCustomValues ? [...newItems, ...customVals] : newItems);
		} else {
			setLocalValue([...newValues]);
		}
	};

	return {
		selectAllOption,
		allOptions,
		inputSelectedValues,
		localValue,
		methods: {
			setLocalValue,
			isOptionSelected,
			isSelectAllSelected,
			getCustomOptions,
			handleSelectClick,
		},
	};
};

export default useSelectAll;
