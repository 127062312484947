import { FC } from 'react';
import classNames from 'classnames';
import NewInput from 'components/common/Input/NewInput/NewInput';
import cls from './styles.module.scss';
import TitleWithTooltip from '../TitleWithTooltip/TitleWithTooltip';

export interface InputWithHeaderProps {
	externalValue?: string;
	tooltipTitle: string;
	placeholder: string;
	title: string;
	onChangeValue: (value: string) => void;
	inputType?: string;
	inputClassName?: string;
}

const InputWithHeader: FC<InputWithHeaderProps> = ({
	externalValue = '',
	onChangeValue,
	tooltipTitle,
	placeholder,
	title,
	inputType,
	inputClassName,
}) => {
	const handleChangeValue = (e: React.ChangeEvent<any>) => {
		const newValue = e.target.value;
		onChangeValue(newValue);
	};

	return (
		<div className={cls.root}>
			<TitleWithTooltip title={title} tooltipTitle={tooltipTitle} theme="gray" size="md" />
			<NewInput
				inputType={inputType}
				className={classNames(cls.input, inputClassName)}
				placeholder={placeholder}
				value={externalValue}
				handleChange={handleChangeValue}
			/>
		</div>
	);
};

export default InputWithHeader;
