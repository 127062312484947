import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const select = (state: RootState) => {
	return state.calls;
};

export const selectCalls = createSelector(select, (calls) => {
	return calls.callsData;
});

export const selectCallsInfo = createSelector(select, (calls) => {
	return calls.callsInfo;
});

export const selectCallsLimitOnOnePage = createSelector(select, (calls) => {
	return calls.callsLimitOnOnePage;
});

export const selectCallsExpandedId = createSelector(select, (calls) => {
	return calls.expandedId;
});

export const selectCallsSort = createSelector(select, (calls) => {
	return calls.sort;
});

export const selectCurrentCall = createSelector(select, (calls) => {
	return calls.currentCall;
});

export const selectCallById = (id: string) =>
	createSelector(select, (calls) => {
		const candidate = (calls.callsInfo || [])?.find((x) => x?.id === id);

		return candidate ?? null;
	});

export const selectIsCallsLoad = createSelector(select, (calls) => {
	return calls.isCallsLoading;
});


export const selectFilterCallsHash = createSelector(select, (calls) => {
	return calls.filterHash;
});

export const selectSoloPage = createSelector(select, (calls) => {
	/**
	 * @unknown
	 */
	// @ts-ignore
	return calls.soloPage;
});
