// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';

/**
 * Приведение даты с бэка к виду - DD.MM.YYYY, HH:MM:SS
 */
export function datetimeToString(datetimeString: string): string {
	return new Date(datetimeString).toLocaleString();
}

/**
 * Приведение даты с бэка к виду - DD.MM.YYYY HH:mm:ss
 */

export const convertDateFormString = (date: Date | string): string | null => {
	if (!date) {
		return null;
	}

	const momentDate = moment(date);

	if (!momentDate.isValid()) {
		return null;
	}

	return momentDate.format('DD.MM.YYYY HH:mm:ss');
};

/**
 * Приведение секунд к виду - HH:mm:ss
 */

export const convertSecondsToFormat = (seconds: number) => new Date(seconds * 1000).toISOString().slice(11, 19);

/**
 * Приведение секунд к минутам с округлением
 */
export const convertSecondsToMin = (seconds: number) => Math.round(seconds / 60);
