/* eslint-disable */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import { instance } from 'api/api.config';
import { getTokens } from 'utils/tokens';
import { criteriaWithComplexValues } from 'configs/searchCriteria';
import { removeAuthToken } from './auth.thunks';
import {
	AccessRights,
	AccessRightType,
	CallsLimitFilter,
	ChildUserType,
	QuotasType,
	ReportLimit,
	UserType,
} from './user.types';
import { langSlice } from '../lang/lang.slice';

export const getUser = createAsyncThunk('user/getUser', async (payload: { id: string }, thunkAPI) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.get<UserType>(`user/${payload.id}`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		return data;
	} catch (e) {
		console.log(e);
	}
});

interface CreateUserError {
	detail: string;
}

// TODO: убрать any Необходима типизиция санок
export const createUser = createAsyncThunk<any, { user: any }, { rejectValue: CreateUserError }>(
	'user/createUser',
	async (payload, thunkAPI) => {
		try {
			const { token } = JSON.parse(localStorage.getItem('token') || '{}');
			const { data } = await instance.post<any>(`user`, payload.user, {
				headers: { Authorization: `Bearer ${token}` },
			});
			return data;
			// TODO: убрать any Необходима типизиция санок
		} catch (err: any) {
			if (!err.response) {
				throw err;
			}
			throw err.response.status;
		}
	},
);

export const updateUser = createAsyncThunk('user/updateUser', async (payload: UserType, thunkAPI) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.put(`user/${payload.id}`, payload, {
			headers: { Authorization: `Bearer ${token}` },
		});
		return data;
	} catch (e) {
		console.log(e);
	}
});

export const deleteUser = createAsyncThunk('user/deleteUser', async (payload: { id: string }, thunkAPI) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.delete<string>(`user/${payload.id}`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		return data;
	} catch (e) {
		console.log(e);
	}
});

export const getAccessRightsDescription = createAsyncThunk(
	'user/getAccessRightsDescription',
	async (payload: { id: string }, thunkAPI) => {
		try {
			const { token } = JSON.parse(localStorage.getItem('token') || '{}');
			const { data } = await instance.get(`user/${payload.id}/access_rights_description`, {
				headers: { Authorization: `Bearer ${token}` },
			});
			return data;
		} catch (e) {
			console.log(e);
		}
	},
);

export const getMe = createAsyncThunk('user/getMe', async (payload: { token?: string }, thunkAPI) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.get<UserType>('user/me?with_quota=true', {
			headers: { Authorization: `Bearer ${token ? token : payload.token}` },
		});
		return data;
	} catch (e) {
		thunkAPI.dispatch(removeAuthToken());
	}
});

export const getUserFilter = createAsyncThunk(
	'user/getUserFilter',
	async (payload: { token: string }, { dispatch }) => {
		try {
			const { token } = JSON.parse(localStorage.getItem('token') || '{}');
			const { data } = await instance.get<UserType>(
				'user/filter?with_quota=true',
				{ headers: { 'Authorization': `Bearer ${payload.token ? payload.token : token}` },
				});
			return data;
		} catch (e) {
			dispatch(removeAuthToken());
		}
	},
);

export const getChildUsers = createAsyncThunk('user/getChildUsers', async (_, { dispatch }) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.get<ChildUserType[]>(`users/?with_childs=true&with_quota=true`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		dispatch(userSlice.actions.setUsers(data));
		return data;
	} catch (e) {
		console.log('Виновен user.slice str. 23');
	}
});

export const getUserInfo = createAsyncThunk('user/getUsers', async (payload: ChildUserType, thunkAPI) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const response = await instance.get<UserType>(`user/${payload.id}`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		return response.data;
	} catch (error) {
		console.log('Виновен user.slice str. 58');
	}
});

export const getUserInfoById = createAsyncThunk('user/getUsers', async (payload: string, thunkAPI) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const response = await instance.get<UserType>(`user/${payload}`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		return response.data;
	} catch (error) {
		console.log('Виновен user.slice str. 58');
	}
});

export const getUsersWithoutChildren = createAsyncThunk('user/getUsers', async (payload, thunkAPI) => {
	const { token } = JSON.parse(localStorage.getItem('token') || '{}');
	const response = await instance.get<ChildUserType[]>(`users/?with_childs=false`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	thunkAPI.dispatch(userSlice.actions.setOnlyUsers(response.data));
	return response.data;
});

export const getUsers = createAsyncThunk('user/getUsers', async (payload, thunkAPI) => {
	const { token } = JSON.parse(localStorage.getItem('token') || '{}');
	const response = await instance.get<ChildUserType[]>(`users/?with_childs=true&with_quota=true`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	thunkAPI.dispatch(userSlice.actions.setUsers(response.data));
	return response.data;
});

export const getOperators = createAsyncThunk('user/getOperators', async (payload: { id: string }, thunkAPI) => {
	const { token } = JSON.parse(localStorage.getItem('token') || '{}');
	const response = await instance.get<ChildUserType[]>(`user/${payload.id}/operators`, {
		headers: { Authorization: `Bearer ${token}` },
	});
	return response.data;
});

export const getUserToken = createAsyncThunk('user/getChildUsers', async (payload: string, thunkAPI) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.get(`set_filter_user?user_id=${payload ? payload : ''}`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		localStorage.setItem(
			'token',
			JSON.stringify({
				token: data.access_token,
			}),
		);
		return data;
	} catch (error) {
		console.log('Виновен user.slice str. 73');
	}
});

export const getChildUser = createAsyncThunk('user/getChildUser', async (payload: string | void, thunkAPI) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.get(`user/filter`, {
			headers: { Authorization: `Bearer ${payload ? payload : token}` },
		});
		thunkAPI.dispatch(userSlice.actions.setChildUser(data));
		return data;
	} catch (error) {
		console.log('Виновен user.slice str. 84');
	}
});

export const getMeAccessRights = createAsyncThunk('user/getMeAccessRights', async (_, thunkAPI) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.get(`user/me/access_rights`, { headers: { Authorization: `Bearer ${token}` } });
		thunkAPI.dispatch(userSlice.actions.setAccessRights(data));
		return data;
	} catch (error) {
		console.log('Виновен user.slice str. 93');
	}
});

export const getUserAccessRights = createAsyncThunk('user/getUserAccessRights', async (id: string, { dispatch }) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.get(`user/${id}/access_rights`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		return data;
	} catch (error) {
		console.log('Виновен user.slice str. 93');
	}
});

export const getUsersAllReports = createAsyncThunk('user/getUsersAllReports', async (id: string, thunkAPI) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.get(`user/${id}/all_reports`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		return data;
	} catch (error) {
		return thunkAPI.rejectWithValue(error);
	}
});

export const updateAccessRights = createAsyncThunk(
	'user/updateAccessRights',
	async (payload: { rights: AccessRights; id: string }) => {
		try {
			const { token } = JSON.parse(localStorage.getItem('token') || '{}');
			const { data } = await instance.put(`user/${payload.id}/access_rights`, payload.rights, {
				headers: { Authorization: `Bearer ${token}` },
			});
			return data;
		} catch (error) {
			console.log('Виновен user.slice str. 93');
		}
	},
);

export const getManagerUsers = createAsyncThunk('user/getManagerUsers', async (userId: string, thunkApi) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.get(`user/${userId}/user_limitation`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		return data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error);
	}
});

export const updateManagerUsers = createAsyncThunk(
	'user/updateManagerUsers',
	async (requestData: { userId: string; body: string[] }, thunkApi) => {
		try {
			const { userId, body } = requestData;
			const { token } = JSON.parse(localStorage.getItem('token') || '{}');
			const { data } = await instance.put(`user/${userId}/user_limitation`, body, {
				headers: { Authorization: `Bearer ${token}` },
			});

			return data;
		} catch (error: any) {
			return thunkApi.rejectWithValue(error);
		}
	},
);

export const getReportsLimits = createAsyncThunk('user/getReportsLimits', async (userId: string, thunkApi) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.get(`user/${userId}/report_limitation`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		return data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error);
	}
});

export const updateReportsLimits = createAsyncThunk(
	'user/updateReportsLimits',
	async (payload: { id: string; body: string[] }, thunkAPI) => {
		try {
			const { id, body } = payload;
			const { token } = JSON.parse(localStorage.getItem('token') || '{}');
			const { data } = await instance.put(`user/${id}/report_limitation`, body, {
				headers: { Authorization: `Bearer ${token}` },
			});
			return data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const getCallsLimits = createAsyncThunk('user/getCallsLimits', async (userId: string, thunkApi) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.get(`user/${userId}/limitations`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		return data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error);
	}
});

export const updateCallsLimits = createAsyncThunk(
	'user/updateCallsLimits',
	async (payload: { userId: string; requestData: CallsLimitFilter[] }, thunkApi) => {
		try {
			const { userId, requestData } = payload;
			const { token } = JSON.parse(localStorage.getItem('token') || '{}');
			const { data } = await instance.put(`user/${userId}/limitations`, requestData, {
				headers: { Authorization: `Bearer ${token}` },
			});

			return data;
		} catch (error: any) {
			return thunkApi.rejectWithValue(error);
		}
	},
);

export const getQuotasOfUser = createAsyncThunk('user/getQuotasOfUser', async (payload: ChildUserType, thunkAPI) => {
	const { token } = JSON.parse(localStorage.getItem('token') || '{}');
	const response = await instance.get(`user/${payload.id}/quotas`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	thunkAPI.dispatch(userSlice.actions.setQuotas(response.data));
});

export const getGivenNamesQuotas = createAsyncThunk(
	'user/getGivenNameQuotas',
	async (payload: QuotasType[], thunkAPI) => {
		try {
			let names: any = [];
			let promiseArray = [];
			for (let i = 0; i < payload.length; i++) {
				// @ts-ignore
				promiseArray.push(thunkAPI.dispatch(getUserInfoById(payload[i].given_by)));
			}
			const promiseResult = await Promise.all(promiseArray);
			promiseResult.forEach((item) => {
				const promiseItem = item.payload; //@ts-ignore
				if (promiseItem) names.push(promiseItem.login);
			}); // @ts-ignore
			thunkAPI.dispatch(userSlice.actions.setGivenByNamesQuotas(names));
		} catch (error) {
			console.log(error);
		}
	},
);

export const deleteQuota = createAsyncThunk(
	'user/deleteQuota',
	async (payload: { userId: string; quotaId: string }, thunkAPI) => {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const response = await instance.delete(`user/${payload.userId}/quota/${payload.quotaId}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return response.data;
	},
);

export const addQuota = createAsyncThunk(
	'user/addQuota',
	async (payload: { userId: string; requestData: any }, thunkAPI) => {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const requestData = payload.requestData;
		const response = await instance.post(`user/${payload.userId}/quota`, requestData, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return response.data;
	},
);

export const getSTTLanguages = createAsyncThunk('user/getSTTLanguages', async (_payload, thunkAPI) => {
	const { token } = JSON.parse(localStorage.getItem('token') || '{}');

	const response = await instance.get(`stt/get_all_languages`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	thunkAPI.dispatch(userSlice.actions.setSTTLanguages(response.data));
	return response.data;
});

export const getSTTEngines = createAsyncThunk('user/getSTTLanguages', async (payload: string, thunkAPI) => {
	const { token } = JSON.parse(localStorage.getItem('token') || '{}');

	const response = await instance.get(`stt/${payload}/engines`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	thunkAPI.dispatch(userSlice.actions.setSTTEngines(response.data));
	return { data: response.data, language: payload };
});

export const getSTTModels = createAsyncThunk(
	'user/getSTTModels',
	async (payload: { language: string | null; engine: string }, thunkAPI) => {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');

		const response = await instance.get(`stt/${payload.language}/${payload.engine}/models`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		thunkAPI.dispatch(userSlice.actions.setSTTModels(response.data));
		return response.data;
	},
);

export const getTimezones = createAsyncThunk('user/getTimezones', async (_, thunkAPI) => {
	try {
		const { token } = getTokens();
		const response = await instance.get<string[]>('/all_timezones', {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return response.data;
	} catch (error) {
		thunkAPI.dispatch(
			langSlice.actions.setSnackbar({
				type: 'error',
				text: "We can't give you groups data",
				time: 2000,
			}),
		);
	}
});

export type OptionType = {
	value: string;
	label: string;
};

type InitialStateType = {
	// авторизация
	error: null | string;
	isAuth: boolean | 'loading';

	// пользователь
	user: UserType | null;
	loggedUser: UserType | null;
	accessRights: AccessRights | null;
	users: ChildUserType[] | null;
	onlyUsers: ChildUserType[] | null;
	childUser: UserType | null;

	// квоты
	quotas: QuotasType[];

	// выбор движка
	STTLanguages: OptionType[];
	STTEngines: OptionType[];
	STTModels: OptionType[];
	addressBook: string | null;
	isAddressBookLoading: boolean;

	allTimezones: string[];
	availableRightsLoading: boolean;
	availableRights: AccessRightType[];
	reportsLimitsLoading: boolean;
	reportsLimits: string[];
	allUserReports: AccessRightType[];

	operators: UserType[];
	operatorsLoading: boolean;
	selectedEmployee: UserType | null;
	callsLimitsLoading: boolean;
	callsLimits: CallsLimitFilter[];
	managerUsersLoading: boolean;
	managerUsers: AccessRightType[];
};

const initialState: InitialStateType = {
	// авторизация
	isAuth: 'loading',
	error: null,

	// пользователь
	user: null,
	// пользователь, под которым был осуществлен вход
	loggedUser: null,
	accessRights: null,
	users: null,
	onlyUsers: null,
	childUser: null,

	quotas: [],

	STTLanguages: [],
	STTEngines: [],
	STTModels: [],
	addressBook: null,
	isAddressBookLoading: false,

	allTimezones: [],
	availableRightsLoading: true,
	availableRights: [],
	reportsLimitsLoading: true,
	reportsLimits: [],
	allUserReports: [],

	operators: [],
	operatorsLoading: false,
	selectedEmployee: null,
	callsLimitsLoading: true,
	callsLimits: [],
	managerUsersLoading: true,
	managerUsers: [],
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		// авторизация
		setAuth(state, action: PayloadAction<boolean | 'loading'>) {
			state.isAuth = action.payload;
		},
		setError(state, action: PayloadAction<string | null>) {
			state.error = action.payload;
		},
		setCurrentAddressBook(state, action: PayloadAction<string | null>) {
			state.addressBook = action.payload;
		},

		setAddressBookLoading(state, action: PayloadAction<boolean>) {
			state.isAddressBookLoading = action.payload;
		},

		// пользователь
		setCurrentUser(state, action: PayloadAction<UserType | null>) {
			state.user = action.payload;
		},
		setChildUser(state, action: PayloadAction<UserType | null>) {
			state.childUser = action.payload;
		},
		setAccessRights(state, action: PayloadAction<AccessRights>) {
			state.accessRights = action.payload;
		},
		setAccessRight(state, action: PayloadAction<{ right: string; value: boolean }>) {
			// @ts-ignore
			if (state.accessRights) state.accessRights[action.payload.right] = action.payload.value;
		},
		setChildUserAccessRightItem(state, action: PayloadAction<{ right: string; value: boolean }>) {
			// @ts-ignore
			if (state.accessRights) state.childUserAccessRights[action.payload.right] = action.payload.value;
		},
		setUsers(state, action: PayloadAction<ChildUserType[]>) {
			state.users = action.payload;
		},
		setOnlyUsers(state, action: PayloadAction<ChildUserType[]>) {
			state.onlyUsers = action.payload;
		},

		// квоты
		setQuotas(state, action: PayloadAction<QuotasType[]>) {
			state.quotas = action.payload;
		},
		setGivenByNamesQuotas(state, action: PayloadAction<string[]>) {
			const quotas = cloneDeep(state.quotas);
			quotas.forEach((item, index) => {
				item.given_by_name = action.payload[index];
			});
			state.quotas = quotas;
		},

		// обработка текста
		setSTTLanguages(state, action: PayloadAction<any>) {
			state.STTLanguages = action.payload;
		},
		setSTTEngines(state, action: PayloadAction<any>) {
			state.STTEngines = action.payload;
		},
		setSTTModels(state, action: PayloadAction<any>) {
			state.STTModels = action.payload;
		},
		setSelectedEmployee(state, action: PayloadAction<UserType | null>) {
			state.selectedEmployee = action.payload;
		},
	},
	extraReducers: {
		[getTimezones.fulfilled.type]: (state, action: PayloadAction<string[]>) => {
			state.allTimezones = action.payload;
		},
		[getAccessRightsDescription.pending.type]: (state) => {
			state.availableRightsLoading = true;
		},
		[getAccessRightsDescription.fulfilled.type]: (state, action: PayloadAction<AccessRightType[]>) => {
			state.availableRightsLoading = false;
			state.availableRights = action.payload;
		},
		[getAccessRightsDescription.rejected.type]: (state) => {
			state.availableRightsLoading = false;
		},
		[getUsersAllReports.fulfilled.type]: (state, action: PayloadAction<ReportLimit[]>) => {
			state.allUserReports = action.payload.map((item) => ({
				key: item.id,
				title: item.name,
			}));
		},
		[getUsersAllReports.rejected.type]: (state) => {
			state.allUserReports = [];
		},
		[getReportsLimits.pending.type]: (state) => {
			state.reportsLimitsLoading = true;
		},
		[getReportsLimits.fulfilled.type]: (state, action: PayloadAction<string[]>) => {
			state.reportsLimitsLoading = false;
			state.reportsLimits = action.payload;
		},
		[getReportsLimits.rejected.type]: (state) => {
			state.reportsLimitsLoading = false;
		},
		[getMe.fulfilled.type]: (state, action: PayloadAction<UserType>) => {
			state.loggedUser = action.payload;
		},
		[getOperators.pending.type]: (state) => {
			state.operatorsLoading = true;
		},
		[getOperators.fulfilled.type]: (state, action: PayloadAction<UserType[]>) => {
			state.operatorsLoading = false;
			state.operators = action.payload;
		},
		[getOperators.rejected.type]: (state) => {
			state.operatorsLoading = false;
		},
		[getCallsLimits.pending.type]: (state) => {
			state.callsLimitsLoading = true;
		},
		[getCallsLimits.fulfilled.type]: (state, action: PayloadAction<CallsLimitFilter[]>) => {
			state.callsLimitsLoading = false;
			state.callsLimits = action.payload
				? action.payload.map((filter) => {
						const newItems = filter.items.map((item, i) => {
							if (criteriaWithComplexValues.includes(item.key)) {
								return {
									...item,
									conditionItemId: i,
								};
							}
							return item;
						});
						return { ...filter, items: newItems };
				  })
				: [];
		},
		[getCallsLimits.rejected.type]: (state) => {
			state.callsLimitsLoading = false;
		},
		[getManagerUsers.pending.type]: (state) => {
			state.managerUsersLoading = true;
		},
		[getManagerUsers.fulfilled.type]: (state, action: PayloadAction<string[]>) => {
			state.managerUsersLoading = false;
			state.managerUsers = action.payload
				? action.payload.map((item) => ({
						key: item,
						title: item,
				  }))
				: [];
		},
		[getManagerUsers.rejected.type]: (state) => {
			state.managerUsersLoading = false;
		},
	},
});
