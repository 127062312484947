import React, { FC } from 'react';

const CaretDown: FC<React.SVGProps<SVGSVGElement>> = (props) => (
	<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.087 6.73374C11.4159 6.37872 11.4159 5.8031 11.087 5.44812L6.59576 0.599607C6.26689 0.244576 5.73378 0.244576 5.40491 0.599607L0.913637 5.44812C0.584777 5.8031 0.584777 6.37872 0.913637 6.73374C1.2425 7.08875 1.7757 7.08875 2.10456 6.73374L6.00034 2.5281L9.89615 6.73374C10.225 7.08875 10.7581 7.08875 11.087 6.73374Z"
			fill="#722ED1"
		/>
	</svg>
);

export default CaretDown;
