import { makeStyles } from '@mui/styles';
import variables from 'styles/variables.scss';

export const useStyles = makeStyles({
	titleFlex: {
		display: 'flex',
		alignItems: 'center',
		padding: '5px 0',
	},
	select: {
		padding: '12px 0',
		textAlign: 'left',
		color: variables.gray_9,
	},
	selectTop: {
		padding: '0 12px 2px 12px',
		borderBottom: `1px solid ${variables.gray_4}`,
	},
	selectBottom: {
		padding: '0 12px',
	},
	titlePd: {
		paddingLeft: '8px',
		color: variables.gray_9,
		'&:hover': {
			color: variables.blue_6,
		},
	},
	selectGrayTitle: {
		fontSize: '12px',
		lineHeight: '20px',
		color: variables.gray_6,
		padding: '8px 0 4px 0',
		marginBottom: '12px',
	},
	searchInput: {
		marginBottom: '12px',
	},
	reportsLists: {
		maxHeight: '238px',
		height: '100%',
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			width: '4px',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: variables.gray_4,
			borderRadius: '4px',
		},
	},
	reportItem: {
		padding: '5px 0',
		color: variables.gray_9,
		'& a': {
			color: variables.gray_9,
			display: 'block',
			'&:hover': {
				color: variables.blue_6,
			},
		},
		'&:hover': {
			color: variables.blue_6,
		},
	},
	messageNotAvail: {
		padding: '12px',
	},
});
