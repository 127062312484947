import React, { FC } from 'react';

const EyeOpenIcon: FC<React.SVGProps<SVGSVGElement> & { pathFill?: string }> = ({ pathFill, ...props }) => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.1875 9C6.1875 7.4467 7.4467 6.1875 9 6.1875C10.5533 6.1875 11.8125 7.4467 11.8125 9C11.8125 10.5533 10.5533 11.8125 9 11.8125C7.4467 11.8125 6.1875 10.5533 6.1875 9ZM9 7.3125C8.06805 7.3125 7.3125 8.06805 7.3125 9C7.3125 9.93195 8.06805 10.6875 9 10.6875C9.93195 10.6875 10.6875 9.93195 10.6875 9C10.6875 8.06805 9.93195 7.3125 9 7.3125Z"
			fill={pathFill || 'black'}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.24257 7.9848C2.92823 8.43773 2.8125 8.79203 2.8125 9C2.8125 9.20797 2.92823 9.56227 3.24257 10.0152C3.54643 10.453 3.99809 10.9277 4.5695 11.3663C5.71484 12.2453 7.28509 12.9375 9 12.9375C10.7149 12.9375 12.2852 12.2453 13.4305 11.3663C14.0019 10.9277 14.4535 10.453 14.7575 10.0152C15.0718 9.56227 15.1875 9.20797 15.1875 9C15.1875 8.79203
				15.0718 8.43773 14.7575 7.9848C14.4535 7.54695 14.0019 7.07229 13.4305 6.63373C12.2852 5.75469 10.7149 5.0625 9 5.0625C7.28509 5.0625 5.71484 5.75469 4.5695 6.63373C3.99809 7.07229 3.54643 7.54695 3.24257 7.9848ZM3.88456 5.74127C5.18224 4.74531 6.98699 3.9375 9 3.9375C11.013 3.9375 12.8177 4.74531 14.1154 5.74127C14.7656 6.24021 15.3024 6.79683 15.6817 7.34336C16.0504 7.87477 16.3125 8.45797 16.3125 9C16.3125 9.54203 16.0504 10.1252 15.6817 10.6567C15.3024 11.2032 14.7656 11.7598 14.1154 12.2587C12.8177 13.2547 11.013 14.0625 9 14.0625C6.98699 14.0625 5.18224 13.2547 3.88456 12.2587C3.23447 11.7598 2.69761 11.2032 2.31832 10.6567C1.94953 10.1252 1.6875 9.54203 1.6875 9C1.6875 8.45797 1.94953 7.87477 2.31832 7.34336C2.69761 6.79683 3.23447 6.24021 3.88456 5.74127Z"
			fill={pathFill || 'black'}
		/>
	</svg>
);

export default EyeOpenIcon;
