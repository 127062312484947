import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const select = (state: RootState): RootState['user'] => {
	return state.user;
};
 
export const selectCurrentUser = createSelector(select, (user) => {
	return user.user;
});

export const selectChildUser = createSelector(select, (user) => {
	return user.childUser;
});

export const selectActiveUser = createSelector([selectCurrentUser, selectChildUser], (currentUser, childUser) => {
	return childUser || currentUser;
});

export const selectAddressBook = createSelector(select, (user) => {
	return user.addressBook;
});

export const selectAddressBookLoading = createSelector(select, (user) => {
	return user.isAddressBookLoading;
}); 
