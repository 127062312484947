import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import queryString from 'query-string';

import { getCallStt, getTokenForCallAudio } from 'store/calls/calls.slice';
import { CallInfoType, CallSttFragmentType, CallTagType } from 'store/calls/calls.types';
import { AudioPlayer } from 'components/common';
import { useAppSelector } from 'hooks/redux';
import { getItem } from 'store/markupRules/actions';
import { callsActions } from 'store/calls';
import { selectCurrentCall } from 'store/calls/selectors';
import { API_URL } from 'config';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { TCallCheckList } from 'store/checkLists/namespaces/responses';
import { TUpdateCurrentCallCheckListAnswerById } from 'store/checkLists/namespaces/payloads';
import { getTranslationWithLanguage } from 'store/calls/actions';
import { translate } from 'localizations';
import { createSnackbarOptions } from 'components/common/Snackbar/Snackbar';
import { useSnackbar } from 'notistack';
import { UserType } from 'store/user/user.types';
import { useDialogItemsSelector } from './useDialogItemsSelector';
import { ISearchedWord } from '../components/TagsBlock/TagsBlock';
import CallBodyDialog from './CallBodyDialog/CallBodyDialog';
import DictionaryStep from './FastDictAddModalWindow/DictionaryStep';
import CallBodyCallParams from './CallBodyCallParams/CallBodyCallParams';
import { buildFragmentDictionary, buildStringByFragmentDictionary, FragmentDictionary } from '../helpers/fragments';

type CallBodyPropsType = {
	callInfo: CallInfoType;
	fragments?: CallTagType[];
	currentFragment: CallTagType | ISearchedWord | null;
	audioDivRef: React.RefObject<HTMLInputElement>;
	tagsDivRef: React.RefObject<HTMLInputElement>;
	paramsDivRef: React.RefObject<HTMLInputElement>;
	solo?: boolean;
	isCheckListsLoading: boolean;
	checkLists: TCallCheckList[];
	isAuth: boolean | 'loading';
	updateCheckList(target: TUpdateCurrentCallCheckListAnswerById): void;
	userInfo: UserType | null;
	currentPage: number | undefined;
	showParams?: boolean;
	hasAccessToTags: boolean;
};

const useStyles = makeStyles({
	callBodyWrapper: {
		minHeight: '339px',
		display: 'grid',
		gridTemplateColumns: 'calc(100% - 350px) 350px',
		gridTemplateRows: '76px auto auto',
	},
	callBodyAudioBox: {
		position: 'sticky',
		height: 'auto',
		zIndex: 100,
		gridColumnStart: 1,
		gridColumnEnd: 3,
		gridRow: 1,
	},
	callBodyTags: {
		maxHeight: '0px',
		position: 'sticky',
		zIndex: 99,
		backgroundColor: '#EEF2F6',
		gridColumn: 1,
		gridRow: 2,
	},
	callBodyCallParams: {
		zIndex: 99,
		gridColumn: 2,
		backgroundColor: '#F8FAFC',
		marginLeft: '32px',
	},
	callBodyDialogWrapper: {
		gridColumn: 1,
		gridRowStart: 2,
		gridRowEnd: 2,
		borderRadius: '10px',
		width: '100%',
		backgroundColor: '#fff',
		overflow: 'hidden',
		padding: '16px',
	},

	callBodyTagsArray: {
		overflowY: 'auto',
		height: '100%',
		maxHeight: '105px',
		'&::-webkit-scrollbar': {
			width: '4px',
			backgroundColor: '#f1f1f1',
			outline: 'none',
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#A3AEBE',
			height: '50px',
			borderRadius: '10px',
		},
		'&::-webkit-scrollbar-thumb:hover': {
			background: '#9298A1',
		},
	},
	cbDialog: {
		overflowY: 'auto',
		height: '100%',
	},
	cbDialogItems: {
		padding: '0 0 5px 0',
		// height: '800px',
		'&::-webkit-scrollbar': {
			width: '4px',
			height: '4px',
			backgroundColor: '#f1f1f1',
			outline: 'none',
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#A3AEBE',
			height: '50px',
			borderRadius: '10px',
		},
		'&::-webkit-scrollbar-thumb:hover': {
			background: '#555',
		},
	},
	controlBlockButtonBox: {
		boxShadow: 'none !important',
		padding: '0 5px',
		marginLeft: '20px',
	},
	controlBlockButton: {
		border: 'none !important',
		transition: '0.4s !important',
		outline: 'none !important',
		height: '40px',
		fontSize: '14px !important',
		// @ts-ignore
		textTransform: 'none !important',
		color: '#738094 !important',
		backgroundColor: '#ffffff !important',
		'&.Mui-selected': {
			backgroundColor: '#D6D9DF !important',
			color: '#000 !important',
		},
	},
	isActive: {
		backgroundColor: '#CDD5DF',
		color: '#000',
	},
	typographyTitle: {
		color: '#738094 !important',
		fontWeight: '700 !important',
		minWidth: '115px !important',
		userSelect: 'none',
	},
	activeFragment: {
		backgroundColor: '#F5F5DC',
	},
	cdRightBlock: {
		overflow: 'hidden',
		position: 'sticky',
		right: '0',
		top: '-100px',
		zIndex: '100',
	},
	cbPgrase: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'start',
		userSelect: 'none',
	},
	cbPgraseDialod: {
		width: '80%',
		display: 'flex',
		alignItems: 'start',
		userSelect: 'none',
	},
	callTranslated: {
		margin: '3px 0',
		width: '50%',
		color: '#738094',
		userSelect: 'text',
	},
	cbFragments: {
		display: 'flex',
		justifyContent: 'end',
		flexWrap: 'wrap',
		textAlign: 'center',
		userSelect: 'none',
		width: '20%',
		paddingLeft: '16px',
	},
	cbFragmentName: {
		backgroundColor: '#EFDBFF',
		color: '#531DAB',
		border: '1px solid #B37FEB',
		padding: '1px 8px',
		margin: '0 4px 4px 0',
		borderRadius: '5px',
		cursor: 'pointer',
		fontSize: '12px',
		lineHeight: '20px',
	},
	cbFragmentNameDisabled: {
		cursor: 'default',
	},
});

const CallBody: FC<CallBodyPropsType> = React.memo(
	({
		callInfo,
		fragments,
		currentFragment,
		audioDivRef,
		tagsDivRef,
		paramsDivRef,
		solo,
		isCheckListsLoading,
		checkLists,
		isAuth,
		updateCheckList,
		userInfo,
		currentPage,
		showParams = true,
		hasAccessToTags,
	}) => {
		const dispatch = useDispatch();
		const classes = useStyles();
		const history = useHistory();

		const { enqueueSnackbar } = useSnackbar();

		const currentCall = useAppSelector(selectCurrentCall);
		const { language } = useAppSelector((state) => state.lang);
		const callId = callInfo.id.toUpperCase();
		const languagesList = useAppSelector((state) => state.lang.allLanguages);
		const callTranslated = useAppSelector((state) => state.calls.callTranslated);
		const isCallLoading = useAppSelector((state) => state.calls.isCallLoading);

		const firstRenderFunc = useCallback(async () => {
			const searchParamsObject = queryString.parse(history.location.search);
			const token = searchParamsObject.token;
			dispatch(
				callsActions.setCurrentCall({
					id: callInfo.id,
					info: callInfo,
					stt: null,
					audio: null,
					token: null,
				}),
			);
			dispatch(getTokenForCallAudio({ id: callInfo.id, token }));
			dispatch(getCallStt({ id: callInfo.id, token }));
		}, []);

		useEffect(() => {
			firstRenderFunc();
		}, [firstRenderFunc]);

		const prevIndex = useRef<string[] | undefined>(undefined);
		const currentIndex = useRef<string[] | undefined>(undefined);
		const audioPlayerRef = useRef<any>(currentCall ? currentCall.audio : '');

		const getIndices = (array: Array<any>, necessaryElements: any) => {
			const indices: number[] = [];
			for (let i = 0; i < necessaryElements.length; i++) {
				indices.push(array.indexOf(necessaryElements[i]));
			}
			return indices;
		};

		// Возвращает строку с индексом фрагмента/фразы и слова.
		// eslint-disable-next-line consistent-return
		function findWordIndexes(phrases: CallSttFragmentType[] | undefined, currentTime: number) {
			if (phrases) {
				const currentFragments = phrases.filter(
					(phrase) => phrase.begin <= currentTime && currentTime <= phrase.end,
				);
				const currentFragmentsIndices = getIndices(phrases, currentFragments);

				if (currentFragmentsIndices.length < 1 || currentFragmentsIndices.some((index) => index < 0)) {
					return undefined;
				}

				const indices = [];
				for (let i = 0; i < currentFragments.length; i++) {
					const currentWords = currentFragments[i].words.filter(
						(word: any) => word.begin <= currentTime && currentTime <= word.end,
					);
					const currentWordsIndices = getIndices(currentFragments[i].words, currentWords);

					for (let j = 0; j < currentWords.length; j++) {
						indices.push(`${callId}-${currentFragmentsIndices[i]}-${currentWordsIndices[j]}`);
					}
				}
				if (indices.length < 1) {
					return undefined;
				}
				return indices;
			}
		} // return [1-4, 2-8]

		const onListen = (eventCurrentTime: any) => {
			let currentTimeLocal;
			if (eventCurrentTime.target) {
				currentTimeLocal = eventCurrentTime.target.currentTime * 1000;
			} else {
				currentTimeLocal = eventCurrentTime * 1000;
			}
			if (currentCall && currentCall.stt) {
				const indices = findWordIndexes(currentCall.stt.fragments, currentTimeLocal);
				if (indices && JSON.stringify(indices) !== JSON.stringify(prevIndex.current)) {
					let activeWord = null;
					for (let i = 0; i < indices.length; i++) {
						activeWord = document.getElementById(`${indices[i]}`);
						if (activeWord) {
							activeWord.classList.add(classes.isActive);
						}
					}
					if (prevIndex.current) {
						for (let i = 0; i < prevIndex.current.length; i++) {
							// убираем раскраску с предыдущего
							const removeElement = document.getElementById(`${prevIndex.current[i]}`);
							if (removeElement) {
								removeElement.classList.remove(classes.isActive);
							}
						}
					}
					// новоепредыдущее = текущее
					prevIndex.current = indices;
				}
				if (!indices) {
					// убираем раскраску с предыдущего
					const removeElement = document.getElementById(`${prevIndex.current}`);
					if (removeElement) {
						removeElement.classList.remove(classes.isActive);
					}
				}
				currentIndex.current = indices;
			}
		};

		const host = window.origin;

		const activeFragmentRef = useRef<any>(null);
		const prevActiveFragment = useRef<any>(null);

		const onFragmentClick = useCallback(
			(activeFragment: CallTagType | ISearchedWord | null) => {
				if (activeFragment) {
					activeFragmentRef.current = activeFragment;
					if (currentCall && currentCall.stt) {
						if (prevActiveFragment.current && prevActiveFragment.current !== activeFragment) {
							const removeFragment = document.getElementById(
								`${prevActiveFragment.current.fragment}-phrase`,
							);
							if (removeFragment) {
								removeFragment.classList.remove(classes.activeFragment);
							}
						}
						const activePhrase = currentCall.stt.fragments.find((fragment) => {
							if (activeFragment.fBegin) {
								// @ts-ignore
								return fragment.begin === activeFragment.fBegin && fragment.end === activeFragment.fEnd;
							} // @ts-ignore
							return fragment.begin === activeFragment.begin;
						});
						const allPhrases = document.getElementById(`${callInfo.id}`);
						let activePhraseHtmlEl;
						if (activePhrase) {
							activePhraseHtmlEl = document.getElementById(`${activePhrase.id}-phrase`);
						}
						if (activePhraseHtmlEl && allPhrases) {
							activePhraseHtmlEl.classList.add(classes.activeFragment);
							activePhraseHtmlEl.scrollIntoView({
								behavior: 'smooth',
								block: 'center',
							});
						}
						prevActiveFragment.current = activeFragment;
						if (
							audioPlayerRef.current &&
							audioPlayerRef.current.audioEl &&
							audioPlayerRef.current.audioEl.current
						) {
							if (activeFragment?.fBegin) {
								audioPlayerRef.current.audioEl.current.currentTime = activeFragment.fBegin / 1000;
							} else if (activeFragment.begin) {
								audioPlayerRef.current.audioEl.current.currentTime = activeFragment.begin / 1000;
							}

							audioPlayerRef.current.audioEl.current.play().catch((error: string) => {
								console.error('Error occurred while trying to play:', error);
							});
						}
					}
				}
			},
			[callInfo.id, classes.activeFragment, currentCall],
		);
		const user = useAppSelector((state) => state.user.user);
		const childUser = useAppSelector((state) => state.user.childUser);
		useEffect(() => {
			if (currentCall) {
				onFragmentClick(currentFragment);
			}
		}, [currentFragment, currentCall]);

		const accessRights = useAppSelector((state) => state.user.accessRights);

		const [isNotSkippedSelected, setIsNotSkippedSelected] = React.useState(false);

		const callDialogWrapperRef = React.useRef<HTMLDivElement>(null);

		const [selectionText, setSelectionText] = useState<string>('');
		const [isUserSelectText, setIsUserSelectText] = useState<boolean>(false);
		const [fragmentDictionary, setFragmentDictionary] = React.useState<FragmentDictionary>({});

		const onOutsideClick = React.useCallback(() => {
			if (!isUserSelectText) {
				setIsNotSkippedSelected(false);
			}
		}, [isUserSelectText]);

		useOnClickOutside(callDialogWrapperRef, onOutsideClick);

		const [open] = useState<boolean>(true);

		const [selector] = useDialogItemsSelector(callDialogWrapperRef, open);

		const handleSelectedText = (select: Selection | null, currentlySelected: string | undefined) => {
			const selection = select?.getRangeAt(0);
			const $startElement = selection?.startContainer.parentElement;
			const $endElement = selection?.endContainer.parentElement;
			const strFragments = (currentCall && currentCall.stt && currentCall?.stt?.fragments) || [];

			const rowAttributeElement = 'data-fragment-row-index';

			const rowIndexStartElement = $startElement?.getAttribute(rowAttributeElement);
			const rowIndexEndElement = $endElement?.getAttribute(rowAttributeElement);
			// открывать модальное окно только при одном выделенном абзаце
			const isRowsIndexEqual = rowIndexStartElement === rowIndexEndElement;

			if (currentlySelected && isRowsIndexEqual) {
				const dictionary = buildFragmentDictionary($startElement, $endElement, strFragments);
				const text = buildStringByFragmentDictionary(dictionary, language);
				const isNewSelection = text !== selectionText;

				if (isNewSelection) {
					setIsUserSelectText(true);
					setFragmentDictionary(dictionary);
					setIsNotSkippedSelected(true);
					setSelectionText(text);
				}
			}

			selector();
		};

		const onMouseUpFunction = React.useCallback(() => {
			if (window.getSelection()) {
				const select: Selection | null = window.getSelection();
				const currentlySelected: string | undefined = select?.toString();

				// check for rangeCount to prevent "Uncaught DOMException:
				// Failed to execute 'getRangeAt' on 'Selection': 0 is not a valid index"
				if (select && select.rangeCount > 0) handleSelectedText(select, currentlySelected);
			}
		}, [currentCall, language, selector]);

		const onCallBodyFragmentClick = React.useCallback(
			async (fragment: CallTagType) => {
				const tagThunkData = await dispatch(
					getItem({
						page: 'tags',
						data: { id: fragment.rule },
					}),
				); // @ts-ignore
				const tag = tagThunkData.payload;
				if (tag) {
					window.open(
						// eslint-disable-next-line no-nested-ternary
						`${host}/${language}/${childUser ? childUser.id : user ? user.id : '_'}/tags?group=${
							tag.group
						}&id=${tag.id}`,
					);
				} else {
					console.log('this rule was deleted');
				}
			},
			[childUser, dispatch, host, language, user],
		);

		React.useEffect(() => {
			const subscribe = (event: ClipboardEvent): void => {
				if (isNotSkippedSelected) {
					event.preventDefault();
					const BUILD_WITH_PHRASE_DIRECTION = true;
					const text = buildStringByFragmentDictionary(
						fragmentDictionary,
						language,
						BUILD_WITH_PHRASE_DIRECTION,
					);

					event?.clipboardData?.setData('text/plain', text);
				}
			};

			document.addEventListener('copy', subscribe);

			return () => {
				document.removeEventListener('copy', subscribe);
			};
		}, [fragmentDictionary, isNotSkippedSelected, isUserSelectText, language]);

		function clearDictionaryData(): void {
			setIsUserSelectText(false);
		}

		const getTranslate = async (callIdForTranslate: string, languageCode: string) => {
			if (languageCode === 'original') {
				dispatch(callsActions.setCallTranslated(null));
			} else {
				dispatch(callsActions.setCallLoading(true));
				const translatedText = await dispatch(
					getTranslationWithLanguage({ callId: callIdForTranslate, languageCode }),
				); // @ts-ignore
				if (!translatedText.payload) {
					enqueueSnackbar(
						null,
						createSnackbarOptions({
							type: 'error',
							time: 2000,
							text: `${translate('translateError', language)}`,
						}),
					);
				}
				dispatch(callsActions.setCallLoading(false));
			}
		};

		const hasAccessToAudio =
			(accessRights?.audio || accessRights?.admin || solo) && currentCall && currentCall.token;
		const hasAccessToSTT = accessRights?.stt || accessRights?.admin;
		const isAudio = callInfo.communicationType === 'call';

		return (
			<div className={classes.callBodyWrapper}>
				<div ref={audioDivRef} className={classes.callBodyAudioBox}>
					{hasAccessToAudio && isAudio && (
						<AudioPlayer
							onListen={onListen}
							callId={callId}
							callAudio={`${API_URL}call/audio/audio_by_token?token=${currentCall.token}`}
							audioPlayerRef={audioPlayerRef}
						/>
					)}
				</div>
				<div ref={tagsDivRef} className={classes.callBodyTags} />
				<CallBodyCallParams
					showParams={showParams}
					updateCheckList={updateCheckList}
					isAuth={isAuth}
					isCheckListsLoading={isCheckListsLoading}
					checkLists={checkLists}
					currentCall={currentCall}
					accessRights={accessRights}
					callInfo={callInfo}
					paramsDivRef={paramsDivRef}
					language={language}
					classes={classes}
					userInfo={userInfo}
					currentPage={currentPage}
				/>
				{hasAccessToSTT && (
					<CallBodyDialog
						hasAccessToTags={hasAccessToTags}
						tagsAreActive={showParams}
						communicationType={callInfo.communicationType}
						innerRef={callDialogWrapperRef}
						onCallBodyFragmentClick={onCallBodyFragmentClick}
						accessRights={accessRights}
						callId={callId}
						currentCall={currentCall}
						audioPlayerRef={audioPlayerRef}
						fragments={fragments}
						onMouseUpFunction={onMouseUpFunction}
						classes={classes}
						solo={solo}
						language={language}
						languagesList={languagesList}
						callTranslated={callTranslated}
						isCallLoading={isCallLoading}
						getTranslate={getTranslate}
					/>
				)}
				{isUserSelectText && (
					<DictionaryStep
						open={isUserSelectText}
						fragmentDictionary={fragmentDictionary}
						selectionText={selectionText}
						closeDictionaryFunc={clearDictionaryData}
					/>
				)}
			</div>
		);
	},
);

export default CallBody;
