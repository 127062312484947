import React, { FC } from 'react';
import { Typography } from '@mui/material';
import cn from 'classnames';
import { CallSttWordType } from 'store/calls/calls.types';
import useStyles from './styles.jss';

interface TextLineLayoutProps {
	time: string;
	speaker: string;
	callId: string;
	fragmentIndex: number;
	words: CallSttWordType[];
	highlightIndexes: number[];
	colorClassName?: string
	timeClassName?: string
	speakerClassName?: string
	handleWordClick?: (i: number) => void;
	lineStartPadding?: string;
}

const TextLineLayout: FC<TextLineLayoutProps> = ({
	time,
	speaker,
	fragmentIndex,
	words,
	callId,
	highlightIndexes,
	handleWordClick,
	timeClassName,
	speakerClassName,
	colorClassName,
	lineStartPadding
}) => {
	const classes = useStyles();
	const lineStartPaddingDefault = (index: number) => index === 0 ? '15px' : '';

	return (
		<div className={classes.diItem}>
			<Typography className={cn(classes.dateTypography, timeClassName)}>{time}</Typography>
			<Typography
				className={cn(classes.typographyTitle, speakerClassName, colorClassName)}
			>
				{speaker}
			</Typography>
			<div className={classes.diBodyText}>
				{words.map((word, index) => (
					<div
						data-fragment-row-index={fragmentIndex}
						data-fragment-call-index={index}
						key={`${word.word}-${index}`}
						style={{
							userSelect: 'text',
							paddingLeft: lineStartPadding || lineStartPaddingDefault(index),
						}}
					>
						<span
							data-fragment-row-index={fragmentIndex}
							data-fragment-call-index={index}
							onClick={() => {
								if (handleWordClick) handleWordClick(index);
							}}
							id={`${callId}-${fragmentIndex}-${index}`}
							className={`${classes.diFragment} ${
								highlightIndexes.find((hwi) => hwi === index + 1) && classes.tagMatch
							}`}
							key={word.conf}
						>
							{word.word}
							&nbsp;
						</span>
					</div>
				))}
			</div>
		</div>
	);
}

export default TextLineLayout;
