import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from '@mui/material';
import { translate } from 'localizations';
import { UserType } from 'store/user/user.types';
import { LangType } from 'store/lang/lang.slice';
import style from './CallBackToMain.module.scss';

interface CallBackToMainProps {
	language: LangType | string;
	isAuth: boolean | 'loading';
	currentUser: UserType | null;
}
const CallBackToMain: FC<CallBackToMainProps> = ({ language, isAuth, currentUser }) => (
	<div className={style.callBackToMain}>
		<Alert severity="error">{translate('noOptionsMessage', language)}</Alert>
		{isAuth ? (
			<Link className={style.link} to={`/${language}/${currentUser ? currentUser.id : '_'}/calls`}>
				{translate('backToMain', language)}
			</Link>
		) : (
			<Link className={style.link} to={`/${language}/auth`}>
				{translate('logIn', language)}
			</Link>
		)}
	</div>
);

export default CallBackToMain;
