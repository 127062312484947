import { FC, useState } from 'react';
import { TextArea } from 'components/shared/Forms/TextArea/TextArea';
import TitleWithTooltip from '../TitleWithTooltip/TitleWithTooltip';
import cls from './styles.module.scss';

export interface TextareaWithHeaderProps {
	externalValue?: string;
	tooltipTitle: string;
	placeholder: string;
	title: string;
	onChangeValue?: (value: string) => void;
}

const TextareaWithHeader: FC<TextareaWithHeaderProps> = ({
	externalValue = '',
	onChangeValue,
	tooltipTitle,
	placeholder,
	title,
}) => {
	const [value, setValue] = useState(externalValue || '');

	const handleChangeValue = (e: React.ChangeEvent<any>) => {
		const newValue = e.target.value;
		setValue(newValue);

		if (onChangeValue) onChangeValue(newValue);
	};

	return (
		<div className={cls.root}>
			<TitleWithTooltip title={title} tooltipTitle={tooltipTitle} theme="gray" size="md" />

			<TextArea
				className={cls.textarea}
				placeholder={placeholder}
				value={value}
				onChange={handleChangeValue}
				rows={3}
			/>
		</div>
	);
};

export default TextareaWithHeader;
