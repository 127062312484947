import { TCallCheckList } from '../namespaces/responses';

export function getCheckListValues(checkLists: TCallCheckList[]): number {
	const value = checkLists.reduce((total, checkList) => {

		let sum = 0;

		checkList.answers.forEach((answer, answerIndex) => {
			if (answer.value !== null) {
				const res = checkList?.checklist?.questions?.[answerIndex]?.answerPoints?.[answer.value] ?? 0;

				sum += res;
			}
		});

		total += sum;

		return total;
	}, 0);

	return value;
}