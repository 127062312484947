/* eslint-disable @typescript-eslint/ban-types */
import { useState, useEffect, useRef } from 'react';

/**
 * Debounce для значений
 */
const useDebounceValue = (value1: any, value2: any, delay: number) => {
	const [debouncedValue, setDebouncedValue] = useState(value1);

	useEffect(
		() => {
			const handler = setTimeout(() => {
				setDebouncedValue(value1);
			}, delay);

			return () => {
				clearTimeout(handler);
			};
		},
		// можно добавить переменную "delay" в массив зависимостей,
		// если необходимо менять ее динамически.
		[value1, value2],
	);

	return debouncedValue;
};

/**
 * Debounce для коллбеков
 */
const useDebounceCB = (callback: Function, delay: number) => {
	const timer = useRef<any>(null);

	return (...args: any) => {
		if (timer.current) {
			clearTimeout(timer.current);
		}

		timer.current = setTimeout(() => {
			callback(...args);
		}, delay);
	};
};

export { useDebounceValue, useDebounceCB };
