/* eslint-disable */
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { sortArray } from 'utils/arrayFunctions';
import cloneDeep from 'lodash/cloneDeep';
import {
	CallReportType,
	Periods,
	PeriodsType,
	ReportInitialStateType,
	ReportParametersType,
	SelectorsValuesParametersType,
} from './reports.types';
import { criteriaWithComplexValues } from 'configs/searchCriteria';
import initialState from './initial';
import { getCriteriaWithComplexValues, removeFromComplexCriteria } from '../search/utils';
import { CriteriasType } from '../search/search.types';

export const reportsSlice = createSlice({
	name: 'reports',
	initialState,
	reducers: {
		setLoading(state, action: PayloadAction<boolean>) {
			state.isLoading = action.payload;
		},

		setReportLoadingIsStarted(state, action: PayloadAction<boolean>) {
			state.reportLoadingIsStarted = action.payload;
		},

		setReportWebSocket(state, action: PayloadAction<any>) {
			state.reportWebSocket = action.payload;
		},

		setLoadingReport(state, action: PayloadAction<ReportInitialStateType['loadingReport']>) {
			state.loadingReport = action.payload;
		},

		setLoadingReportInitial(state, action: PayloadAction<null>) {
			state.loadingReport = initialState.loadingReport;
		},

		setReports(state, action: PayloadAction<[]>) {
			state.allReports = action.payload;
		},
		setActive(state, action: PayloadAction<ReportParametersType>) {
			state.activeReport = action.payload;
		},
		setActiveReport(state, action: PayloadAction<ReportParametersType>) {
			state.activeReport = action.payload;
			state.isSavedReport = true;
			// TODO: из-за этого отчет загружается дважды,
			//  если во время загрузки раскрыть свернутые параметры
			// state.isReportCollapsed = true;

			if (action.payload.from_dt) {
				state.date = [moment(action.payload.from_dt, 'YYYY/MM/DD'), moment(action.payload.to_dt, 'YYYY/MM/DD')];
			}

			// заглушка для корявых сохраненных отчетов (когда нет всех чекбоксов отображения)
			if (!action.payload.view_options) {
				state.activeReport.view_options = initialState.activeReport.view_options;
			}
			// заглушка для корявых сохраненных отчетов (нет части чекбоксов в сохраненных отчетах)
			if (!Object.keys(action.payload.view_options).includes('show_client_time')) {
				state.activeReport.view_options.show_client_time = false;
				state.activeReport.view_options.show_call_dt = false;
				state.activeReport.view_options.show_operator_time = false;
				state.activeReport.view_options.show_silence_time = false;
				state.activeReport.view_options.show_tags_count = false;
			}
			if (!Object.keys(action.payload.view_options).includes('show_zero_cols')) {
				state.activeReport.view_options.show_zero_cols = true;
				state.activeReport.view_options.show_zero_grouping_rows = true;
				state.activeReport.view_options.show_zero_grouping_cols = true;
			}
		},
		setActiveReportByHash(state, action: PayloadAction<any>) {
			state.activeReport = action.payload.report_parameters;
			state.isSavedReport = true;
			state.isReportCollapsed = true;
			// date from hash
			const date_1 = moment(action.payload.start_date, 'YYYY-MM-DD');
			const date_2 = moment(action.payload.end_date, 'YYYY-MM-DD');

			state.date = [date_1, date_2];
			state.activeReport.from_dt = date_1.startOf('day').utc(true).format();
			state.activeReport.to_dt = date_2.endOf('day').utc(true).format();
		},

		setIsSavedReport(state, action: PayloadAction<boolean>) {
			state.isSavedReport = action.payload;
		},
		setReportById(state, action: PayloadAction<boolean>) {
			state.isReportById = action.payload;
		},
		setCurrentSavedReport(state, action: PayloadAction<any>) {
			state.currentSavedReport = action.payload;
		},
		setInitialSavedReport(state) {
			state.currentSavedReport = initialState.currentSavedReport;
		},
		setReportByHash(state, action: PayloadAction<boolean>) {
			state.isReportByHash = action.payload;
		},
		setReportCollapsed(state, action: PayloadAction<boolean>) {
			state.isReportCollapsed = action.payload;
		},
		setCallReport(state, action: PayloadAction<CallReportType>) {
			state.callReport = action.payload;
		},
		setInitialCallReport(state, action: PayloadAction<null>) {
			state.callReport = initialState.callReport;
		},
		setDiffReport(state, action: PayloadAction<any>) {
			state.diffReport = action.payload;
		},
		setDiffReportIsLoaded(state, action: PayloadAction<boolean>) {
			state.diffReportIsLoaded = action.payload;
		},
		setDate(state, action: PayloadAction<typeof initialState.date | null[]>) {
			state.date = action.payload;
		},

		setFromToDate(state, action: PayloadAction<(string | null)[]>) {
			state.activeReport.from_dt = action.payload[0];
			state.activeReport.to_dt = action.payload[1];
		},

		setPeriod(state, action: PayloadAction<PeriodsType | null | string>) {
			state.activeReport.period = action.payload;
		},
		setChartType(state, action: PayloadAction<{ value: string; label: string }>) {
			state.chartType = action.payload;
		},

		setTagNames(state, action: PayloadAction<[]>) {
			state.tagNames = sortArray(action.payload);
		},

		setTagNamesWithValues(state, action: PayloadAction<[]>) {
			state.tagNamesWithValues = sortArray(action.payload);
		},

		setTagValuesColumnAdd(state, action: PayloadAction<[]>) {
			state.tagColumnsAddValues = sortArray(action.payload);
		},

		setTagValuesColumn(state, action: PayloadAction<[]>) {
			state.tagColumnValues = sortArray(action.payload);
		},

		setTagValuesRow(state, action: PayloadAction<[]>) {
			state.tagRowValues = sortArray(action.payload);
		},

		getSelectorsValues(state, action: PayloadAction<{ selectors: SelectorsValuesParametersType; tags: string[] }>) {
			state.selectors = action.payload.selectors;
			state.activeReport.report_type = action.payload.selectors.report_types[0];
		},

		setValidTagList(state, action: PayloadAction<boolean>) {
			state.isValidTagList = action.payload;
		},
		setValidTagTitle(state, action: PayloadAction<boolean>) {
			state.isValidTagTitle = action.payload;
		},
		setValidSearchTitle(state, action: PayloadAction<boolean>) {
			state.isValidSearchTitle = action.payload;
		},

		// view options
		setViewOption(
			state,
			action: PayloadAction<{ name: keyof ReportParametersType['view_options']; data: boolean }>,
		) {
			state.activeReport.view_options[action.payload.name] = action.payload.data;
		},

		// clear графики
		setInitialChart(state) {
			state.activeReport.view_options = initialState.activeReport.view_options;
		},

		setResetParameters(state, action: PayloadAction<boolean>) {
			state.isResetParameters = action.payload;
		},
		// установление параметров отчета по порядку
		setNameReport(state, action: PayloadAction<string>) {
			state.activeReport.report_name = action.payload;
		},
		setTypeReport(state, action: PayloadAction<string>) {
			state.activeReport.report_type = action.payload;
		},
		//* ****  группировка строк
		setActiveRowsGroupBy(state, action: PayloadAction<any>) {
			state.activeReport.rows_group_by = action.payload;
		},

		setRowsGroupBy(state, action: PayloadAction<any>) {
			state.activeReport.rows_group_by.group_by = action.payload.group_by;
			state.activeReport.rows_group_by.value = action.payload.value;
		},

		// по тегу и значениям
		setActiveRowsTags(state, action: PayloadAction<{ name: string; split: boolean }>) {
			// @ts-ignore
			state.activeReport.rows_group_by.value = {
				name: action.payload.name,
				values: [],
				split: action.payload.split,
			};
		},
		setActiveRowsTagsWithValues(state, action: PayloadAction<any>) {
			// @ts-ignore
			state.activeReport.rows_group_by.value.values = action.payload;
		},
		setActiveRowsSplit(state, actions: PayloadAction<boolean>) {
			// @ts-ignore
			state.activeReport.rows_group_by.value.split = actions.payload;
		},

		//* ****  первая дефолтная группировка по столбцам
		setDefaultColsGroupBy(state, action: PayloadAction<any>) {
			if (action.payload.group_by === 'tag_name_list') {
				state.activeReport.cols_group_by[0] = {
					group_by: action.payload.group_by,
					value: {
						values: [],
						split: action.payload.value.split,
						col_name: '',
					},
				};
			} else if (action.payload.group_by === 'tag_name_value') {
				state.activeReport.cols_group_by[0] = {
					group_by: action.payload.group_by,
					value: {
						name: '',
						values: [],
						split: action.payload.value.split,
					},
				};
			} else {
				state.activeReport.cols_group_by[0] = { group_by: action.payload.group_by.value };
			}
		},

		// значение тега
		setDefaultColsTagsValue(state, action: PayloadAction<any>) {
			state.activeReport.cols_group_by[0].value = action.payload.value.value;
		},
		// по фильтрам звонка (конструктор)
		setDefaultColsTitleGroupBy(state, action: PayloadAction<any>) {
			state.activeReport.cols_group_by[0] = {
				group_by: 'search_items',
				// @ts-ignore
				value: {
					col_name: action.payload.col_name,
					search_items: [
						{
							key: '',
							values: [],
						},
					],
				},
			};
		},
		setDefSearchItems(state, action: PayloadAction<{ key: any; values: any }>) {
			// @ts-ignore
			state.activeReport.cols_group_by[0].value.search_items = action.payload;
		},

		// по списку тегов
		setDefaultColTagsListValues(state, action: PayloadAction<any>) {
			// @ts-ignore
			state.activeReport.cols_group_by[0].value.values = action.payload;
		},
		setDefaultColTagsListName(state, action: PayloadAction<any>) {
			// @ts-ignore
			state.activeReport.cols_group_by[0].value.col_name = action.payload;
		},

		// по тегу и значениям
		setDefaultColsTags(state, action: PayloadAction<{ name: string; split: boolean }>) {
			state.activeReport.cols_group_by[0].value = {
				name: action.payload.name,
				values: [],
				split: action.payload.split,
			};
		},
		setDefaultColsTagsWithValues(state, action: PayloadAction<any>) {
			// @ts-ignore
			state.activeReport.cols_group_by[0].value.values = action.payload;
		},
		setDefaultColsSplit(state, action: PayloadAction<boolean>) {
			// @ts-ignore
			state.activeReport.cols_group_by[0].value.split = action.payload;
		},

		//* ****** доп столбцы
		// массив данных доп группировки по столбцам
		setActiveParameters(state, action: PayloadAction<any>) {
			let local: any = [];
			if (!action.payload) local = {};
			else local = action.payload;
			const massiv = [];
			for (let i = 0; i < local.length; i++) {
				massiv.push({
					select: {
						options: local[i].select.options,
						value: local[i].select.value,
					},
					tagsVal: {
						options: local[i].tagsVal.options,
						value: local[i].tagsVal.value,
					},
					op: {
						options: local[i].op.options,
						value: local[i].op.value,
					},
					opTags: {
						values: [],
					},
					nameColumn: {
						value: '',
					},
					tagsNameList: {
						options: local[i].tagsNameList.options,
						values: local[i].tagsNameList.values,
						split: local[i].tagsNameList.split,
						col_name: local[i].tagsNameList.col_name,
						inputSwitch: local[i].tagsNameList.inputSwitch,
					},
					tagNameValue: {
						options: local[i].tagNameValue.options,
						valueTag: local[i].tagNameValue.valueTag,

						optionsTag: local[i].tagNameValue.optionsTag,
						name: local[i].tagNameValue.name,
						values: local[i].tagNameValue.values,
						split: local[i].tagNameValue.split,
					},

					callFilters: {
						// вид для селекта
						options: local[i].callFilters.options,
						// все в изнач виде
						values: local[i].callFilters.values,
						// активные
						activeValues: local[i].callFilters.activeValues,
					},
				});
			}
			// @ts-ignore
			state.activeParameters.push(massiv);
		},
		// выбор селекта
		setParameterSelectFieldValue(
			state,
			action: PayloadAction<{ arrayIndex: number; value: { value: any; label: string; type?: string } }>,
		) {
			state.activeParameters[action.payload.arrayIndex][0].select.value = action.payload.value;
		},
		// выбор тега
		setParameterTagstFieldValue(
			state,
			action: PayloadAction<{ arrayIndex: number; value: { value: any; label: string } }>,
		) {
			state.activeParameters[action.payload.arrayIndex][0].tagsVal.value = action.payload.value;
		},
		// тег и значение
		setParamTag(state, action: PayloadAction<{ arrayIndex: number; value: { value: string; label: string } }>) {
			state.activeParameters[action.payload.arrayIndex][0].tagNameValue.valueTag = action.payload.value;
		},
		setParamTagName(state, action: PayloadAction<{ arrayIndex: number; value: string }>) {
			state.activeParameters[action.payload.arrayIndex][0].tagNameValue.name = action.payload.value;
		},
		setParamTagValues(state, action: PayloadAction<{ arrayIndex: number; value: [] }>) {
			state.activeParameters[action.payload.arrayIndex][0].tagNameValue.optionsTag = action.payload.value;
		},
		setParamTagNameValues(state, action: PayloadAction<{ arrayIndex: number; value: any }>) {
			state.activeParameters[action.payload.arrayIndex][0].tagNameValue.values = action.payload.value;
		},
		setInitialTagNameValue(state, action: PayloadAction<{ arrayIndex: number }>) {
			state.activeParameters[action.payload.arrayIndex][0].tagNameValue.values = [];
		},
		setParamTagSplit(state, action: PayloadAction<{ arrayIndex: number; value: boolean }>) {
			state.activeParameters[action.payload.arrayIndex][0].tagNameValue.split = action.payload.value;
		},

		//  названия столбца
		setNameColumnFieldValue(state, action: PayloadAction<{ arrayIndex: number; value: string }>) {
			state.activeParameters[action.payload.arrayIndex][0].nameColumn.value = action.payload.value;
		},

		// выбор фильтра тега
		setFilterFieldValue(
			state,
			action: PayloadAction<{ arrayIndex: number; value: { value: any; label: string; type: string } }>,
		) {
			state.activeParameters[action.payload.arrayIndex][0].op.value = action.payload.value;
		},
		// выбор тегов по фильтру
		setFilterFieldTags(state, action: PayloadAction<{ arrayIndex: number; value: [string] }>) {
			// @ts-ignore
			state.activeParameters[action.payload.arrayIndex][0].opTags.values = action.payload.value;
		},

		setActiveCriteriaColumn(state, action: PayloadAction<{ arrayIndex: number; criteria: any }>) {
			state.activeParameters[action.payload.arrayIndex][0].callFilters.activeValues = action.payload.criteria;
		},

		setActiveCriteriaColumn2(state, action: PayloadAction<{ arrayIndex: number; criteria: any }>) {
			// @ts-ignore
			state.activeParameters[action.payload.arrayIndex][0].callFilters.activeValues.push(action.payload.criteria);
		},

		setOptionsCriterias(state, action: PayloadAction<{ arrayIndex: number; criteria: any }>) {
			state.activeParameters[action.payload.arrayIndex][0].callFilters.options = action.payload.criteria;
		},

		setActiveColTagsName(state, action: PayloadAction<{ arrayIndex: number; value: any }>) {
			state.activeParameters[action.payload.arrayIndex][0].tagsNameList.values = action.payload.value;
		},
		setActiveColTagsColName(state, action: PayloadAction<{ arrayIndex: number; value: any }>) {
			state.activeParameters[action.payload.arrayIndex][0].tagsNameList.col_name = action.payload.value;
		},
		setActiveColTagsSplit(state, action: PayloadAction<{ arrayIndex: number; value: any }>) {
			state.activeParameters[action.payload.arrayIndex][0].tagsNameList.split = action.payload.value;
		},
		setActiveColTagsInputSwitch(state, action: PayloadAction<{ arrayIndex: number; value: any }>) {
			state.activeParameters[action.payload.arrayIndex][0].tagsNameList.inputSwitch = action.payload.value;
		},

		setActiveCriteriaValuesColumn(state, action: PayloadAction<{ arrayIndex: number; criteria: any }>) {
			const { arrayIndex, criteria } = action.payload;
			const obj = current(state.activeParameters[arrayIndex][0].callFilters.activeValues).find(
				(item: any) => item.key === criteria.key,
			);
			// @ts-ignore
			const index = current(
				state.activeParameters[arrayIndex][0].callFilters.activeValues, // @ts-ignore
			).indexOf(obj);
			if (criteriaWithComplexValues.includes(criteria.key)) {
				// @ts-ignore
				state.activeParameters[arrayIndex][0].callFilters.activeValues = getCriteriaWithComplexValues(
					criteria.key,
					current(state.activeParameters[arrayIndex][0].callFilters.activeValues),
					criteria,
				);
			} else {
				// @ts-ignore
				state.activeParameters[arrayIndex][0].callFilters.activeValues[index].values = criteria.values;
			}
		},

		removeComplexCriteriaValuesColumn(
			state,
			action: PayloadAction<{ arrayIndex: number; criteria: CriteriasType }>,
		) {
			const { arrayIndex, criteria } = action.payload;
			// @ts-ignore
			state.activeParameters[arrayIndex][0].callFilters.activeValues = removeFromComplexCriteria(
				current(state.activeParameters[arrayIndex][0].callFilters.activeValues),
				criteria.conditionItemId,
				criteria.key,
			);
		},

		removeActiveCriteriaColumn(state, action: PayloadAction<{ arrayIndex: number; criteria: any }>) {
			const activeCriteriasColumn = cloneDeep(
				current(state.activeParameters[action.payload.arrayIndex][0].callFilters.activeValues),
			);
			const obj = current(state.activeParameters[action.payload.arrayIndex][0].callFilters.activeValues).find(
				(item: any) => item.key === action.payload.criteria.key,
			);
			// @ts-ignore
			const index = current(
				state.activeParameters[action.payload.arrayIndex][0].callFilters.activeValues, // @ts-ignore
			).indexOf(obj);
			activeCriteriasColumn.splice(index, 1);

			state.activeParameters[action.payload.arrayIndex][0].callFilters.activeValues = activeCriteriasColumn;
		},

		setIsValidColumn(state, action: PayloadAction<{ arrayIndex: number; value: any }>) {
			state.activeParameters[action.payload.arrayIndex][0].nameColumn.isValid = action.payload.value;
		},

		setIsValidTagListTitle(state, action: PayloadAction<{ arrayIndex: number; value: any }>) {
			state.activeParameters[action.payload.arrayIndex][0].tagsNameList.col_name_isValid = action.payload.value;
		},

		setIsValidTagList(state, action: PayloadAction<{ arrayIndex: number; value: any }>) {
			state.activeParameters[action.payload.arrayIndex][0].tagsNameList.values_isValid = action.payload.value;
		},

		setTableRows(state, action: PayloadAction<any>) {
			state.tableRows = action.payload;
		},
		setTableColumns(state, action: PayloadAction<any>) {
			state.tableColumns = action.payload;
		},
		setTableAllRows(state, action: PayloadAction<any>) {
			state.tableAllRows = action.payload;
		},

		// initial state and reset
		// удаление активного доп критерия
		removeParameterField(state, action: PayloadAction<{ arrayIndex: number }>) {
			state.activeParameters.splice(action.payload.arrayIndex, 1);
		},
		resetDate(state) {
			state.date = initialState.date;
		},

		resetDiffReport(state) {
			state.diffReport = initialState.diffReport;
		},

		resetReportParameters(state) {
			state.activeParameters = initialState.activeParameters;
			state.activeReport.report_name = initialState.activeReport.report_name;
			state.activeReport.call_search_items = initialState.activeReport.call_search_items;

			state.callReport = initialState.callReport;

			state.tableRows = initialState.tableRows;
			state.tableColumns = initialState.tableColumns;
		},

		setInitialIsSavedReport(state) {
			state.isSavedReport = initialState.isSavedReport;
		},
		setInitialReportParameters: (state, action: PayloadAction<SelectorsValuesParametersType>) => {
			state.activeReport.cols_group_by = [{ group_by: 'tag_name_value', value: { name: '', values: [] } }];

			if (action.payload.rows_groupings[0] === 'time') {
				state.activeReport.rows_group_by = {
					group_by: action.payload.rows_groupings[0],
					value: action.payload.groupings_by_time[0],
				};
			}
		},
	},
});

export default reportsSlice.reducer;
