import React from 'react';
import { PersonSvg } from '../../../../components/Icons/PersonSvg';
import { Skeleton, Typography } from '@mui/material';
import { AccessRights } from '../../../../store/user/user.types';
import { CallInfoType } from '../../../../store/calls/calls.types';

interface IOwnProps {
	classes: any;
	callInfo: CallInfoType | null;
}

const ClientBlock: React.FC<IOwnProps> = ({ callInfo, classes }) => {
	return (
		<div>
			<div style={{width: "100%", display: 'flex', marginBottom: '6px', overflow: "hidden" }}>
				<PersonSvg fill={'#738094'} />
				<Typography style={{ marginLeft: '5px' }} className={classes.darkText}>
					{callInfo ? (
						callInfo.operatorPhone && callInfo.operatorPhone.length > 0 ? (
							callInfo.operatorPhone
						) : (
							'---'
						)
					) : (
						<Skeleton style={{ marginRight: '5px' }} width={100} height={20} variant='text' />
					)}
				</Typography>
			</div>

			<Typography className={classes.lightText}>
				{callInfo ? callInfo.callTimeReadable : <Skeleton width={108} height={20} variant='text' />}
			</Typography>
		</div>
	);
};

export default React.memo(ClientBlock);
