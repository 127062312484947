import { FC } from 'react';
import cls from './styles.module.scss';

interface BlockHeader {
	title: string;
	link?: string;
	linkText?: string;
}

const BlockHeader: FC<BlockHeader> = ({ title, link, linkText }) => (
	<div className={cls.root}>
		<div className={cls.title}>{title}</div>
		{link && (
			<a data-testid="userLink" className={cls.infoLink} href={link}>
				{linkText}
			</a>
		)}
	</div>
);

export default BlockHeader;
