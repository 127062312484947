import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import renderRoutes from 'router/renderRoutes';
import { authRoutes, routes } from 'router/routes';
import { useAppSelector } from 'hooks/redux';
import { Header } from 'components/common';

const useStyles = makeStyles(({
	container: {
		maxWidth: '1740px',
		margin: '0 auto',
		padding: '0 50px',
		paddingBottom: '25px',
	},
}));
const WithAuthContent: FC = () => {
	const classes = useStyles();
	const isAuth = useAppSelector(state => state.user.isAuth);

	if (isAuth) return (
		<>
			<Header />
			<div className={classes.container}>{renderRoutes(routes)}</div>
		</>
	);

	return renderRoutes(authRoutes);
};

export default WithAuthContent;
