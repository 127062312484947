import React, { useCallback, useState } from 'react';
import { Skeleton } from '@mui/material';
import { BlockBox, Alert } from 'components/common';
import { createSnackbarOptions } from 'components/common/Snackbar/Snackbar';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { searchSlice } from 'store';
import { translate } from 'localizations';
import { callsActions } from 'store/calls';
import { AccessRights, UserType } from 'store/user/user.types';
import { CriteriasType, ModeType, TemplateType } from 'store/search/search.types';
import { optionsCreator } from 'utils/optionsCreator';
import { criteriaWithComplexValues } from 'configs/searchCriteria';
import { convertComplexValuesIntoTags } from 'utils/convertDataForSearchFilters/convertData';
import CreateNameTemplateModalWindow from './Windows/CreateNameTemplateModalWindow';
import DeleteTemplateModalWindow from './Windows/DeleteTemplateModalWindow';
import { useCallSearchStyles } from './Search.jss';
import SearchFilters from './components/SearchFilters/SearchFilters';
import SearchControls from './components/SearchControls/SearchControls';

// функция, которая возвращает полноценную критерию.
export function getFullCriteria(key: string, allCriterias: CriteriasType[]): CriteriasType | undefined {
	return allCriterias.find((fullCriteria) => fullCriteria.key === key);
}

interface IOwnProps {
	isLoading: boolean;
	language: string;
	mode: ModeType;
	activeCriterias: CriteriasType[];
	allCriterias: CriteriasType[];
	user: UserType | null;
	accessRights: AccessRights | null;
	childUser: UserType | null;
	allTemplates: TemplateType[];
	currentTemplate: TemplateType | null;
	isDateError?: boolean;
	updateCurrentPage: (pageNumber: number) => void;
}

const Search: React.FC<IOwnProps> = ({
	childUser,
	user,
	accessRights,
	allCriterias,
	activeCriterias,
	isLoading,
	language,
	mode,
	allTemplates,
	isDateError,
	currentTemplate,
	updateCurrentPage,
}) => {
	const dispatch = useDispatch();
	const classes = useCallSearchStyles();
	const { enqueueSnackbar } = useSnackbar();

	// кнопка искать
	const searchRequest = async () => {
		const FIRST_TABLE_PAGE = 1;

		if (!isDateError) {
			await dispatch(callsActions.setSearchCallsHash());
			updateCurrentPage(FIRST_TABLE_PAGE);
		} else {
			enqueueSnackbar(
				null,
				createSnackbarOptions({
					type: 'error',
					time: 2000,
					text: translate('reportDateError', language),
				}),
			);
		}
	};

	// модальные окна
	const [isOpenRenameMethod, setIsOpenRenameMethod] = useState<'put' | 'post' | null>(null);
	const [isOpenRenameWindow, setIsOpenRenameWindow] = useState<boolean>(false);
	const [isOpenDeleteWindow, setIsOpenDeleteWindow] = useState<boolean>(false);

	const convertedTemplate = optionsCreator(allTemplates);
	const removeAllCriteriasHandler = (): void => {
		dispatch(searchSlice.actions.setCurrentTemplate(null));
		dispatch(searchSlice.actions.removeAllActiveCriterias({ page: 'calls', data: null }));
	};
	const handleCreateNewTemplateButtonClick = (): void => {
		setIsOpenRenameMethod('post');
		setIsOpenRenameWindow(true);
	};
	const onTemplateSelectValueChange = (event: any): void => {
		dispatch(searchSlice.actions.setCurrentTemplate(event.value));
		if (allCriterias) {
			const fullCriteriaLocal: CriteriasType[] = [];
			for (let i = 0; i < event.value.items.length; i++) {
				const obj = allCriterias.find((item) => item.key === event.value.items[i].key); // @ts-ignore
				const newObj = { ...obj, values: event.value.items[i].values };
				if (criteriaWithComplexValues.includes(event.value.items[i].key)) {
					newObj.values = convertComplexValuesIntoTags(event.value.items[i].complexValues);
					newObj.logic = event.value.items[i]?.logic || 'and';
					newObj.conditionItemId = i; // TODO: нужно вставить id относительно количества самих тегов с этим типом
				}
				fullCriteriaLocal.push(newObj as CriteriasType);
			}
			const activeCriteriasLocal = [];
			for (let i = 0; i < fullCriteriaLocal.length; i++) {
				activeCriteriasLocal.push(fullCriteriaLocal[i]);
			}
			dispatch(searchSlice.actions.setActiveCriterias({ page: 'calls', data: activeCriteriasLocal }));
		}
	};

	// селект "Добавить фильтр"
	const handleMoreSelectClick = useCallback(() => {
		if (allCriterias.length) {
			interface keyable {
				[key: string]: any;
			}

			const local: Array<keyable> = [];
			allCriterias.forEach((item, i) => {
				if (item.values) {
					local.push({ value: allCriterias[i], label: allCriterias[i].title });
				}
			});
			for (let i = 0; i < local.length; i++) {
				if (activeCriterias.find((item) => item.key === local[i].value.key)) {
					local[i].icon = 'minus';
				} else {
					local[i].icon = 'plus';
				}
			}
			return local;
		}
	}, []);

	function setCriterias(criterias: CriteriasType[]): void {
		dispatch(searchSlice.actions.setCurrentTemplate(null));
		criterias.forEach((criteria) => {
			if (criteria && !activeCriterias.find((activeCriteria) => activeCriteria.key === criteria.key)) {
				dispatch(searchSlice.actions.setActiveCriteria({ page: 'calls', data: { ...criteria, values: [] } }));
			}
		});
	}

	function removeCriterias(criterias: CriteriasType[]): void {
		dispatch(searchSlice.actions.setCurrentTemplate(null));
		criterias.forEach((criteria) => {
			const currentCriteria = activeCriterias.find((activeCriteria) => activeCriteria.key === criteria.key);
			if (currentCriteria) {
				dispatch(
					searchSlice.actions.removeActiveCriteria({
						page: 'calls',
						data: currentCriteria,
					}),
				);
			}
		});
	}

	function setCriteria(criteria: CriteriasType): void {
		dispatch(searchSlice.actions.setCurrentTemplate(null));
		if (criteria) {
			const data = { ...criteria, values: [] };

			// Для сложного поиска добавляем дефолтные conditionItemId и logic
			if (criteriaWithComplexValues.includes(criteria.key)) {
				data.conditionItemId = 0;
				data.logic = 'and';
			}

			dispatch(
				searchSlice.actions.setActiveCriteria({
					page: 'calls',
					data,
				}),
			);
		}
	}

	function removeCriteria(criteria: CriteriasType): void {
		dispatch(searchSlice.actions.setCurrentTemplate(null));
		if (criteria) {
			dispatch(searchSlice.actions.removeActiveCriteria({ page: 'calls', data: criteria }));
		}
	}

	const onSaveCriterias = (event: any) => {
		if (event.value === 'rename') {
			setIsOpenRenameMethod('put');
			setIsOpenRenameWindow(true);
		} else if (event.value === 'delete') {
			setIsOpenDeleteWindow(true);
		}
	};

	return (
		<div style={{ margin: '16px 0' }}>
			<BlockBox padding="20px 24px" height="100%">
				{(mode === 'LOADING' && <Skeleton animation="wave" height={32} />) ||
					((mode === 'SEARCH' || mode === 'HISTORY') && (
						<>
							{user && user.role === 'admin' && !childUser ? (
								<Alert text={translate('selectUser', language)} />
							) : (
								<div>
									<SearchFilters
										page="calls"
										simpleListWithTitles={false}
										currentTemplate={currentTemplate}
										allTemplates={allTemplates}
										language={language}
										allCriterias={allCriterias}
										activeCriterias={activeCriterias}
										accessRights={accessRights}
										convertedTemplate={convertedTemplate}
										onTemplateSelectValueChange={onTemplateSelectValueChange}
									/>
									<SearchControls
										handleSelectChange={onSaveCriterias}
										activeCriterias={activeCriterias}
										accessRights={accessRights}
										setCriterias={setCriterias}
										removeCriterias={removeCriterias}
										setCriteria={setCriteria}
										removeCriteria={removeCriteria}
										op={[]}
										currentTemplate={currentTemplate}
										language={language}
										isLoading={isLoading}
										searchRequest={searchRequest}
										handleCreateNewTemplateButtonClick={handleCreateNewTemplateButtonClick}
										removeAllCriteriasHandler={removeAllCriteriasHandler}
									/>
								</div>
							)}
						</>
					))}
			</BlockBox>
			{/* Модальные окна */}
			<CreateNameTemplateModalWindow
				currentTemplate={currentTemplate}
				isOpen={isOpenRenameWindow}
				handleClose={() => {
					setIsOpenRenameWindow(false);
				}}
				method={isOpenRenameMethod}
			/>
			<DeleteTemplateModalWindow
				currentTemplate={currentTemplate}
				isOpen={isOpenDeleteWindow}
				handleClose={() => {
					setIsOpenDeleteWindow(false);
				}}
			/>
		</div>
	);
};

export default React.memo(Search);
