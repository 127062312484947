import { FC, useState } from 'react';
import { Typography } from '@mui/material';
import CustomSwitch from '../CustomSwitch/CustomSwitch';
import cls from './styles.module.scss';

export interface TextWithSwitchProps {
	text: string;
	onSwitchChange: (checked: boolean) => void;
	initialChecked?: boolean;
}

const TextWithSwitch: FC<TextWithSwitchProps> = ({ text, onSwitchChange, initialChecked }) => {
	const [switchChecked, setSwitchChecked] = useState(initialChecked || false);

	const handleSwitchChecked = () => {
		const newSwitchChecked = !switchChecked;
		setSwitchChecked(newSwitchChecked);
		onSwitchChange(newSwitchChecked);
	};

	return (
		<div className={cls.root}>
			<Typography className={cls.text}>{text}</Typography>
			<CustomSwitch checked={switchChecked} onChecked={handleSwitchChecked} />
		</div>
	);
};

export default TextWithSwitch;
