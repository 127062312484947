import { useAppSelector } from 'hooks/redux';
import React, { FC, useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Moment } from 'moment';
import { PeriodsType } from 'store/reports/reports.types';
import { translate } from 'localizations';
import { useStyles } from './ButtonGroup.jss';

interface ButtonGroupProps {
	date: (Moment | null)[];
	buttons: {
		value: PeriodsType | string;
		onClick: () => void;
		unitOfTime: Moment[] | null[];
	}[];
}

const ButtonGroup: FC<ButtonGroupProps> = ({ date, buttons }) => {
	const s = useStyles();
	const { language } = useAppSelector((state) => state.lang);
	const [buttonValue, setButtonValue] = useState<string | null>(null);

	const handleChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
		setButtonValue(newValue);
	};

	useEffect(() => {
		for (let i = 0; i < buttons.length; i++) {
			const button = buttons[i];
			const period = button.value;
			const periodArray = button.unitOfTime;

			if (date === null) {
				setButtonValue(null);
			}
			if (
				date &&
				periodArray[0]?.utc(true).toString() === date[0]?.utc(true).toString() &&
				periodArray[1]?.utc(true).toString() === date[1]?.utc(true).toString()
			) {
				setButtonValue(period);
				break;
			} else {
				setButtonValue(null);
			}
		}
	}, [date]);

	return (
		<div>
			<ToggleButtonGroup
				className={s.controlBlockButtonBox}
				value={buttonValue}
				exclusive
				onChange={handleChange}
			>
				{buttons.map((button) => (
					<ToggleButton
						key={button.value}
						disabled={button.value === buttonValue}
						className={s.controlBlockButton}
						value={button.value}
						onClick={button.onClick}
					>
						{translate(button.value, language)}
					</ToggleButton>
				))}
			</ToggleButtonGroup>
		</div>
	);
};

export default ButtonGroup;
