import { FC, useState } from 'react';
import TooltipCustom from 'components/common/Tooltip/Tooltip';
import cls from './styles.module.scss';
import CopyToClipboardIcon from '../icons/notifyIcons/CopyToClipboardIcon/CopyToClipboardIcon';
import UniversalButton from '../UniversalButton/UniversalButton';

interface CopyToClipboardProps {
	text: string;
}

const CopyToClipboard: FC<CopyToClipboardProps> = ({ text }) => {
	const [isCopied, setIsCopied] = useState(false);

	const handleCopyClick = async () => {
		try {
			await navigator.clipboard.writeText(text);
			setIsCopied(true);
			setTimeout(() => setIsCopied(false), 2000);
		} catch (err) {
			console.error('Failed to copy text: ', err);
		}
	};

	return (
		<div className={cls.root}>
			<span className={cls.text}>{text}</span>
			<TooltipCustom title={isCopied ? 'Скопировано!' : 'Копировать в буфер'}>
				<UniversalButton
					text=""
					icon={<CopyToClipboardIcon />}
					handleClick={handleCopyClick}
					buttonStyle="plain"
				/>
			</TooltipCustom>
		</div>
	);
};

export default CopyToClipboard;
