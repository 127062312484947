import React, { FC } from 'react';
import { useAppSelector } from 'hooks/redux';
import { TaskType } from 'store/tasks/tasks.slice';
import LinearProgress from '@mui/material/LinearProgress';
import { Skeleton, Typography } from '@mui/material';
import { RootState } from 'store/store';
import { translate } from 'localizations';
import styles from './RealtimeTasks.module.scss';

type RealtimeTaskProps = {
	task: TaskType;
	index: number;
};

const RealtimeTask: FC<RealtimeTaskProps> = ({ task, index }) => {
	const language = useAppSelector((state: RootState) => state.lang.language);

	return (
		<div className={styles.tasksWrapper}>
			<div className={styles.taskWidth}>
				<div className={styles.task}>
					<Typography>{task.title ? task.title : translate('downloading', language)}</Typography>
					<div className={styles.taskItem}>
						<div
							className={styles.taskTitle}
							style={{
								backgroundColor:
									(task.status === 'PENDING' && 'yellow') ||
									(task.status === 'PROGRESS' && 'blue') ||
									(task.status === 'FAILURE' && 'red') ||
									(task.status === 'SUCCESS' && 'green') ||
									'white',
							}}
						/>
						{task.progress && (
							<>
								<Typography className={styles.taskProgress}>
									{Math.trunc(task.current && task.total ? (task.current * 100) / task.total : 0)}%
								</Typography>
								<Typography className={styles.taskProgress}>
									{task.current && task.total ? (
										<>
											{task.current}/{task.total}
										</>
									) : (
										'-'
									)}
								</Typography>
							</>
						)}
					</div>
				</div>
				{(task.status === 'PROGRESS' && (
					<LinearProgress
						variant={task.progress ? 'determinate' : 'indeterminate'}
						value={task.current && task.total ? (task.current * 100) / task.total : 0}
					/>
				)) ||
					(task.status === 'PENDING' && <Skeleton height={4} animation="wave" />)}
			</div>
		</div>
	);
};

const RealtimeTasks = () => {
	const tasks = useAppSelector((state) => state.tasks.realtimeTasks);
	const { language } = useAppSelector((state: RootState) => state.lang);

	return (
		<div className={styles.realTimeTask}>
			{tasks.length ? (
				tasks.map((task, index) => <RealtimeTask task={task} index={index} />)
			) : (
				<div>{translate('tasksEmpty', language)}</div>
			)}
		</div>
	);
};

export default RealtimeTasks;
