import React, { FC } from 'react';
import LogoSvg from 'assets/images/logo.svg';
import LoadingSvg from 'assets/images/loading-init.svg';
import { MainLoading } from 'components/common';
import { useAppSelector } from 'hooks/redux';

const WithMainLoader: FC = ({ children }) => {
	const { isAuth } = useAppSelector(state => state.user);

	if ( isAuth === 'loading') return (
		<MainLoading
			logo={LogoSvg}
			logoTitle="Imot.io logo"
			loading={LoadingSvg}
			loadingTitle="Imot.io loader"
		/>
	);

	return <div>{children}</div>;
};

export default WithMainLoader;
