import React from 'react';
import styles from './styles.module.scss';
import { translate } from '../../../localizations';
import ScorePercent from './components/ScorePercent/ScorePercent';

interface IOwnProps {
	points: number;
	score: number | string;
	scorePercent: number | null;
	language: string;
}

const Score: React.FC<IOwnProps> = ({ points, score, scorePercent, language }) => {
	let condition;
	if (scorePercent !== null) {
		condition = scorePercent > 30 ? (scorePercent < 70 ? 'yellow' : 'green') : 'red';
	}

	return (
		<div className={styles.scoreWrappper}>
			<div className={styles.score}>
				{points}
				{translate('callsOf', language)}&nbsp;
				{score}
			</div>
			{condition && <ScorePercent condition={condition} scorePercent={scorePercent} />}
		</div>
	);
};

export default Score;
