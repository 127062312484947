import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IntegrationState {
	selectedIntegrationName: string | undefined;
	selectedIntegrationTitle: string | null;
	selectedIntegrationId: string | null;
}

const initialState: IntegrationState = {
	selectedIntegrationName: undefined,
	selectedIntegrationTitle: null,
	selectedIntegrationId: null,
};

const integrationSlice = createSlice({
	name: 'integration',
	initialState,
	reducers: {
		setSelectedIntegrationName: (state, action: PayloadAction<string>) => {
			state.selectedIntegrationName = action.payload;
		},
		setSelectedIntegrationTitle: (state, action: PayloadAction<string>) => {
			state.selectedIntegrationTitle = action.payload;
		},
		setSelectedIntegrationId: (state, action: PayloadAction<string>) => {
			state.selectedIntegrationId = action.payload;
		},
	},
});

export const { setSelectedIntegrationName, setSelectedIntegrationTitle, setSelectedIntegrationId } =
	integrationSlice.actions;

export default integrationSlice.reducer;
