import { ToggleFormType } from 'components/customForms/types';
import { useAPIResponseToasts, useCustomForm } from 'hooks';
import { translate } from 'localizations';
import { useAppSelector } from './redux';

const useTemplateForm = (
	initialValues: ToggleFormType,
	submitCB: (values: ToggleFormType) => Promise<{ meta: { requestStatus: 'fulfilled' | 'rejected' }}>,
) => {
	const handleNotifications = useAPIResponseToasts();
	const { language } = useAppSelector(state => state.lang);

	return useCustomForm<ToggleFormType>({
		initialValues,
		onSubmit: async (values, actions) => {
			const res = await submitCB(values);

			handleNotifications(
				res.meta.requestStatus,
				translate('profileWasUpdated', language),
				translate('profileWasNotUpdated', language),
			);

			actions.resetForm({ values });
		},
	}, []);
};

export default useTemplateForm;
