import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { NotifyRuleDetailed } from 'pages/Notifications/types/NotificationsTypes';
import { API_URL } from '../config';
import { getTokens } from '../utils/tokens';

export const notificationsAPI = createApi({
	reducerPath: 'notificationsAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: API_URL,
		prepareHeaders: (headers) => {
			const { token } = getTokens();
			if (token) {
				headers.set('authorization', `Bearer ${token}`);
			}
			return headers;
		},
	}),
	tagTypes: ['NotifyRule', 'PatchNotifyRule'],
	endpoints: (build) => ({
		// Возвращает список правил оповещения пользователя
		getNotifyRules: build.query({
			query: () => ({
				url: `/notify_rules/`,
			}),
			providesTags: [{ type: 'NotifyRule' }, { type: 'PatchNotifyRule' }],
		}),
		// Возвращает правило оповещения пользователя
		getNotifyRule: build.query({
			query: (notifyRuleId) => ({
				url: `/notify_rules/${notifyRuleId}`,
			}),
			providesTags: [{ type: 'NotifyRule' }, { type: 'PatchNotifyRule' }],
		}),
		// Возвращает переменные с шаблоном для подстановки в текст сообщения для конкретного звонка
		getNotifyRuleVariables: build.query({
			query: () => ({
				url: `/notify_rules/notify_rule_variables`,
			}),
		}),
		// Возвращает переменные с шаблоном для подстановки в текст сообщения для конкретного звонка
		getCallNotifyRuleVariables: build.query({
			query: (callId) => ({
				url: `/notify_rules/call/${callId}/notify_rule_variables`,
			}),
		}),
		// Тестирование шаблона письма
		testNotifyRuleTemplate: build.mutation({
			query: ({ callId, params }) => ({
				url: `/notify_rules/call/${callId}/test_notify_rule_template`,
				method: 'POST',
				body: params,
			}),
		}),
		// Создание правила оповещения
		createCreateNotifyRule: build.mutation({
			query: ({ params }) => ({
				url: `/notify_rules/`,
				method: 'POST',
				body: params,
			}),
		}),
		// Выполнение действий с правилом оповещения
		notifyRuleActions: build.mutation({
			query: ({ notifyRuleId, params }) => ({
				url: `/notify_rules/${notifyRuleId}/action`,
				method: 'POST',
				body: params,
			}),
		}),
		// Обновление правила оповещения пользователя
		updateNotifyRule: build.mutation({
			query: ({ notifyRuleId, params }) => ({
				url: `/notify_rules/${notifyRuleId}`,
				method: 'PUT',
				body: params,
			}),
			invalidatesTags: [{ type: 'NotifyRule' }],
		}),
		// Частичное обновление правила оповещения пользователя
		patchNotifyRule: build.mutation({
			query: ({ notifyRuleId, params }) => ({
				url: `/notify_rules/${notifyRuleId}`,
				method: 'PATCH',
				body: params,
			}),
			invalidatesTags: [{ type: 'PatchNotifyRule' }],
		}),
		// Удаление правила оповещения пользователя
		deleteUserNotify: build.mutation({
			query: ({ notifyRuleId }) => ({
				url: `/notify_rules/${notifyRuleId}`,
				method: 'DELETE',
			}),
		}),
	}),
});
