import React, { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { makeStyles } from '@mui/styles';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import { CallInfoType, CallTagType } from 'store/calls/calls.types';
import { getCallPublicToken } from 'store/calls/calls.slice';
import { IconButton } from 'components/common';
import { callsActions } from 'store/calls';
import ArrowIcon from 'components/Icons/ArrowIcon';
import CallScore from 'components/shared/Score/CallScore';
import { AccessRights, UserType } from 'store/user/user.types';
import { TCallCheckList } from 'store/checkLists/namespaces/responses';
import { TUpdateCurrentCallCheckListAnswerById } from 'store/checkLists/namespaces/payloads';
import { declinationOfNumber } from 'utils/declination';
import { translate } from 'localizations';
import { getPercents } from 'utils/numbers';
import { Share } from 'components/Icons/Share';
import { getUrlForDev } from 'utils/url';

import CallBody from './CallBody/CallBody';
import EmployeeBlock from './components/EmployeeBlock/EmployeeBlock';
import ClientBlock from './components/ClientBlock/ClientBlock';
import TagsBlock, { ISearchedWord } from './components/TagsBlock/TagsBlock';

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters TransitionProps={{ unmountOnExit: true, timeout: 0 }} elevation={0} {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {},
	borderBottom: 0,
	'&:before': {
		display: 'none',
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

type CallPropsType = {
	callInfo: CallInfoType | null;
	index: number | null;
	expanded: boolean;
	solo?: boolean;
	language: string;
	isAuth: boolean | 'loading';
	accessRights: AccessRights | null;
	loadCheckLists(callId: string): void;
	isCheckListsLoading: boolean;
	updateCheckList(target: TUpdateCurrentCallCheckListAnswerById): void;
	isLoading?: boolean;
	checkLists: TCallCheckList[];
	userInfo: UserType | null;
	currentPage?: number | undefined;
	showOpenLink?: boolean;
	showParams?: boolean;
};

const useStyles = makeStyles({
	accordion: {
		border: 'none !important',
		zIndex: '1 !important',
		backgroundColor: '#fff !important',
		borderTop: '1px solid #EEF2F6 !important',
		borderRadius: 'none !important',
		'&.Mui-expanded': {
			background: ' #F8FAFC !important',
			'&>div': {
				background: ' #F8FAFC !important',
			},
			'& #accordionSummary': {
				background: ' #F8FAFC !important',
			},
		},
	},
	accordionInner: {
		position: 'sticky',
		top: '0',
		zIndex: '101',
		display: 'flex',
		padding: '0 24px',
	},
	accordionSummary: {
		// @ts-ignore
		position: 'sticky !important',
		padding: '16px 0 !important',
		width: '100%',
		alignItems: 'flex-start !important',
		top: '0 !important', // @ts-ignore
		userSelect: 'auto !important',
		zIndex: '100 !important',
		backgroundColor: '#ffffff !important',
		cursor: 'default !important',
		'& .MuiAccordionSummary-content': {
			margin: '0 !important',
		},
		display: 'flex',
		justifyContent: 'space-between',
		transition: 'all 0.1s !important',
		'& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
			margin: '25px !important',
			position: 'relative',
			fill: '#818D9F',
			transform: 'rotate(90deg)',
		},
		'&.Mui-disabled': {
			opacity: '1 !important',
		},
	},
	summaryInfo: {
		display: 'flex',
		width: '48%',
	},
	summaryNumber: {
		minWidth: '48px',
		marginTop: '10px',
	},
	summaryInfoIndex: {
		width: '10%',
		display: 'flex',
		alignItems: 'flex-start',
	},
	summaryScore: {
		width: '25%',
		display: 'flex',
		alignItems: 'flex-start',
	},
	summaryInfoClient: {
		width: '35%',
		paddingRight: '8px',
	},
	summaryInfoEmployee: {
		width: '32%',
		paddingRight: '8px',
	},
	summaryTags: {
		width: '52%',
	},

	darkText: {
		color: '#2F3747 !important',
		fontSize: '14px !important',
		fontWeight: '600 !important',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	lightText: {
		color: '#738094 !important',
		fontSize: '14px !important',
	},
	lignthTextSmall: {
		color: '#738094 !important',
		fontSize: '12px !important',
		lineHeight: '20px',
	},
	numberText: {
		color: '#2F3747 !important',
	},
	accordionDetails: {
		backgroundColor: '#F8FAFC !important',
		border: 'none !important',
		margin: '0px !important',
		padding: '0 24px 24px 24px !important',
	},
	expandIcon: {
		display: 'flex',
		borderRadius: '5px',
		width: '32px',
		height: '32px',
		backgroundColor: '#F8FAFC',
		border: '1px solid #E3E8EF',
		cursor: 'pointer',
		justifyContent: 'center',
		paddingTop: '6px',
	},
});

const Call: React.FC<CallPropsType> = ({
	callInfo,
	index,
	language,
	isAuth,
	solo,
	expanded,
	accessRights,
	loadCheckLists,
	isCheckListsLoading,
	checkLists,
	updateCheckList,
	isLoading,
	userInfo,
	currentPage,
	showOpenLink = true,
	showParams = true,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const host = window.origin;

	React.useEffect(() => {
		if (expanded && callInfo?.id) {
			loadCheckLists(callInfo?.id);
		}
	}, [callInfo?.id, expanded, loadCheckLists]);

	const scroll = async (currentTarget: any) => {
		if (currentTarget) {
			const topValue = window.pageYOffset + currentTarget.getBoundingClientRect().top;
			await window.scrollTo({
				top: topValue,
				behavior: 'smooth',
			});
		}
	};

	async function openLinkInNewTab() {
		if (callInfo) {
			const id = callInfo.id;
			const publicTokenData = await dispatch(getCallPublicToken(id)); // @ts-ignore
			const publicToken: { access_token: string; token_type: string } = publicTokenData.payload;
			const hrefWithEnv = getUrlForDev(`${language}/call?id=${id}&token=${publicToken.access_token}`);
			const newHref = `${host}${hrefWithEnv}`;

			window.open(newHref, '_blank');
		}
	}

	const accordion = useRef<any | null>(null);
	const accordionSummary = useRef<any | null>(null);
	const audioDivRef = useRef<any | null>(null);
	const tagsDivRef = useRef<any | null>(null);
	const paramsDivRef = useRef<any | null>(null);

	const onClick = async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
		if (!callInfo) {
			return;
		}

		const selected = window.getSelection();
		const selectionText = selected?.toString();
		if (selectionText) {
			return;
		}

		if (!expanded && callInfo) {
			// сборс переведенного текста
			dispatch(callsActions.setCallTranslated(null));
			dispatch(callsActions.setExpanded({ id: callInfo.id }));
			scroll(e.target).then();
		} else {
			dispatch(callsActions.setExpanded({ id: null }));
		}
		if (accordion.current) {
			await window.scrollTo({
				top: window.scrollY + accordion.current.getBoundingClientRect().top,
				behavior: 'auto',
			});
		}
	};

	const openLinkButton =
		isAuth && !isLoading ? (
			<IconButton margin="16px 0 0 8px" backgroundColor="#F8FAFC" icon={<Share />} onClick={openLinkInNewTab} />
		) : (
			<Skeleton width={32} height={52} variant="text" style={{ margin: '5px 0 0 8px' }} />
		);

	const AccordionSummary = styled((propss: AccordionSummaryProps) => (
		<div style={{ display: 'flex', width: '100%' }}>
			<MuiAccordionSummary
				tabIndex={-1}
				expandIcon={
					!solo && (
						<div>
							{isAuth && !isLoading ? (
								<span onClick={onClick} className={classes.expandIcon}>
									<ArrowIcon />
								</span>
							) : (
								<Skeleton width={32} height={52} variant="text" style={{ margin: '-11px 0 0 8px' }} />
							)}
						</div>
					)
				}
				{...propss}
			/>
			{showOpenLink ? openLinkButton : null}
		</div>
	))(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
		'& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
			margin: '9px !important',
		},
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(180deg)',
		},
		'& .Mui-expanded': {
			background: '#F8FAFC !important',
		},
	}));

	const [currentFragment, setCurrentFragment] = useState<CallTagType | ISearchedWord | null>(null);

	const setCurrentFragmentHandle = (fragment: CallTagType | ISearchedWord | null): void => {
		setCurrentFragment(fragment);
	};

	// разделяет теги на теги и фрагменты
	const tagsAndFragmentsSeparator = () => {
		const tags = [];
		const fragments: { tag: CallTagType; count: number }[] = [];
		if (callInfo) {
			for (let i = 0; i < callInfo.tags?.length; i++) {
				// фрагменты
				if (callInfo.tags[i].fragment) {
					const currentFragmentTag = callInfo.tags[i];
					const forCallCurrentFragment = fragments.find(
						(fragment) => fragment.tag.name === currentFragmentTag.name,
					);

					if (forCallCurrentFragment) {
						const fragmentIndex = fragments.indexOf(forCallCurrentFragment);
						fragments[fragmentIndex] = {
							tag: forCallCurrentFragment.tag,
							count: (forCallCurrentFragment.count += 1),
						};
					} else {
						fragments.push({ tag: currentFragmentTag, count: 1 });
					}
				} else {
					// теги
					tags.push(callInfo.tags[i]);
				}
			}
		}
		return { tags, fragments, searchedWords: callInfo?.textSearchResult };
	};
	const tagsAndFragmentsArray = tagsAndFragmentsSeparator();

	useEffect(() => {
		function onResize(): void {
			if (accordionSummary.current && audioDivRef.current && tagsDivRef.current && paramsDivRef.current) {
				audioDivRef.current.style.top = `${accordionSummary.current.offsetHeight}px`;
				tagsDivRef.current.style.top = `${accordionSummary.current.offsetHeight + 76}px`;
				paramsDivRef.current.style.top = `${accordionSummary.current.offsetHeight + 76}px`;
			}
		}

		onResize();
		window.addEventListener('resize', onResize);
		return (): void => {
			window.addEventListener('resize', onResize);
		};
	});

	const declinationArray: string[] = [
		translate('score_1', language),
		translate('score_24', language),
		translate('score_many', language),
	];

	const hasAccessToPhone = callInfo?.communicationType === 'call' && accessRights?.phone_number;
	const hasAccessToTags = accessRights?.tags;

	return (
		<div ref={accordion}>
			<Accordion id={callInfo?.id ?? index?.toString()} className={classes.accordion} expanded={expanded}>
				<div className={classes.accordionInner} ref={accordionSummary}>
					<AccordionSummary
						id="accordionSummary"
						onClick={onClick}
						className={classes.accordionSummary}
						tabIndex={-1}
					>
						<div className={classes.summaryInfo}>
							{index && (
								<div className={classes.summaryInfoIndex}>
									<div className={classes.summaryNumber}>
										<Typography className={classes.numberText}>{index}</Typography>
									</div>
								</div>
							)}

							<div className={classes.summaryInfoClient}>
								<ClientBlock callInfo={callInfo} classes={classes} />
							</div>

							{callInfo ? (
								// TODO: попробовать убрать проверку внутри проверки
								// eslint-disable-next-line react/jsx-no-useless-fragment
								<div className={classes.summaryScore}>
									{typeof callInfo?.points === 'number' &&
										typeof callInfo?.maxPoints === 'number' && (
											<div>
												<CallScore
													scorePercent={getPercents(callInfo.points, callInfo.maxPoints)}
													score={`${callInfo.points} ${declinationOfNumber(
														callInfo.points,
														declinationArray,
													)}`}
												/>
											</div>
										)}
								</div>
							) : (
								<div className={classes.summaryScore}>
									<Skeleton width={85} height={20} variant="text" />
								</div>
							)}

							{callInfo ? (
								<div className={classes.summaryInfoEmployee}>
									<EmployeeBlock
										classes={classes}
										hasAccess={hasAccessToPhone}
										clientPhone={callInfo.clientPhone}
										duration={callInfo.duration}
									/>
								</div>
							) : (
								<Skeleton width={85} height={20} variant="text" />
							)}
						</div>

						<div className={classes.summaryTags}>
							{hasAccessToTags && (
								<TagsBlock // @ts-ignore
									tagsAndFragmentsArray={tagsAndFragmentsArray}
									callInfo={callInfo}
									classes={classes}
									expanded={expanded}
									language={language}
									accessRights={accessRights}
									setCurrentFragmentHandle={setCurrentFragmentHandle}
								/>
							)}
						</div>
					</AccordionSummary>
				</div>
				{/* Основная информация о звонке. */}
				<AccordionDetails className={classes.accordionDetails}>
					{callInfo && (
						<CallBody
							hasAccessToTags={!!hasAccessToTags}
							showParams={showParams}
							updateCheckList={updateCheckList}
							isAuth={isAuth}
							isCheckListsLoading={isCheckListsLoading}
							checkLists={checkLists}
							solo={solo}
							callInfo={callInfo}
							currentFragment={currentFragment}
							fragments={callInfo?.tags}
							audioDivRef={audioDivRef}
							tagsDivRef={tagsDivRef}
							paramsDivRef={paramsDivRef}
							userInfo={userInfo}
							currentPage={currentPage}
						/>
					)}
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

export default React.memo(Call);
