import { createEntityAdapter } from '@reduxjs/toolkit';
import { TCallCheckList, TCheckListItem } from './namespaces/responses';
import checkList from '../../components/shared/CheckListGroup/components/CheckList/CheckList';

export const callsCheckListsAdapter = createEntityAdapter<TCallCheckList>({
    selectId: checkList => checkList.id
});

export const  userCheckListsAdapter = createEntityAdapter<TCheckListItem>({
	selectId: checkList => checkList.id,
});
