import React, { FC } from 'react';
import { CallSttFragmentType } from 'store/calls/calls.types';
import useStyles from './styles.jss';
import { getAbsTimeForDialog } from '../utils';
import { DialogItemType } from './types';
import TextLineLayout from './TextLineLayout';

const ChatItem: FC<Omit<DialogItemType, 'language' | 'audioPlayerRef'>> = ({
	callId,
	prevFragment,
	fragmentIndex,
	phrase,
}) => {
	const classes = useStyles();
	const condition = phrase.direction === 'client';
	const prevChatFragment = prevFragment as CallSttFragmentType;
	const time = phrase.abs_time ? getAbsTimeForDialog(
		phrase.abs_time,
		prevChatFragment.abs_time as string,
		fragmentIndex,
	) : '';
	const speaker = prevChatFragment &&
		(prevChatFragment.speaker_name !== phrase.speaker_name ? phrase.speaker_name : '');
	const speakerNameStyles = condition ? classes.titleCustomer : classes.titleEmployee;

	return (
		<TextLineLayout
			time={time}
			speaker={speaker || ''}
			callId={callId}
			fragmentIndex={fragmentIndex}
			words={phrase.words}
			highlightIndexes={phrase.highlight_words_indexes}
			speakerClassName={classes.chatTitle}
			timeClassName={classes.chatTime}
			colorClassName={speakerNameStyles}
			lineStartPadding="0"
		/>
	)
};

export default React.memo(ChatItem);
