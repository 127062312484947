import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_URL } from '../config';
import { getTokens } from '../utils/tokens';

export const tgChatsAPI = createApi({
	reducerPath: 'tgChatsAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: API_URL,
		prepareHeaders: (headers) => {
			const { token } = getTokens();
			if (token) {
				headers.set('authorization', `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: (build) => ({
		// Список всех чатов Telegram пользователя
		getTgChats: build.query({
			query: () => ({
				url: `/tg_chats`,
			}),
		}),
		// Ключ для бота, имя бота, ссылка на бота (и для генерации QR-кода)
		getInfoForBind: build.query({
			query: () => ({
				url: `/tg_chats/token`,
			}),
		}),
		// Отправка сообщения в Telegram через бота
		sendMessageToTgChat: build.mutation({
			query: ({ params }) => ({
				url: `/tg_chats/send_message`,
				method: 'POST',
				body: params,
			}),
		}),
		// Удаление чата по id
		deleteTgChat: build.mutation({
			query: ({ chatId }) => ({
				url: `/tg_chat/${chatId}`,
				method: 'DELETE',
			}),
		}),
	}),
});
