import React, { FC } from 'react';
import { Button } from '@mui/material';
import cn from 'classnames';
import s from './styles.module.scss';

interface UniversalButtonProps {
	text: string;
	handleClick: () => void;
	className?: string;
	testId?: string;
	buttonStyle?: 'outlined' | 'filled' | 'plain';
	icon?: React.ReactNode;
	disabled?: boolean;
}

const UniversalButton: FC<UniversalButtonProps> = ({
	text,
	handleClick,
	className,
	testId,
	buttonStyle,
	icon,
	disabled,
}) => (
	<Button
		className={cn(s.root, className, {
			[`${s.outlined}`]: buttonStyle === 'outlined',
			[`${s.filled}`]: buttonStyle === 'filled',
			[`${s.plain}`]: buttonStyle === 'plain',
			[`${s.disabled}`]: disabled,
		})}
		startIcon={icon || null}
		onClick={handleClick}
		data-testid={testId || ''}
		disabled={disabled}
	>
		{text}
	</Button>
);

export default UniversalButton;
