import React from 'react';

export const RedTrash: React.FC = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M6.32812 3.23438H6.1875C6.26484 3.23438 6.32812 3.17109 6.32812 3.09375V3.23438H11.6719V3.09375C11.6719 3.17109 11.7352 3.23438 11.8125 3.23438H11.6719V4.5H12.9375V3.09375C12.9375 2.47324 12.433 1.96875 11.8125 1.96875H6.1875C5.56699 1.96875 5.0625 2.47324 5.0625 3.09375V4.5H6.32812V3.23438ZM15.1875 4.5H2.8125C2.50137 4.5 2.25 4.75137 2.25 5.0625V5.625C2.25 5.70234 2.31328 5.76562 2.39062 5.76562H3.45234L3.88652 14.959C3.91465 15.5584 4.41035 16.0312 5.00977 16.0312H12.9902C13.5914 16.0312 14.0854 15.5602 14.1135 14.959L14.5477 5.76562H15.6094C15.6867 5.76562 15.75 5.70234 15.75 5.625V5.0625C15.75 4.75137 15.4986 4.5 15.1875 4.5ZM12.8549 14.7656H5.14512L4.71973 5.76562H13.2803L12.8549 14.7656Z"
			fill="#FF7875"
		/>
	</svg>
);
