/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useEffect, FC, memo } from 'react';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { InputWithHeader } from 'shared/ui';
import { BlockBox, CustomCheckbox } from 'components/common';
import { translate } from 'localizations';
import { userSlice, getSTTLanguages, getSTTEngines, getSTTModels, OptionType } from 'store/user/user.slice';
import { UserType } from 'store/user/user.types';
import { LangType } from 'store/lang/lang.slice';
import styles from './STT.module.scss';
import STTSelect from './components/STTSelect/STTSelect';
import { Values } from './namespaces';
import { validationSchema } from './schemas/sttSchema';
import { LANGUAGE_DEFAULT } from './consts';
import WithSTTSkeleton from './components/STTSkeleton/WithSTTSkeleton';

import '../../Settings.css';

interface STTProps {
	user: UserType | null;
	STTLanguagesList: OptionType[];
	STTEnginesList: OptionType[];
	STTModelsList: OptionType[];
	language: LangType | string;
	onSubmitFunction: (values: Values) => void;
}

const STT: FC<STTProps> = ({ user, STTLanguagesList, STTEnginesList, STTModelsList, language, onSubmitFunction }) => {
	const dispatch = useDispatch();
	const [contentIsLoading, setContentIsLoading] = useState(true);
	const fetchSTTLanguages = async () => dispatch(getSTTLanguages());
	const fetchSTTEngines = async (language: string) => dispatch(getSTTEngines(language));
	const fetchSTTModels = async (info: { language: string; engine: string }) => dispatch(getSTTModels(info));

	const defaultValues = {
		sttLanguage: '',
		sttEngine: '',
		sttModel: '',
		sttEconomize: false,
		sttCountPerIteration: 0,
	};

	const formik = useFormik<Values>({
		// @ts-ignore
		initialValues: defaultValues,
		validationSchema,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: async (values: Values) => {
			await onSubmitFunction(values);
		},
	});

	const setModel = async (language: string, engine: string) => {
		const models = await fetchSTTModels({ language, engine }); // @ts-ignore
		const modelsArray = models.payload;
		const model = modelsArray.find((item: OptionType) => item.value === user?.sttModel) || '';
		if (model) {
			setContentIsLoading(true);
		}
		setContentIsLoading(true);
		return { sttModel: model };
	};

	const setEngine = async (languageSTT: OptionType) => {
		const engines = await fetchSTTEngines(languageSTT.value); // @ts-ignore
		const enginesPayload = engines.payload;
		let sttModel = '';
		let sttEngine = '';

		if (enginesPayload.data.length && user?.sttEngine) {
			const engine = enginesPayload.data.find((item: OptionType) => item.value === user?.sttEngine);
			sttEngine = engine;

			if (engine) {
				const res = await setModel(enginesPayload.language, engine.value);
				sttModel = res.sttModel;
			} else {
				setContentIsLoading(true);
			}
		}

		return { sttEngine, sttModel }
	};

	const setLanguage = async () => {
		setContentIsLoading(true);
		const languages = await fetchSTTLanguages(); // @ts-ignore
		const languagesArray = languages.payload;
		let languageSTT;
		let model = '';
		let engine = '';

		if (user?.sttLanguage === '') {
			languageSTT = LANGUAGE_DEFAULT;
		} else {
			languageSTT = languagesArray.find((item: OptionType) => item.value === user?.sttLanguage);
		}

		if (languageSTT) {
			const { sttModel, sttEngine,} = await setEngine(languageSTT);
			model = sttModel;
			engine = sttEngine;
		} else {
			setContentIsLoading(true);
		}

		return {
			sttLanguage: languageSTT,
			sttModel: model,
			sttEngine: engine,
		}
	};

	const getUserSTTSettings = async () => {
		const { sttLanguage, sttModel, sttEngine } = await setLanguage();
		setContentIsLoading(false);
		formik.resetForm({
			values: {
				sttLanguage,
				// @ts-ignore
				sttEngine,
				// @ts-ignore
				sttModel,
				sttEconomize: !!user?.sttEconomize,
				sttCountPerIteration: user?.sttCountPerIteration
			}
		});
	}

	useEffect(() => {
		if (user) getUserSTTSettings();
	}, [user]);

	const isDisabled = !formik.dirty
		|| !formik.values.sttLanguage || !formik.values.sttEngine || !formik.values.sttModel;

	return (
		<BlockBox padding="24px">
			<form onSubmit={formik.handleSubmit}>
				<WithSTTSkeleton showChildren={!contentIsLoading}>
					<div className={styles.sttContent}>
						<div className="users-info-line first-users-info-line">
							<STTSelect
								title={translate('sttLanguage', language)}
								placeholder={translate('sttSetLang', language)}
								isDisabled={false}
								onSelectionChange={async (e) => {
									formik.setFieldValue('sttLanguage', e);
									if (e.value !== formik.values.sttLanguage?.value) {
										dispatch(userSlice.actions.setSTTEngines([]));
										dispatch(userSlice.actions.setSTTModels([]));
										formik.setFieldValue('sttEngine', '');
										formik.setFieldValue('sttModel', '');
										await fetchSTTEngines(e.value);

										formik.setErrors({
											sttEngine: {
												label: 'is required',
												value: 'is required',
											}
										});
									}
								}}
								options={STTLanguagesList}
								value={formik.values.sttLanguage}
								errors={formik.errors.sttLanguage?.value}
								language={language}
							/>
						</div>
						<div className="users-info-line">
							<STTSelect
								title={translate('sttEngine', language)}
								placeholder={translate('sttSetEngine', language)}
								isDisabled={!formik.values.sttLanguage?.value}
								onSelectionChange={async (e) => {
									formik.setFieldValue('sttEngine', e);
									if (e.value !== formik.values.sttEngine?.value) {
										dispatch(userSlice.actions.setSTTModels([]));
										formik.setFieldValue('sttModel', '');
										await fetchSTTModels({ language: formik.values.sttLanguage?.value, engine: e.value });

										formik.setErrors({
											sttModel: {
												label: 'is required',
												value: 'is required',
											}
										});
									}
								}}
								options={STTEnginesList}
								value={formik.values.sttEngine}
								errors={formik.errors.sttEngine?.value}
								language={language}
							/>
							<STTSelect
								title={translate('sttModel', language)}
								placeholder={translate('sttSetModel', language)}
								isDisabled={!formik.values.sttEngine.value}
								onSelectionChange={(e) => {
									formik.setFieldValue('sttModel', e);
									formik.setErrors({});
								}}
								options={STTModelsList}
								value={formik.values.sttModel}
								errors={formik.errors.sttModel?.value}
								language={language}
							/>
						</div>
						<p className="section-sub-sub-title">{translate('sttOrder', language)}</p>
						<CustomCheckbox
							rootClassname={styles.sttEconomize}
							style={{ marginRight: '10px' }}
							id="sttEconomize"
							tooltip={translate('sttEconomize_tooltip', language)}
							label={translate('sttEconomize', language)}
							checked={formik.values.sttEconomize}
							onChange={(e) => {
								formik.setFieldValue('sttEconomize', e.target.checked);
							}}
						/>
						<InputWithHeader
							inputClassName={styles.sttCountPerIteration}
							tooltipTitle={translate('sttCountPerIteration_tooltip', language)}
							placeholder="0"
							inputType="number"
							title={`${translate('sttCountPerIteration', language)}`}
							externalValue={`${formik.values.sttCountPerIteration}`}
							onChangeValue={(value) => {
								const newValue = parseInt(value, 10);
								const isValid = newValue >= 0 && newValue < 11;
								if (isValid) {
									formik.setFieldValue('sttCountPerIteration', newValue);
								}
							}}
						/>
					</div>
				</WithSTTSkeleton>
				<div className={styles.controlButtonsBlock}>
					<LoadingButton color="primary" variant="contained" type="submit" disabled={isDisabled}>
						{translate('saveButton', language)}
					</LoadingButton>
				</div>
			</form>
		</BlockBox>
	);
};

export default memo(STT);
