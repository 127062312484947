/* eslint-disable */
/**
 * функция сравнения массивов
 */

export function arrayCompare(array1: any[] | null, array2: any[] | null) {
	if (array1 && array2) {
		return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
	}
	return;
}

/**
 * сортировка
 */

export const sortArray = (data: []): [] => {
	return data.sort(function (a: string, b: string) {
		const one = a.toLowerCase();
		const two = b.toLowerCase();
		return one === two ? 0 : one < two ? -1 : 1;
	});
};
