import { OptionType } from 'components/common/Selects/MultiValueSelect/multiValuesSelect';

export const converter = (state: any) => {
	const local: { value: string, label: string }[] = [];
	if (state?.values) {
		for (let i = 0; i < state.values.length; i++) {
			local.push({ value: state.values[i], label: state.values[i] });
		}
	}
	return local;
};
export const eventConverter = (values: OptionType[]) => {
	const result = [];
	for (let i = 0; i < values.length; i++) {
		result.push(values[i].value);
	}
	return result;
};
