/* eslint-disable */
import { FC, useEffect, useState, memo } from 'react';
import Select from 'react-select';
import { IconButton, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import RealtimeTasks from './RealtimeTasks';
import Tooltip from '../common/Tooltip/Tooltip';
import { useAppSelector } from '../../hooks/redux';
import { RootState } from '../../store/store';
import { translate } from '../../localizations';
import { tasksSlice } from '../../store';
import { Bell } from '../Icons/BellIcon';

const BaleSelect: FC = () => {
	const [isMenuOpen, setMenuOpen] = useState(false);
	const { language } = useAppSelector((state: RootState) => state.lang);
	const newTaskMessage = useAppSelector((state) => state.tasks.newTaskMessage);

	const ballSelect = () => ({
		menu: (provided: any, state: any) => ({
			...provided,
			width: '322px',
			right: '0',
			overflow: 'hidden',
			overflowY: 'auto',
			zIndex: '1000',
			margin: '0',
			padding: '0',
			'&::-webkit-scrollbar': {
				width: '4px',
				backgroundColor: '#f1f1f1',
				outline: 'none',
			},
			'&::-webkit-scrollbar-thumb': {
				background: '#A3AEBE',
				height: '50px',
				borderRadius: '10px',
			},
			'&::-webkit-scrollbar-thumb:hover': {
				background: '#9298A1',
			},
		}),
	});

	const classes = ballSelect();
	const dispatch = useDispatch();
	const Control: FC<any> = ({ children, ...props }) => (
		<div>
			<Tooltip title={translate('tasksQueue', language)} placement="bottom">
				<IconButton
					size="small"
					onClick={() => {
						setMenuOpen(!isMenuOpen);
						dispatch(tasksSlice.actions.setNewTaskMessage(false));
					}}
				>
					{newTaskMessage && (
						<div
							style={{
								top: '3px',
								right: '5px',
								position: 'absolute',
								backgroundColor: 'red',
								width: '5px',
								height: '5px',
								borderRadius: '50%',
							}}
						/>
					)}
					<Bell />
				</IconButton>
			</Tooltip>
		</div>
	);
	const CustomMenuList = memo(({ selectProps, ...props }: any) => (
		<div
			style={{
				padding: '24px',
			}}
		>
			<Typography>{translate('tasksQueue', language)}</Typography>
			<RealtimeTasks />
		</div>
	));

	// костылечек для загрытия менюшки.
	useEffect(() => {
		document.addEventListener('mousedown', () => setMenuOpen(false));
		return () => {
			document.removeEventListener('mousedown', () => setMenuOpen(false));
		};
	}, []);

	return (
		<Select
			styles={classes}
			options={[]}
			value={[]}
			menuIsOpen={isMenuOpen}
			openMenuOnFocus
			isClearable
			closeMenuOnSelect={false}
			isSearchable={false}
			hideSelectedOptions={false}
			components={{
				Control,
				MenuList: CustomMenuList,
			}}
		/>
	);
};

export default BaleSelect;
