import { useSnackbar } from 'notistack';
import { createSnackbarOptions, SnackbarParamsType } from 'components/common/Snackbar/Snackbar';

const useAPIResponseToasts = (
) => {
	const { enqueueSnackbar } = useSnackbar();

	return (
		requestStatus: 'fulfilled' | 'rejected',
		successText: string,
		errorText: string,
	) => {
		const isSuccess = requestStatus === 'fulfilled';
		const message = {
			text: isSuccess ? successText : errorText,
			type: isSuccess ? 'success' : 'error',
		};
		enqueueSnackbar(null, createSnackbarOptions({
			text: message.text,
			time: 2000,
			type: message.type as SnackbarParamsType,
		}));
	}
};

export default useAPIResponseToasts;
