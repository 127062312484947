import React, { FC } from 'react';
import { timeConverter } from 'components/common/Buttons/Time';
import { translate } from 'localizations';
import useStyles from './styles.jss';
import { DialogItemType } from './types';
import TextLineLayout from './TextLineLayout';

const DialogItem: FC<DialogItemType> = ({
	callId,
	prevFragment,
	fragmentIndex,
	phrase,
	audioPlayerRef,
	language,
}) => {
	const classes = useStyles();
	const condition = phrase.direction === 'client';
	const time = timeConverter(phrase.begin, false);
	const speaker = phrase.direction === 'client'
		? translate('callsCustomer', language)
		: translate('callsEmployee', language);

	return (
		<TextLineLayout
			time={time}
			speaker={prevFragment && (prevFragment.direction !== phrase.direction ? speaker : '')}
			callId={callId}
			fragmentIndex={fragmentIndex}
			words={phrase.words}
			highlightIndexes={phrase.highlight_words_indexes}
			handleWordClick={(i: number) => {
				audioPlayerRef.current.audioEl.current.play();
				audioPlayerRef.current.audioEl.current.currentTime = phrase.words[i].begin / 1000 + 0.01;
			}}
			colorClassName={condition ? classes.titleCustomer : classes.titleEmployee}
		/>
	);
};

export default React.memo(DialogItem);
