import { FormattedTags, FragmentTag } from '../types';

const delimiter = ' ';
export const getDateFromAbsTime = (time: string) => time.split(delimiter)[0]

export const getTimeFromAbsTime = (time: string) => time.split(delimiter)[1]

const getTimeUnits = (
	absTime: string,
	prevAbsTime: string,
) => ({
	prevDate: getDateFromAbsTime(prevAbsTime),
	prevTime: getTimeFromAbsTime(prevAbsTime),
	currentDate: getDateFromAbsTime(absTime),
	currentTime: getTimeFromAbsTime(absTime),
})

export const getComparedAbsTime = (
	absTime: string,
	prevAbsTime: string,
) => {
	const {
		prevDate,
		prevTime,
		currentDate,
		currentTime,
	} = getTimeUnits(absTime, prevAbsTime);

	let newTime = '';

	if (prevDate !== currentDate) {
		newTime = currentDate;
	}

	if (prevTime !== currentTime) {
		newTime = `${newTime} ${currentTime}`;
	}

	return newTime;
}

export const getAbsTimeForDialog = (
	absTime: string,
	prevAbsTime: string,
	fragmentIndex: number,
) => {
	if (fragmentIndex === 0) return absTime;

	return getComparedAbsTime(absTime, prevAbsTime);
}

export const getFormattedTags = (tags: FragmentTag[]): FormattedTags => ({
	trimmedTags: tags.length > 5
		? tags.slice(0, 5) : tags,
	reducedTags: tags.length > 5
		? tags.slice(5) : [],
})
