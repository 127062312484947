import { criteriaWithComplexValues } from 'configs/searchCriteria';
import { CriteriasType } from '../search.types';

/**
 * Получаем критерии с complexValues и обычные критерии
 */
export const sortCriteria = <T>(
	activeCriterias: (T & {key: string})[],
) => {
	const complexCriteria: (T & {key: string})[] = [];
	const otherCriteria: (T & {key: string})[] = [];

	activeCriterias.forEach(item => {
		if (criteriaWithComplexValues.includes(item.key)) {
			complexCriteria.push(item);
		} else {
			otherCriteria.push(item);
		}
	});

	return { complexCriteria, otherCriteria };
};

export const getSortedCriteriaObj = <T>(complexCriteria: (T & {key: string})[]) => {
	const complexCriteriaObj: Record<string, any[]> = {};

	complexCriteria.forEach(item => {
		if (complexCriteriaObj[item.key]) {
			complexCriteriaObj[item.key] = [...complexCriteriaObj[item.key], item]
		} else {
			complexCriteriaObj[item.key] = [item]
		}
	});

	return complexCriteriaObj;
}

const getUpdatedComplexCriteria = (
	allComplexCriteria: CriteriasType[],
	currentCriteria: CriteriasType,
) => {
	let hasThisData = false;
	const updatedComplexCriteria = allComplexCriteria.map(item => {
		if (item.conditionItemId === currentCriteria.conditionItemId && item.key === currentCriteria.key) {
			hasThisData = true;
			return currentCriteria;
		}
		return item;
	});

	if (!hasThisData) updatedComplexCriteria.push(currentCriteria);

	return updatedComplexCriteria;
};

/**
 * Получаем все активные критерии, включая критерии с complexValues
 */
export const getCriteriaWithComplexValues = (
	key: string,
	activeCriterias: CriteriasType[],
	currentCriteria: CriteriasType,
): CriteriasType[] => {
	const { complexCriteria, otherCriteria } = sortCriteria(activeCriterias);
	if (!complexCriteria.length) {
		return [
			...otherCriteria,
			{
				...currentCriteria,
				values: currentCriteria.values,
				conditionItemId: currentCriteria.conditionItemId,
			},
		];
	}
	const updatedComplexCriteria = getUpdatedComplexCriteria(complexCriteria, currentCriteria);
	return [...otherCriteria, ...updatedComplexCriteria];
};

export const deleteItemByProp = <T>(
	criterias: (T & {key: string})[],
	property: keyof T,
	value: number | undefined,
	key: string,
) => criterias.filter(item => !(item.key === key && item[property] === value))

export const removeFromComplexCriteria = <T>(
	activeData: (T & {key: string})[],
	conditionItemId: number | undefined,
	key: string,
) => {
	const { complexCriteria, otherCriteria } = sortCriteria(activeData);
	const updatedComplexCriteria = deleteItemByProp(complexCriteria, 'conditionItemId' as keyof T, conditionItemId, key);
	return [...otherCriteria, ...updatedComplexCriteria];
};
