import React, { memo, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import cn from 'classnames';
import { translate } from 'localizations';
import { useAppSelector } from 'hooks/redux';
import { getCallStt } from 'store/calls/calls.slice';
import queryString from 'querystring';
import { callsActions } from 'store/calls';
import { getCallInfo } from 'store/calls/actions';
import { selectCurrentCall, selectIsCallsLoad } from 'store/calls/selectors';
import { getCheckListsByCallId, updateCurrentCallCheckListAnswerById } from 'store/checkLists/actions';
import { selectCallsCheckLists, selectIsCallsCheckListsLoading } from 'store/checkLists/selectors';
import { TUpdateCurrentCallCheckListAnswerById } from 'store/checkLists/namespaces/payloads';
import { getAllLanguages } from 'store/lang/lang.slice';
import { getUser } from 'store/user/user.slice';
import Call from '../Calls/Call';
import CallProgress from './components/CallProgress/CallProgress';
import CallBackToMain from './components/CallBackToMain/CallBackToMain';

interface CallPageProps {
	showOpenLink?: boolean;
	showParams?: boolean;
	callPageBoxStyles?: string;
	marginTop?: string;
}

const CallPage = memo(({
	showOpenLink = true,
	showParams = true,
	callPageBoxStyles,
	marginTop,
}: CallPageProps) => {
	const useStyles = makeStyles({
		callPageBox: {
			boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
		},
	});
	const classes = useStyles();

	const history = useHistory();
	const dispatch = useDispatch();

	const currentCall = useAppSelector(selectCurrentCall);
	const currentUser = useAppSelector((state) => state.user.user);
	const isAuth = useAppSelector((state) => state.user.isAuth);
	const accessRights = useAppSelector((state) => state.user.accessRights);
	const { language } = useAppSelector((state) => state.lang);
	const user = useAppSelector((state) => state.user.user);
	const isCheckListsLoading = useAppSelector(selectIsCallsCheckListsLoading);
	const checkLists = useAppSelector(selectCallsCheckLists);

	const isLoading = useAppSelector(selectIsCallsLoad);

	const [userInfo, setUserInfo] = useState(null);

	useEffect(() => {
		if (user) {
			const fetchUserInfo = async () => {
				const userInfoFetch = await dispatch(getUser({ id: user.id })); // @ts-ignore
				setUserInfo(userInfoFetch.payload);
			};
			fetchUserInfo().catch(console.error);
		}
	}, [user]);

	const fetchCallInfoData = useCallback(async () => {
		const searchParamsObject = queryString.parse(history.location.search.slice(1));
		const id = searchParamsObject.id;
		const token = searchParamsObject.token;
		if (id && token) {
			const callInfoData = await dispatch(getCallInfo({ id, token })); // @ts-ignore
			const callInfo = callInfoData.payload;
			if (callInfo) {
				dispatch(
					callsActions.setCurrentCall({
						// @ts-ignore
						id,
						info: callInfo,
						stt: null,
						token: null,
					}),
				);
				dispatch(callsActions.setInfo(callInfo));
				const callSttData = await dispatch(getCallStt({ id, token })); // @ts-ignore
				const callStt = callSttData.payload;
				if (callStt) {
					await dispatch(callsActions.setStt(callStt));
				} else {
					dispatch(callsActions.setCurrentCall(false));
				}
			} else {
				dispatch(callsActions.setCurrentCall(false));
			}
		} else {
			dispatch(callsActions.setCurrentCall(false));
		}
	}, []);

	// добавление параметров поиска
	useEffect(() => {
		fetchCallInfoData().then();
	}, []);

	useEffect(() => {
		document.title = 'Звонок | IMOT.io';
	}, []);

	useEffect(() => {
		document.title = `${translate('callDocTitle', language)} | IMOT.io`;
	}, [language]);

	const loadCheckListsByCallId = React.useCallback(
		(callId: string) => {
			dispatch(getCheckListsByCallId(callId));
		},
		[dispatch],
	);

	const updateCheckList = React.useCallback(
		(taget: TUpdateCurrentCallCheckListAnswerById): void => {
			dispatch(updateCurrentCallCheckListAnswerById(taget));
		},
		[dispatch],
	);

	useEffect(() => {
		dispatch(getAllLanguages());
	}, [dispatch]);

	return (
		<div style={{ marginTop: marginTop || '40px' }}>
			{currentCall && (
				<div className={cn(classes.callPageBox, callPageBoxStyles)}>
					<Call
						showOpenLink={showOpenLink}
						showParams={showParams}
						updateCheckList={updateCheckList}
						isCheckListsLoading={isCheckListsLoading}
						checkLists={checkLists}
						loadCheckLists={loadCheckListsByCallId}
						callInfo={currentCall.info}
						index={null}
						solo
						expanded
						language={language}
						isAuth={isAuth}
						accessRights={accessRights}
						isLoading={isLoading}
						userInfo={userInfo}
					/>
				</div>
			)}
			{currentCall === null && <CallProgress />}
			{currentCall === false && <CallBackToMain language={language} isAuth={isAuth} currentUser={currentUser} />}
		</div>
	);
});

export default CallPage;
