import { FC } from 'react';
import { useAppSelector } from 'hooks/redux';
import { AdminBar } from 'components/common';

const WithAdminBar: FC = ({ children }) => {
	const { loggedUser } = useAppSelector(state => state.user);
	const hasAdminBar = loggedUser && (loggedUser.role === 'admin' || loggedUser.role === 'manager');

	return (
		<>
			{hasAdminBar && <AdminBar user={loggedUser} />}
			{children}
		</>
	)
};

export default WithAdminBar;
