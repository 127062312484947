import { ChildUserType, UserType } from 'store/user/user.types';
import { OptionType } from 'components/common/Selects/MultiValueSelect/multiValuesSelect';
import { convertSecondsToMin } from './convertDateAndTime/dateTimeFormating';

export const optionsUserChangerCreator = (
	childUsers: ChildUserType[] | null,
) => {
	const result = [];
	if (childUsers) {
		for (let i = 0; i < childUsers.length; i++) {
			if (['user', 'admin', 'manager'].find((role) => role === childUsers[i].role)) {
				result.push({
					value: childUsers[i].id,
					label: childUsers[i].name ? childUsers[i].name : childUsers[i].login,
				});
			}
		}
		return result;
	}
	return [];
};

export const getConvertedUserValue = (
	convertedOptions: OptionType[],
	childUser: ChildUserType | null,
	user: UserType | null,
) => convertedOptions.find((option) => childUser && (option.value === childUser.id)) || ({
	label: (user && (user.name ? user.name : user.login)),
	value: user && user.id,
});

export const getUsersForImport = (
	childUsers: ChildUserType[] | null,
	currentUserId: string,
) => {
	const convertedOptions = optionsUserChangerCreator(childUsers);
	return convertedOptions.filter(option => option.value !== currentUserId);
};

export const getExpiredQuota = (
	quotaRemindTime: number,
	quotaTotalTime: number,
) => {
	if (quotaTotalTime === 0) return true;
	return (quotaRemindTime / quotaTotalTime) < 0.1
};

export const getQuotaData = (
	quotaRemindTime: number | undefined,
	quotaTotalTime: number | undefined,
) => {
	if (quotaRemindTime !== undefined && quotaTotalTime !== undefined) {
		const quotaInMinutes = convertSecondsToMin(quotaRemindTime);
		const quotaEndsSoon = getExpiredQuota(quotaRemindTime, quotaTotalTime);

		return { quotaInMinutes, quotaEndsSoon };
	}
	return { quotaInMinutes: undefined, quotaEndsSoon: undefined };
}


