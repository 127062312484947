import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	diItem: {
		display: 'flex',
		margin: '3px 0',
		alignItems: 'start',
		position: 'relative',
		width: '100%',
		paddingRight: '16px',
	},
	diBodyText: {
		padding: '0 2px !important',
		display: 'flex',
		flexWrap: 'wrap',
		position: 'relative',
		height: '100% !important',
		lineHeight: '22px !important',
		color: '#2F3747 !important',
		paddingTop: '0px',
	},
	dateTypography: {
		fontSize: '14px !important',
		userSelect: 'none',
		WebkitUserSelect: 'none',
		MozUserSelect: 'none',
		msUserSelect: 'none',
	},
	diTagsBlock: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		margin: '0 16px',
	},
	diFragment: {},
	typographyTitle: {
		textAlign: 'right',
		color: '#2F3747 !important',
		fontWeight: '700 !important',
		minWidth: '95px !important',
		userSelect: 'none',
		WebkitUserSelect: 'none',
		MozUserSelect: 'none',
		msUserSelect: 'none',
	},
	titleCustomer: {
		color: '#0009FF !important',
	},
	titleEmployee: {
		color: '#FF0E00 !important',
	},
	tagMatch: {
		padding: '0 2px',
		fontWeight: 700,
		backgroundColor: '#EFDBFF !important',
	},
	// chat styles
	chatTime: {
		display: 'inline-block',
		width: '75px',
		flexShrink: '0',
		WebkitUserSelect: 'none',
		MozUserSelect: 'none',
		msUserSelect: 'none',
	},
	chatTitle: {
		display: 'inline-block',
		width: '170px',
		flexShrink: '0',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		marginRight: '10px !important',
		textAlign: 'left',
	},
});

export default useStyles;
