import React from 'react';

const ArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.3911 6.39974C11.6104 6.63642 11.6104 7.02016 11.3911 7.25682L8.39697 10.4892C8.17773 10.7258 7.82232 10.7258 7.60307 10.4892L4.60889 7.25682C4.38965 7.02016 4.38965 6.63642 4.60889 6.39974C4.82813 6.16306 5.18359 6.16306 5.40283 6.39974L8.00002 9.20349L10.5972 6.39974C10.8165 6.16306 11.1719 6.16306 11.3911 6.39974Z"
			fill="#738094"
		/>
	</svg>
);

export default ArrowIcon;
