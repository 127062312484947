import React from 'react';

export const Pensil: React.FC = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M10.9548 2.625C11.0543 2.625 11.1497 2.66451 11.22 2.73484L13.3413 4.85615C13.4877 5.00261 13.4877 5.24004 13.3413 5.38649L6.44704 12.2808C6.39993 12.3279 6.34111 12.3616 6.27665 12.3785L3.40533 13.1285C3.27652 13.1621 3.13953 13.1249 3.04539 13.0308C2.95125 12.9367 2.91408 12.7997 2.94773 12.6709L3.69773 9.7995C3.71457 9.73507 3.74828 9.67628 3.79539 9.6291L10.6896 2.73484C10.76 2.66451 10.8554 2.625 10.9548 2.625Z"
			fill="#A3AEBE"
		/>
		<path
			d="M3 14.4375C2.68934 14.4375 2.4375 14.6894 2.4375 15C2.4375 15.3106 2.68934 15.5625 3 15.5625H14.25C14.5606 15.5625 14.8125 15.3106 14.8125 15C14.8125 14.6894 14.5606 14.4375 14.25 14.4375H3Z"
			fill="#A3AEBE"
		/>
	</svg>
);
