import React from 'react';
import { Typography } from '@mui/material';
import { translate } from '../../../../localizations';
import CallsFragments from '../CallsFragments/CallsFragments';
import CallsTags from '../CallsTags/CallsTags';
import { CallInfoType, CallTagType } from '../../../../store/calls/calls.types';
import { AccessRights } from '../../../../store/user/user.types';

export interface ISearchedWord {
	begin: number;
	fragment: string;
	key: string | null;
	text: string;
	fBegin?: number
}

interface IOwnProps {
	setCurrentFragmentHandle: (value: CallTagType | ISearchedWord) => void;
	callInfo: CallInfoType | null;
	expanded: boolean;
	accessRights: AccessRights | null;
	language: string;
	// TODO: Igor - fix any types
	tagsAndFragmentsArray: {
		tags: any[];
		fragments: any[];
		searchedWords?: ISearchedWord[];
	};
	classes: any;
}

const TagsBlock: React.FC<IOwnProps> = (
	{
		tagsAndFragmentsArray,
		callInfo,
		expanded,
		setCurrentFragmentHandle,
		accessRights,
		language,
		classes,
	},
) => {
	return (
		<div style={{ width: '100%', display: 'flex' }}>
			<div style={{ width: '45px' }}>
				{tagsAndFragmentsArray.fragments.length > 0 && (
					<Typography style={{ marginRight: '16px' }} className={classes.lignthTextSmall}>
						{translate('tags', language)}
					</Typography>
				)}
			</div>
			<div style={{ width: '100%' }}>
				{callInfo && (
					<div style={{ position: 'absolute', top: '-2px', display: 'flex' }}>
						{callInfo?.unparsedByQuota && (
							<Typography
								style={{
									color: '#9a0c21',
									fontWeight: '700',
									fontSize: '11px',
								}}
							>
								{translate('overQuota', language)}
							</Typography>
						)}
					</div>
				)}
				{/* Fragments */}
				{tagsAndFragmentsArray.fragments.length > 0 ? (
					<CallsFragments
						expanded={expanded}
						setCurrentFragmentHandle={setCurrentFragmentHandle}
						tagsAndFragmentsArray={tagsAndFragmentsArray}
					/>
				) : null}
				{/* CallsTags */}
				{accessRights && accessRights.tags && (
					<CallsTags
						language={language}
						tagsAndFragmentsArray={tagsAndFragmentsArray}
						callInfo={callInfo}
					/>
				)}
			</div>
		</div>
	);
};

export default React.memo(TagsBlock);
