import { FC } from 'react';
// import { ContainedSelect } from 'components/common';
import { translate } from 'localizations';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'pages/Settings/types';
import ContainedSelect from '../../../components/common/Selects/ContainedSelect/ContainedSelect';

type SortOption = {
	value: string;
	sort_desc: boolean;
	label: string;
};

interface SortProps {
	language: string;
	page?: string;
	currentSort: {
		sort: string;
		sort_desc: boolean;
	};
	onSortChange: (option: { value: string; sort_desc: boolean }) => void;
	options?: SortOption[];
}

const SortComponent: FC<SortProps> = ({ page, currentSort, onSortChange, options, language }) => {
	const generateSortArray = () => {
		const sortArray = [
			{ value: 'priority', sort_desc: true, label: translate('high_priority_first', language) },
			{ value: 'priority', sort_desc: false, label: translate('low_priority_first', language) },
			{ value: 'title', sort_desc: false, label: translate('alphabetically', language) },
			{ value: 'title', sort_desc: true, label: translate('alphabetically_from_the_end', language) },
			{ value: 'add_time', sort_desc: true, label: translate('first_new', language) },
			{ value: 'add_time', sort_desc: false, label: translate('first_old', language) },
			{ value: 'update_time', sort_desc: true, label: translate('updated_first', language) },
			{ value: 'update_time', sort_desc: false, label: translate('not_updated_first', language) },
		];

		if (page === 'dicts') {
			sortArray.splice(0, 2);
		}

		return sortArray;
	};

	const sortArray = generateSortArray();

	return (
		<ContainedSelect
			width="100%"
			onSelectChange={onSortChange}
			options={options || sortArray}
			value={sortArray.find(
				(sortItem) => sortItem.value === currentSort.sort && sortItem.sort_desc === currentSort.sort_desc,
			)}
		/>
	);
};

export default SortComponent;
