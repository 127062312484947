import { useAppSelector } from './redux';

const useAccessToManagerUsers = () => {
	const { childUser, loggedUser } = useAppSelector(state => state.user);

	const isAdminWithManager = loggedUser?.role === 'admin' && childUser?.role === 'manager';
	const isNotAdmin = loggedUser?.role !== 'admin' || !!(childUser && childUser.role !== 'admin');
	const isManagerWithoutChild = loggedUser?.role === 'manager' && !childUser;

	return {
		isAdminWithManager,
		isNotAdmin,
		isManagerWithoutChild,
	}
};

export default useAccessToManagerUsers;
