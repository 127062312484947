import { getQuotaData } from 'utils/user';
import { TextWithIndicator } from 'components/common';

const useQuotaIndicator = (
	quotaRemindTime: number | undefined,
	quotaTotalTime: number | undefined,
) => {
	const { quotaInMinutes, quotaEndsSoon } = getQuotaData(quotaRemindTime, quotaTotalTime);

	return quotaRemindTime !== undefined && quotaTotalTime !== undefined
		? (
			<TextWithIndicator
				showIndicator
				color={quotaEndsSoon ? '#F5222D' : '#52C41A'}
				text={`${quotaInMinutes}`}
			/>
		) : null;
};

export default useQuotaIndicator;
