/* eslint-disable import/no-mutable-exports */
import { EnvsManager } from './utils/enviroments';

export let API_URL = '';
export let OLD_INTERFACE = '';
export let API_URL_WEBSOKET = '';

//if (EnvsManager.isDev) {
//	API_URL = 'https://api.stand.imot.io/';
//	API_URL_WEBSOKET = 'wss://api.stand.imot.io/';
//	OLD_INTERFACE = 'https://imot-stand.pyzzle.ru/';
//} else {
//	API_URL = 'https://api.imot.io/';
//	API_URL_WEBSOKET = 'wss://api.imot.io/';
//	OLD_INTERFACE = 'https://test.imot.io/';
//}

API_URL = 'https://api.demo.imot.ru/';
API_URL_WEBSOKET = 'wss://api.demo.imot.io/';
OLD_INTERFACE = 'https://old.demo.imot.io/';

// API_URL
// стенд - https://api.stand.imot.io/
// прод ❗❗❗БИКАРЕФУЛ❗❗❗ - https://api.imot.io/
//
// OLD_INTERFACE
// стенд - https://imot-stand.pyzzle.ru/
// прод - https://test.imot.io/
