import { EnvsManager } from './enviroments';

export const isDevStand = (hrefArr: string[]) => hrefArr.find(item => item.includes('feature-dev'));

/**
 * Формирует url с учетом окружения
 * при деплое на дев стенд вернет url с названием ветки
 */
export const getUrlForDev = (url: string) => {
	const hrefArr = window.location.href.split('/');
	const branchName = isDevStand(hrefArr);
	const base =  EnvsManager.isDev && branchName ? `/${branchName}` : '';

	return `${base}/${url}`;
};
