export const multiSelectsWithoutDropdown = {
	client_phone: {
		selectAll: false,
	},
	operator_phone: {
		selectAll: false,
	},
	call_time_interval: {
		selectAll: false,
	},
	call_duration: {
		selectAll: false,
	},
	silence_time_percentage: {
		selectAll: false,
	},
	client_talk_time_percentage: {
		selectAll: false,
	},
	operator_talk_time_percentage: {
		selectAll: false,
	},
	silence_duration: {
		selectAll: false,
	},
	client_talk_time: {
		selectAll: false,
	},
	operator_talk_time: {
		selectAll: false,
	},
	call_points: {
		selectAll: false,
	},
	exclude_any_of_tags_with_value: {
		selectAll: false,
	},
	any_of_tags_with_value: {
		selectAll: false,
	},
};
