import React from 'react';

export const SearchSvg = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.59029 10.2973C7.82342 11.7125 5.23712 11.6011 3.59927 9.9632C1.84191 8.20587 1.84191 5.35663 3.59927 3.59927C5.35663 1.84191 8.20589 1.84191 9.96322 3.59927C11.6011 5.23712 11.7125 7.8234 10.2974 9.59027L13.7344 13.0273C13.9297 13.2226 13.9297 13.5392 13.7344 13.7345C13.5392 13.9297 13.2226 13.9297 13.0274 13.7345L9.59029 10.2973ZM4.30638 9.25614C2.93954 7.88927 2.93954 5.67321 4.30638 4.30638C5.67321 2.93954 7.88929 2.93954 9.25616 4.30638C10.622 5.67221 10.623 7.886 9.25916 9.25314C9.25809 9.25414 9.25709 9.25507 9.25609 9.25614C9.25509 9.25714 9.25409 9.25814 9.25309 9.25914C7.88602 10.6229 5.67221 10.6219 4.30638 9.25614Z"
			fill="#738094"
		/>
	</svg>
);
