import React from 'react';

export const RebootSvg = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.36267 5.44226C4.21919 5.40441 4.09994 5.30478 4.03716 5.17032C3.97439 5.03586 3.97458 4.88047 4.03769 4.74617L5.14486 2.38991C5.22736 2.21431 5.404 2.1023 5.59801 2.10254C5.79202 2.10278 5.96837 2.21523 6.05044 2.39103L6.53886 3.43718C6.55632 3.43032 6.57436 3.42437 6.59291 3.41939C9.49111 2.64282 12.4702 4.36275 13.2467 7.26097C14.0233 10.1592 12.3034 13.1382 9.40518 13.9148C6.50692 14.6914 3.5279 12.9714 2.75132 10.0732C2.50436 9.15157 2.37364 8.83264 2.58992 7.96678C2.65685 7.69891 2.92829 7.53598 3.1962 7.60291C3.46411 7.66978 3.62704 7.94124 3.56011 8.20918C3.38392 8.91451 3.51534 9.06091 3.71724 9.81444C4.35088 12.1792 6.78158 13.5825 9.14631 12.9489C11.5111 12.3152 12.9144 9.88457 12.2808 7.51984C11.6575 5.19371 9.29551 3.79783 6.96778 4.35587L7.46058 5.4114C7.54264 5.58719 7.51558 5.7946 7.39124 5.94349C7.26684 6.09237 7.06758 6.15587 6.87998 6.10638L4.36267 5.44226Z"
			fill="#738094"
		/>
	</svg>
);

export const BackSvg = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.352 9.39611C13.2972 9.44451 13.2641 9.51298 13.2605 9.58605C13.2281 10.2611 13.1727 10.9354 13.0941 11.6076C12.9913 12.486 12.281 13.1858 11.3942 13.2848C9.157 13.5349 6.8426 13.5349 4.60538 13.2848C3.71864 13.1858 3.00831 12.486 2.90557 11.6076C2.63824 9.32185 2.63824 7.01285 2.90557 4.72713C3.00831 3.84869 3.71864 3.14894 4.60538 3.04983C6.0804 2.88498 7.59073 2.82889 9.08879 2.88142C9.19693 2.88521 9.28899 2.80291 9.29659 2.69493L9.32933 2.22625C9.33133 2.19865 9.33406 2.17135 9.33746 2.14436C9.35373 2.01477 9.26386 1.88698 9.13333 1.88236C7.58293 1.82743 6.02163 1.88533 4.49431 2.05603C3.15293 2.20594 2.07005 3.26261 1.91235 4.61097C1.63599 6.97385 1.63599 9.36085 1.91235 11.7237C2.07005 13.072 3.15293 14.1288 4.49431 14.2786C6.80533 14.537 9.19426 14.537 11.5053 14.2786C12.8467 14.1288 13.9295 13.072 14.0873 11.7237C14.1999 10.7605 14.2667 9.79331 14.2875 8.82531C14.2901 8.70385 14.1411 8.64331 14.0557 8.72985C13.829 8.96005 13.5943 9.18225 13.352 9.39611Z"
			fill="#A3AEBE"
		/>
		<path
			d="M5.2448 10.3666C5.09732 10.326 4.99642 10.1902 5.0001 10.0373L5.03959 8.39467C5.09614 6.04271 7.01899 4.16551 9.37166 4.16551H10.2099C10.2275 3.74969 10.2514 3.33407 10.2817 2.9188L10.3269 2.29837C10.3545 1.91975 10.7767 1.70809 11.0965 1.9125C12.4772 2.79469 13.6775 3.93142 14.6333 5.26209L14.9374 5.68539C15.0209 5.8016 15.0209 5.95812 14.9374 6.07433L14.6333 6.49763C13.6775 7.82827 12.4772 8.96501 11.0965 9.84721C10.8331 10.0155 10.4993 9.90174 10.3747 9.64487C10.3485 9.59027 10.3317 9.52734 10.3269 9.46134L10.2817 8.84094C10.2455 8.34567 10.2185 7.84994 10.2005 7.35394L9.96446 7.31841C8.46032 7.09201 6.97252 7.81787 6.2252 9.14267L5.62366 10.2091C5.59501 10.2598 5.55435 10.3009 5.50712 10.3297C5.43048 10.3765 5.33598 10.3917 5.2448 10.3666Z"
			fill="#738094"
		/>
	</svg>
);

export const DownloadSvg = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.33398 10.833C3.61012 10.833 3.83398 11.0569 3.83398 11.333V12.6663C3.83398 12.7584 3.9086 12.833 4.00065 12.833H12.0007C12.0927 12.833 12.1673 12.7584 12.1673 12.6663V11.333C12.1673 11.0569 12.3912 10.833 12.6673 10.833C12.9435 10.833 13.1673 11.0569 13.1673 11.333V12.6663C13.1673 13.3107 12.645 13.833 12.0007 13.833H4.00065C3.35632 13.833 2.83398 13.3107 2.83398 12.6663V11.333C2.83398 11.0569 3.05784 10.833 3.33398 10.833Z"
			fill="#A3AEBE"
		/>
		<path
			d="M7.15864 2.5C6.81578 2.5 6.52969 2.76208 6.49974 3.1037C6.39732 4.27217 6.37925 5.44639 6.44563 6.61723C6.28097 6.62633 6.11638 6.63687 5.95186 6.64887L4.95886 6.72127C4.57599 6.74913 4.36196 7.17607 4.56866 7.49953C5.27669 8.6076 6.189 9.57087 7.25698 10.3381L7.65478 10.6239C7.86111 10.7721 8.13904 10.7721 8.34538 10.6239L8.74318 10.3381C9.81118 9.57087 10.7234 8.6076 11.4315 7.49953C11.6382 7.17607 11.4242 6.74913 11.0413 6.72127L10.0483 6.64887C9.88378 6.63687 9.71918 6.62633 9.55451 6.61723C9.62091 5.44639 9.60284 4.27217 9.50044 3.10369C9.47044 2.76208 9.18438 2.5 8.84151 2.5H7.15864Z"
			fill="#738094"
		/>
	</svg>
);

export const HistorySvg = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.57576 4.58857C5.45144 3.70987 6.66152 3.16699 7.99935 3.16699C10.6687 3.16699 12.8327 5.33095 12.8327 8.00033C12.8327 10.6697 10.6687 12.8337 7.99935 12.8337C5.32998 12.8337 3.16602 10.6697 3.16602 8.00033C3.16602 7.72419 2.94216 7.50033 2.66602 7.50033C2.38988 7.50033 2.16602 7.72419 2.16602 8.00033C2.16602 11.222 4.77769 13.8337 7.99935 13.8337C11.221 13.8337 13.8327 11.222 13.8327 8.00033C13.8327 4.77867 11.221 2.16699 7.99935 2.16699C6.38502 2.16699 4.92307 2.82341 3.86744 3.88267C3.85047 3.8997 3.83498 3.91764 3.82098 3.93633L2.9826 3.09796C2.89114 3.0065 2.75504 2.97621 2.63342 3.02023C2.51181 3.06427 2.42664 3.17467 2.41494 3.30348L2.17923 5.89621C2.17028 5.99473 2.20554 6.09213 2.2755 6.16208C2.34544 6.23204 2.44286 6.26731 2.54138 6.25835L5.1341 6.02265C5.26291 6.01093 5.37332 5.92577 5.41734 5.80416C5.46138 5.68254 5.43108 5.54643 5.33962 5.45498L4.52088 4.63623C4.54002 4.62189 4.55838 4.60601 4.57576 4.58857Z"
			fill="#738094"
		/>
		<path
			d="M8.5 4.66699C8.5 4.39085 8.27613 4.16699 8 4.16699C7.72387 4.16699 7.5 4.39085 7.5 4.66699V8.00033C7.5 8.17273 7.5888 8.33293 7.735 8.42433L9.735 9.67433C9.9692 9.82066 10.2777 9.74953 10.424 9.51533C10.5703 9.28113 10.4992 8.97266 10.265 8.82633L8.5 7.72319V4.66699Z"
			fill="#738094"
		/>
	</svg>
);
