import { Redirect } from 'react-router-dom';

import withProps from './withProps';
import withSTTProps from './withSTTProps';
import lazyWithRetry from './lazyWithRetry';

const Calls = lazyWithRetry(() => import('pages/Calls/CallsPage'));
const CallPage = lazyWithRetry(() => import('pages/Call/CallPage'));
const Reports = lazyWithRetry(() => import('pages/Reports/Reports'));
const ReportPage = lazyWithRetry(() => import('pages/Reports/ReportPage/ReportPage'));
const ReportsListPage = lazyWithRetry(() => import('pages/Reports/ReportsListPage/ReportsListPage'));
const Auth = lazyWithRetry(() => import('pages/Auth/Auth'));
const PageNotFound = lazyWithRetry(() => import('pages/PageNotFound/PageNotFound'));
const MarkupRules = lazyWithRetry(() => import('components/MarkupRules/MarkupRules'));
const Users = lazyWithRetry(() => import('pages/Users/Users'));
const CallRoute = lazyWithRetry(() => import('pages/OneCallRoute/OneCallRoute'));
const Quotas = lazyWithRetry(() => import('pages/Settings/Category/Quotas'));
const Settings = lazyWithRetry(() => import('pages/Settings/Settings'));
const AddressBook = lazyWithRetry(() => import('pages/Settings/AddressBook/AddressBook'));
const UserIntegrationsList = lazyWithRetry(
	() => import('pages/Settings/Integrations/components/UserIntegrationsList/UserIntegrationsList'),
);
const IntegrationsList = lazyWithRetry(
	() => import('pages/Settings/Integrations/components/IntegrationsList/IntegrationsList'),
);
const IntegrationsSettings = lazyWithRetry(
	() => import('pages/Settings/Integrations/components/IntegrationsSettings/IntegrationsSettings'),
);
const AccessKeysTab = lazyWithRetry(
	() => import('pages/Settings/Integrations/components/IntegrationsSettings/components/AccessKeysTab/AccessKeysTab'),
);
const Parameters = lazyWithRetry(
	() => import('pages/Settings/Integrations/components/IntegrationsSettings/components/Parameters/Parameters'),
);
const FilterRules = lazyWithRetry(
	() => import('pages/Settings/Integrations/components/IntegrationsSettings/components/FilterRules/FilterRules'),
);
const CallActions = lazyWithRetry(() => import('pages/Settings/Category/CallActions'));
const STT = lazyWithRetry(() => import('pages/Settings/Category/STT/STT'));
const Integrations = lazyWithRetry(() => import('pages/Settings/Integrations/Integrations'));
const UserPersonalInfo = lazyWithRetry(() => import('features/UserPersonalInfo/UserPersonalInfo'));
const Employees = lazyWithRetry(() => import('features/Employees/Employees'));
const EmployeeProfile = lazyWithRetry(() => import('features/EmployeeProfile/EmployeeProfile'));
const EmployeePersonalInfo = lazyWithRetry(() => import('features/EmployeeProfile/EmployeePersonalInfo'));
const EmployeeAccessRights = lazyWithRetry(() => import('features/EmployeeProfile/EmployeeAccessRights'));
const EmployeeReportsLimits = lazyWithRetry(() => import('features/EmployeeProfile/EmployeeReportsLimits'));
const EmployeeCallsLimits = lazyWithRetry(() => import('features/EmployeeProfile/EmployeeCallsLimits'));
const UserReportsLimits = lazyWithRetry(() => import('features/UserPermissions/UserReportsLimits'));
const UserAccessRights = lazyWithRetry(() => import('features/UserPermissions/UserAccessRights'));
const UserCallsLimits = lazyWithRetry(() => import('features/UserPermissions/UserCallsLimits'));
const ManagerUsers = lazyWithRetry(() => import('features/UserPermissions/ManagerUsers'));
const Notifications = lazyWithRetry(() => import('pages/Notifications/Notifications'));
const NotifyAddNewRule = lazyWithRetry(() => import('widgets/NotifyAddNewRule/ui/NotifyAddNewRule'));
const NotifyEditRule = lazyWithRetry(() => import('widgets/NotifyEditRule/ui/NotifyEditRule'));

const basePath = '/:lang/:userId';
const integrationsSettingsPath = 'settings/integrations/connected-integrations/integration-settings';

export const routes = [
	{
		path: `${basePath}/calls`,
		component: Calls,
		exact: true,
	},
	{
		path: `${basePath}/reports`,
		component: ReportsListPage,
		exact: true,
	},
	{
		path: `${basePath}/report/create`,
		component: withProps(Reports),
		exact: true,
	},
	{
		path: `${basePath}/report/:reportId`,
		component: withProps(Reports),
		exact: true,
	},
	{
		path: `${basePath}/report`,
		component: withProps(ReportPage),
		exact: true,
	},
	{
		path: `${basePath}/tags`,
		component: MarkupRules,
		exact: true,
	},
	{
		path: `${basePath}/dictionaries`,
		component: MarkupRules,
		exact: true,
	},
	{
		path: `${basePath}/checklists`,
		component: MarkupRules,
		exact: true,
	},
	{
		path: `${basePath}/users`,
		component: Users,
		exact: true,
	},
	{
		path: `${basePath}/employees`,
		component: () => <Redirect to={`${basePath}/settings/employees`} />,
		exact: true,
	},
	{
		path: `${basePath}/employees/:employeeId`,
		component: withProps(EmployeeProfile),
		routes: [
			{
				path: `${basePath}/employees/:employeeId/profile`,
				component: withProps(EmployeePersonalInfo),
			},
			{
				path: `${basePath}/employees/:employeeId/access-rights`,
				component: withProps(EmployeeAccessRights),
			},
			{
				path: `${basePath}/employees/:employeeId/calls-limits`,
				component: withProps(EmployeeCallsLimits),
			},
			{
				path: `${basePath}/employees/:employeeId/reports`,
				component: withProps(EmployeeReportsLimits),
			},
		],
	},
	{
		path: `${basePath}/notifications`,
		component: withProps(Notifications),
	},
	{
		path: `${basePath}/settings`,
		component: Settings,
		routes: [
			{
				path: `${basePath}/settings/profile`,
				component: withProps(UserPersonalInfo),
			},
			{
				path: `${basePath}/settings/access-rights`,
				component: withProps(UserAccessRights),
			},
			{
				path: `${basePath}/settings/calls-limits`,
				component: withProps(UserCallsLimits),
			},
			{
				path: `${basePath}/settings/reports`,
				component: withProps(UserReportsLimits),
			},
			{
				path: `${basePath}/settings/manager-users`,
				component: withProps(ManagerUsers),
			},
			{
				path: `${basePath}/settings/employees`,
				component: withProps(Employees),
			},
			{
				path: `${basePath}/settings/actions-with-calls`,
				component: withProps(CallActions),
			},
			{
				path: `${basePath}/settings/word-processing`,
				component: withSTTProps(STT),
			},
			{
				path: `${basePath}/settings/quotas`,
				component: Quotas,
			},
			{
				path: `${basePath}/settings/address-book`,
				component: AddressBook,
			},
			{
				path: `${basePath}/settings/integrations`,
				component: Integrations,
				routes: [
					{
						path: `${basePath}/settings/integrations/connected-integrations`,
						component: UserIntegrationsList,
						routes: [
							{
								path: `${basePath}/${integrationsSettingsPath}/:integrationId`,
								component: IntegrationsSettings,
								routes: [
									{
										path: `${basePath}/${integrationsSettingsPath}/:integrationId/access-keys`,
										component: AccessKeysTab,
										exact: true,
									},
									{
										path: `${basePath}/${integrationsSettingsPath}/:integrationId/parameters`,
										component: Parameters,
										exact: true,
									},
									{
										path: `${basePath}/${integrationsSettingsPath}/:integrationId/filter-rules`,
										component: FilterRules,
										exact: true,
									},
								],
							},
						],
					},
					{
						path: `${basePath}/settings/integrations/all-integrations`,
						component: IntegrationsList,
						exact: true,
					},
				],
			},
		],
	},
	{
		path: '/:lang/call',
		component: CallPage,
		exact: true,
	},
	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/lang/_/calls" />,
	},
	{
		path: '/:lang',
		exact: true,
		component: () => <Redirect to="/lang/_/calls" />,
	},
	{
		path: '*',
		component: PageNotFound,
	},
	{
		path: '/:lang/404',
		component: PageNotFound,
	},
];

export const authRoutes = [
	{
		path: '/:lang/auth',
		component: Auth,
	},
	{
		path: '/:lang/call',
		component: CallRoute,
	},
	{
		path: '*',
		component: Auth,
	},
];
