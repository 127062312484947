/* теги и значения  -  'tag_name_value'
     список тегов  -  'tag_name_list'
     конструктор - 'search_items'
		 по звонку - 'call'
*/

/* дефолтные списки в селектах в отчетах (строки и колонки) -  сейчас используется во фронте */
export const rowSelects = ['time', 'tag_name_value', 'operator_phone', 'call', 'client_phone'];

export const columnSelects = ['tag_name_list', 'search_items', 'tag_name_value', 'calls_count'];

/* что не входит */
export const excludeRowGroupings = new Set(['tag', 'client_phone']);

export const excludeColumnGroupings = new Set(['checklist_avg']);

/* высота и ширина селектов в отчете */
export const selectHeight = '36px';
export const selectWidth = '252px';

/* routes */

export const pathCreateReport = 'report/create';

export const pathReports = 'reports';

export const pathReport = 'report';

export const pathReportHash = 'report_hash';

export const pathHideParameters = 'hide_parameters';

export const reportColsLimit = 25;
