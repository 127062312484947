import React from 'react';
import { Typography } from '@mui/material';
import { timeConverter } from 'components/common/Buttons/Time';
import { CallInfoType } from 'store/calls/calls.types';
import styles from './EmployeeBlock.module.scss';

interface IOwnProps {
	classes: Record<string, string>;
	clientPhone: CallInfoType['clientPhone'];
	duration: CallInfoType['duration'];
	hasAccess: boolean | undefined;
}

const EmployeeBlock: React.FC<IOwnProps> = ({ classes, hasAccess, clientPhone, duration }) => (
	<div className={styles.employeeBlock}>
		{hasAccess && (
			<div className={styles.employeePhone}>
				<Typography className={classes.darkText}>{clientPhone}</Typography>
			</div>
		)}
		<div className={styles.employeeDuration}>
			{hasAccess && <Typography className={classes.lightText}>{timeConverter(duration, true)}</Typography>}
		</div>
	</div>
);

export default EmployeeBlock;
