/* eslint-disable */
import React from 'react';

export const EditSvg = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1.9751 10.6493L8.5661 4.05825L11.9421 7.43425L7.0391 12.3372L5.3511 14.0252C5.3501 14.0262 4.9731 14.3753 4.2431 14.5123C3.2041 14.7093 2.4491 14.8512 1.7211 14.9893C1.5141 15.0282 1.3061 14.9613 1.1611 14.8393C1.0391 14.6943 0.972102 14.4862 1.0111 14.2792C1.1491 13.5512 1.2911 12.7962 1.4881 11.7572C1.6251 11.0282 1.9731 10.6523 1.9751 10.6493ZM2.9851 11.5652C2.9891 11.5602 2.9941 11.5553 2.9981 11.5512C3.1491 11.4002 3.3941 11.4002 3.5451 11.5512L4.4491 12.4552C4.6001 12.6062 4.6001 12.8502 4.4491 13.0012L4.3901 13.0602C4.3901 13.0602 4.2281 13.2102 3.9141 13.2692C3.4681 13.3542 3.1441 13.4152 2.8311 13.4742C2.7421 13.4913 2.6521 13.4622 2.5901 13.4102C2.5381 13.3482 2.5091 13.2583 2.5261 13.1692C2.5851 12.8562 2.6461 12.5322 2.7311 12.0862C2.7901 11.7722 2.9401 11.6102 2.9401 11.6102L2.9851 11.5652ZM12.7461 1.29325C12.3561 0.90225 11.7221 0.90225 11.3321 1.29325L10.0001 2.62425L13.3761 6.00025L14.7071 4.66825C15.0981 4.27825 15.0981 3.64425 14.7071 3.25425L12.7461 1.29325Z"
			fill="#A3AEBE"
		/>
	</svg>
);
