import { CallInitialStateType } from './calls.types';
import { CALLS_LIMIT_ON_ON_PAGE } from './const';

const initialState: CallInitialStateType = {
	callsData: null,

	callsLimitOnOnePage: CALLS_LIMIT_ON_ON_PAGE,

	callsInfo: false,
	currentCall: null,
	expandedId: null,

	sort: { sort: 'call_time', sortDesc: false },
	filterHash: undefined,
	searchHash: undefined,

	callTranslated: null,
	isCallLoading: false,
	isCallsLoading: false,
};

export default initialState;
