import { FC, ReactElement } from 'react';
import STTSkeleton from './STTSkeleton';

interface WithSTTSkeletonProps {
	showChildren: boolean;
	children: ReactElement;
}

const WithSTTSkeleton: FC<WithSTTSkeletonProps> = ({
	showChildren,
	children,
}) => showChildren ? children : <STTSkeleton />;

export default WithSTTSkeleton;
