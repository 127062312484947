import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { callsCheckListsAdapter, userCheckListsAdapter } from './adapters';

const select = (state: RootState): RootState['checkLists'] => state.checkLists;

export const selectIsCallsCheckListsLoading = createSelector(select, (checkLists) => checkLists.isCallsCheckListsLoading);

const checkCallsListsSelector = callsCheckListsAdapter.getSelectors<RootState>(
	(state) => select(state).callsCheckLists,
);

export const selectCallsCheckLists = checkCallsListsSelector.selectAll;

export const selectIsUserCheckListsLoading = createSelector(select, (checkLists) => checkLists.isUserCheckListsLoading);

const checkUserListsSelector = userCheckListsAdapter.getSelectors<RootState>((state) => select(state).userCheckLists);

export const selectUserCheckLists = checkUserListsSelector.selectAll;

export const currentUserCheckList = createSelector(select, (checkList) => checkList.currentCheckList);

export const selectIsNewCheckList = createSelector(select, (checkLists) => checkLists.isNewCheckList);
