/**
 * вычисление процентов
 */

export const getPercents = (points: number | null, maxPoints: number | null): number | null => {
  if (points !== null && maxPoints !== null) {
    if (maxPoints === 0) return 100;
    else return Math.round(points / maxPoints * 100);
  }
  return null;
}