import React, { FC, FormHTMLAttributes, ReactElement } from 'react';
import cn from 'classnames';
import FooterButtons, { FooterButtonsProps } from 'components/shared/FooterButtons/FooterButtons';

import s from './styles.modele.scss';

interface FormWithButtonsProps extends FooterButtonsProps {
	formProps: FormHTMLAttributes<HTMLFormElement>;
	children: ReactElement;
	formClassName?: string;
	contentClassName?: string;
	footerClassName?: string;
	loading?: boolean;
}
const FormWithButtons: FC<FormWithButtonsProps> = ({
	formProps,
	formClassName,
	contentClassName,
	footerClassName,
	onCancel,
	onAccept,
	cancelText,
	acceptText,
	children,
	loading = false,
}) => (
	<form {...formProps} className={cn(s.form, formClassName)}>
		<div className={contentClassName}>{children}</div>
		<FooterButtons
			footerClassName={cn(s.footer, footerClassName)}
			onCancel={onCancel}
			onAccept={onAccept}
			cancelText={cancelText}
			acceptText={acceptText}
			loading={loading}
		/>
	</form>
);

export default FormWithButtons;
