import React, { FC, ReactNode, MouseEventHandler } from 'react';
import { Tooltip, IconButton, IconButtonProps } from '@mui/material';
import { translate } from 'localizations';

type ActionIconButtonProps = {
	tooltipText: string;
	language: string;
	onClickHandler: MouseEventHandler<HTMLButtonElement>;
	icon: ReactNode;
	placement?: 'top' | 'bottom' | 'left' | 'right';
	setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
	size?: IconButtonProps['size'];
};

const ActionIconButton: FC<ActionIconButtonProps> = ({
	tooltipText,
	language,
	onClickHandler,
	icon,
	placement = 'top',
	setLoading,
	size,
}) => {
	const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		if (setLoading) setLoading(true);

		if (onClickHandler) await onClickHandler(event);

		if (setLoading) setLoading(false);
	};

	return (
		<Tooltip title={translate(tooltipText, language)} placement={placement}>
			<IconButton size={size} onClick={handleClick}>
				{icon}
			</IconButton>
		</Tooltip>
	);
};

export default ActionIconButton;
