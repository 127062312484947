/* eslint-disable */

export const Bell = (props: any) => (
	<svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M9.03439 2.81258C8.44162 2.80895 7.85398 2.92265 7.30535 3.14713C6.75662 3.37166 6.25775 3.70258 5.8375 4.1208C5.41726 4.53903 5.08395 5.0363 4.85678 5.58394C4.62961 6.13159 4.51309 6.71878 4.51391 7.31167L4.51392 7.31246L4.51391 7.87496C4.51391 10.4725 3.96965 12.0355 3.44631 12.9358L3.44536 12.9375H14.5539L14.553 12.9358C14.0299 12.0356 13.4859 10.4726 13.4859 7.87496V7.37601C13.4859 4.88299 11.4982 2.83111 9.03439 2.81258ZM6.87931 2.10592C7.56544 1.82518 8.30036 1.68301 9.04169 1.68761L9.04238 1.68761C12.1457 1.71068 14.6109 4.28627 14.6109 7.37601V7.87496C14.6109 10.3132 15.1204 11.6727 15.5253 12.37C15.6249 12.5405 15.6779 12.7341 15.6789 12.9315C15.68 13.1293 15.6289 13.3239 15.5307 13.4957C15.4326 13.6674 15.2909 13.8103 15.1199 13.9098C14.9489 14.0093 14.7547 14.0619 14.5569 14.0625L14.5555 14.0625H3.4438L3.44235 14.0625C3.24451 14.0619 3.0503 14.0093 2.87932 13.9097C2.70834 13.8102 2.56662 13.6673 2.46848 13.4956C2.37034 13.3238 2.31924 13.1291 2.32033 12.9313C2.32142 12.7339 2.37444 12.5403 2.47405 12.3698C2.87924 11.6725 3.38891 10.313 3.38891 7.87495L3.38891 7.31285C3.38793 6.57163 3.53364 5.83755 3.81763 5.1529C4.10168 4.46813 4.51845 3.84635 5.04393 3.3234C5.5694 2.80045 6.19318 2.38667 6.87931 2.10592Z"
			fill="#A3AEBE"
		/>
		<path
			d="M6.75 12.9375C7.06066 12.9375 7.3125 13.1893 7.3125 13.5V14.0625C7.3125 14.5101 7.49029 14.9393 7.80676 15.2557C8.12322 15.5722 8.55245 15.75 9 15.75C9.44755 15.75 9.87677 15.5722 10.1932 15.2557C10.5097 14.9393 10.6875 14.5101 10.6875 14.0625V13.5C10.6875 13.1893 10.9393 12.9375 11.25 12.9375C11.5607 12.9375 11.8125 13.1893 11.8125 13.5V14.0625C11.8125 14.8084 11.5162 15.5238 10.9887 16.0512C10.4613 16.5787 9.74592 16.875 9 16.875C8.25408 16.875 7.53871 16.5787 7.01126 16.0512C6.48382 15.5238 6.1875 14.8084 6.1875 14.0625V13.5C6.1875 13.1893 6.43934 12.9375 6.75 12.9375Z"
			fill="#A3AEBE"
		/>
	</svg>
);
