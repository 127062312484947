import React, { useCallback } from 'react';
import { translate } from 'localizations';
import { TCheckListGroup, TCheckListItem } from './namespace';
import Score from '../Score/Score';
import './styles.css';
import CheckList from './components/CheckList/CheckList';
import { getPercents } from '../../../utils/numbers';

interface IOwnProps extends TCheckListGroup {
	points: number;
	onClickCheckListItem(target: TCheckListItem, id: string): void;
	language: string;
	callCheckListDisabled: boolean;
	appraised: boolean;
}

const CheckListGroup: React.FC<IOwnProps> = ({
	label,
	questions,
	points,
	score,
	appraised,
	onClickCheckListItem,
	language,
	callCheckListDisabled,
}) => {
	const update = useCallback(
		(target: TCheckListItem, id: string) => {
			onClickCheckListItem(target, id);
		},
		[onClickCheckListItem],
	);

	return (
		<div className="CheckListGroup">
			<div className="CheckListGroupHeader">
				<h4 className="CheckListGroupLabel">{label}</h4>
				<Score
					points={points}
					score={`${score} ${translate('score_many', language)}`}
					scorePercent={getPercents(points, score)}
					language={language}
				/>
			</div>
			{appraised ? (
				<div className="ChekListAppraised">{translate('checklists_appraised', language)}</div>
			) : (
				<div className="CheckListGroupBody">
					{questions.map(({ title, checkList, selectedItem, id }) => (
						<CheckList
							selectedItem={selectedItem}
							onClickCheckListItem={(target) => {
								update(target, id);
							}}
							key={id}
							title={title}
							checkList={checkList}
							callCheckListDisabled={callCheckListDisabled}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default CheckListGroup;
