/**
 * склонение слов с числами
 *
 * входящие данные:
 *  число - количество
 *  массив значений =>
 *    [
 *     1- слово в единственном значении
 *     2- слово для промежутка от 2 до 4 в разряде единиц
 *     3- множественное значение и 0
 *    ]
 *
 * пример вызова:  declinationOfNumber(2, ['минута', 'минуты', 'минут']) // вернёт — минуты
 * пример для англ. языка:  declinationOfNumber(2, ['minute', 'minutes', 'minutes']) // вернёт — minutes
 */

export function declinationOfNumber(number: number | string, textForms: string[]): string {
  if (typeof number === 'string') number = parseInt(number, 10);
	const number10 = Math.abs(number) % 100;
	const number1 = number10 % 10;

	if (number10 > 10 && number10 < 20) {
		return textForms[2];
	}
	if (number1 > 1 && number1 < 5) {
		return textForms[1];
	}
	if (number1 === 1) {
		return textForms[0];
	}

	return textForms[2];
}
