import { ModeType } from 'store/search/search.types';

export const useParametersForAction = () => {
	const setParameters = (
		action: string,
		filterHash: string | null | undefined,
		languageEngineModel = {},
		mode: ModeType | null = null,
	) => {
		// для звонков из отчетов (передаем call_ids_hash вместо search_filter_hash)
		if (mode === 'REPORTS') {
			return {
				action,
				...languageEngineModel,
				call_ids_hash: filterHash,
				columns:
					action === 'xlsx_export'
						? ['call_date', 'call_time', 'duration', 'client_phone', 'operator_phone', 'tags']
						: [],
			};
		}

		if (filterHash) {
			return {
				action,
				...languageEngineModel,
				search_filter_hash: filterHash,
				columns:
					action === 'xlsx_export'
						? ['call_date', 'call_time', 'duration', 'client_phone', 'operator_phone', 'tags']
						: [],
			};
		}

		return {
			action,
			...languageEngineModel,
		};
	};
	return { setParameters };
};
