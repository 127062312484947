import React, { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { translate } from 'localizations';
import { Hint, ContainedSelectWithLabel } from 'components/common';
import { OptionType } from 'components/common/Selects/MultiValueSelect/multiValuesSelect';
import { userSlice } from 'store';
import { getSTTEngines, getSTTModels } from 'store/user/user.slice';
import { useHintTranslations } from 'hooks';
import { showError } from 'utils/forms';
import Input from 'components/common/Input/NewInput/NewInput';
import { labelValueObj } from 'widgets/PeopleList/types';
import { TextArea } from 'components/shared/Forms/TextArea/TextArea';
import { optionsCreatorVEL } from 'utils/optionsCreator';

// TODO: вынести общие стили для формы отдельно на верхний уровень
import s from 'widgets/PeopleList/components/PeopleList.module.scss'

const useFormFields = (
	formik: any,
	setDisplayErrors: React.Dispatch<React.SetStateAction<string[]>>,
	displayErrors: string[],
	rolesArr: labelValueObj[],
) => {
	const {
		STTEngines: STTEnginesList,
		STTModels: STTModelsList,
		STTLanguages,
	} = useAppSelector((state) => state.user);
	const { language } = useAppSelector(state => state.lang);
	const { allTimezones } = useAppSelector(state => state.user);

	const dispatch = useAppDispatch();

	const fetchSTTEngines = async (sttLanguage: string) => {
		if (sttLanguage) {
			dispatch(getSTTEngines(sttLanguage));
		}
	};

	const fetchSTTModels = async (info: { language: string | null; engine: string }) => {
		if (info.language) {
			dispatch(getSTTModels(info));
		}
	};

	const hints = useHintTranslations();

	interface IFormField {
		type: string,
		component: FC<any>,
		props: any;
	}

	// TODO: убрать захардкоженную логику (handleChange и т.п.)
	// Сейчас здесь данные для pages/Employees/components/AddUserForm.tsx
	const config: Record<string, IFormField> = {
		name: {
			type: 'text_input',
			component: Input,
			props: {
				dataTestid: 'inputName',
				required: true,
				name: 'name',
				title: translate('nameOnly', language),
				placeholder: translate('nameOnly', language),
				value: formik.values.name,
				borderColor: showError('name', formik, displayErrors),
				handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
					formik.handleChange(event)
					setDisplayErrors(displayErrors.filter((error: string) => error !== 'name'));
				},
				labelBrother: (
					<Hint
						className={s.form__input__hint}
						title={hints.name}
					/>
				),
			},
		},
		login: {
			type: 'text_input',
			component: Input,
			props: {
				dataTestid: 'inputLogin',
				required: true,
				name: 'login',
				title: translate('login', language),
				placeholder: translate('login', language),
				value: formik.values.login,
				borderColor: showError('login', formik, displayErrors),
				handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
					formik.handleChange(event)
					setDisplayErrors(displayErrors.filter((error: string) => error !== 'login'));
				},
				labelBrother: (
					<Hint
						className={s.form__input__hint}
						title={hints.login}
					/>
				),
			},
		},
		password: {
			type: 'text_input',
			component: Input,
			props: {
				dataTestid: 'inputPassword',
				required: true,
				isPassword: true,
				name: 'password',
				autoComplete: "new-password",
				title: translate('password', language),
				placeholder: translate('password', language),
				value: formik.values.password,
				borderColor: showError('password', formik, displayErrors),
				handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
					formik.handleChange(event)
					setDisplayErrors(displayErrors.filter((error: string) => error !== 'password'));
				},
				labelBrother: (
					<Hint
						className={s.form__input__hint}
						title={hints.password}
					/>
				),
			},
		},
		email: {
			type: 'text_input',
			component: Input,
			props: {
				dataTestid: 'inputEmail',
				width: '343px',
				name: 'email',
				margin: "0 0 16px 0",
				title: translate('user_email', language),
				placeholder: translate('user_email', language),
				value: formik.values.email,
				handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
					formik.handleChange(event)
				},
				labelBrother: (
					<Hint
						className={s.form__input__hint}
						title={hints.email}
					/>
				),
			},
		},
		role: {
			type: 'select',
			component: ContainedSelectWithLabel,
			props: {
				testId: 'selectRole',
				required: true,
				title: translate('userRole', language),
				placeholder: translate('select_role', language),
				onSelectChange: (event: OptionType): void => {
					setDisplayErrors(displayErrors.filter(error => error !== 'role'));
					if (event?.value) {
						formik.setFieldValue('role', event);
					}
				},
				isSearchable: true,
				options: rolesArr,
				disabled: rolesArr.length === 0,
				value: formik.values.role,
				hasError: !!showError('role', formik, displayErrors),
				labelBrother: (
					<Hint
						className={s.form__input__hint}
						title={hints.role}
					/>
				),
			},
		},
		comment: {
			type: 'textarea',
			component: TextArea,
			props: {
				dataTestid: 'textAreaComment',
				name: "comment",
				classNameRoot: s.form__comment__root,
				className: s.form__comment,
				classNameTitle: s.form__input__label,
				onChange: formik.handleChange,
				value: formik.values.comment,
				hasPlaceholder: true,
				placeholder: translate('leave_comment', language),
				title: translate('commentary', language),
				labelBrother: (
					<Hint
						className={s.form__input__hint}
						title={hints.comment}
					/>
				),
			},
		},
		sttLanguage: {
			type: 'select',
			component: ContainedSelectWithLabel,
			props: {
				testId: 'selectSttLang',
				width: '220.6px',
				marginRight: '10px',
				isSearchable: true,
				title: translate('sttLanguage', language),
				placeholder: translate('sttSetLang', language),
				onSelectChange: (event: OptionType): void => {
					if (event?.value) {
						formik.setFieldValue('sttLanguage', event); // @ts-ignore
						if (formik.values.sttLanguage && event.value !== formik.values.sttLanguage.value) {
							formik.setFieldValue('sttEngine', '');
							dispatch(userSlice.actions.setSTTEngines([]));
							formik.setFieldValue('sttModel', '');
							dispatch(userSlice.actions.setSTTModels([]));
						}
						fetchSTTEngines(event.value);
					}
				},
				options: STTLanguages,
				value: formik.values.sttLanguage,
				labelBrother: (
					<Hint
						className={s.form__input__hint}
						title={hints.sttLanguage}
					/>
				),
			},
		},
		sttEngine: {
			type: 'select',
			component: ContainedSelectWithLabel,
			props: {
				testId: 'selectSttEngine',
				width: '220.6px',
				marginRight: '10px',
				isSearchable: true,
				title: translate('sttEngine', language),
				placeholder: translate('sttSetEngine', language),
				onSelectChange: (event: OptionType): void => {
					if (event?.value) {
						formik.setFieldValue('sttEngine', event); // @ts-ignore
						if (formik.values.sttEngine &&	event.value !== formik.values.sttEngine) {
							formik.setFieldValue('sttModel', '');
							dispatch(userSlice.actions.setSTTModels([]));
						}
						fetchSTTModels({ // @ts-ignore
							language: formik.values.sttLanguage.value,
							engine: event.value,
						});
					}
				},
				options: STTEnginesList,
				value: formik.values.sttEngine,
				labelBrother: (
					<Hint
						className={s.form__input__hint}
						title={hints.sttEngine}
					/>
				),
			},
		},
		sttModel: {
			type: 'select',
			component: ContainedSelectWithLabel,
			props: {
				testId: 'selectSttModel',
				width: '220.6px',
				marginRight: '10px',
				isSearchable: true,
				title: translate('sttModel', language),
				placeholder: translate('sttSetModel', language),
				onSelectChange: (event: OptionType): void => {
					if (event?.value) formik.setFieldValue('sttModel', event);
				},
				options: STTModelsList,
				value: formik.values.sttModel,
				labelBrother: (
					<Hint
						className={s.form__input__hint}
						title={hints.sttModel}
					/>
				),
			},
		},
		quotaRemindTime: {
			type: 'text_input',
			component: Input,
			props: {
				dataTestid: 'inputTime',
				required: true,
				className: s.form__minutes,
				width: '100%',
				name: 'quotaRemindTime',
				margin: "0 0 16px 0",
				title: translate('quotaAddnominal', language),
				placeholder: '',
				value: formik.values.quotaRemindTime,
				borderColor: showError('quotaRemindTime', formik, displayErrors),
				handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
					if (Number(event.target.value) || !event.target.value) {
						formik.handleChange(event);
						setDisplayErrors(displayErrors.filter((error: string) => error !== 'name'));
					}
				},
				labelBrother: (
					<Hint
						className={s.form__input__hint}
						title={hints.time}
					/>
				),
			},
		},
		timezone: {
			type: 'select',
			component: ContainedSelectWithLabel,
			props: {
				testId: 'selectTimezone',
				disabled: !allTimezones.length,
				required: true,
				title: translate('timeZone', language),
				placeholder: translate('timeZone', language),
				onSelectChange: (event: OptionType): void => {
					if (event?.value) {
						formik.setFieldValue('timezone', event);
					}
				},
				options: optionsCreatorVEL(allTimezones),
				value: formik.values.timezone,
				hasError: !!showError('timezone', formik, displayErrors),
			},
		},
		phone: {
			type: 'text_input',
			component: Input,
			props: {
				dataTestid: 'inputPhone',
				width: '343px',
				name: 'phone',
				margin: "0 0 16px 0",
				title: translate('userPhone', language),
				placeholder: translate('userPhone', language),
				value: formik.values.phone,
				handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
					formik.handleChange(event)
				},
				labelBrother: (
					<Hint
						className={s.form__input__hint}
						title={hints.phone}
					/>
				),
			},
		},
	};

	return config;
};

export default useFormFields;
