import { createAsyncThunk } from '@reduxjs/toolkit';
import { newInstance as instance } from '../../api/api.config';
import { checkListsActions } from './index';
import { TCallCheckList, TCallCheckListsResponse, TCheckListItem, TNewCheckListItem, UserCheckListsResponse } from './namespaces/responses';
import { TUpdateCurrentCallCheckListAnswerById, TUpdateCurrentUserCheckList, TUpdateEditedUserCheckList } from './namespaces/payloads';
import { RootState } from '../store';
import { selectCallsCheckLists } from './selectors';
import { getCheckListValues } from './helpers';
import { selectCallById } from '../calls/selectors';
import { callsActions } from '../calls';

export const getUserCheckLists = createAsyncThunk(
	'checkLists/getUserCheckLists',
	async (arg, { dispatch }) => {
		try {
			dispatch(checkListsActions.setUserCheckListsLoading(true));
			const { data } = await instance.get<UserCheckListsResponse>('/checklists/');

			dispatch(checkListsActions.setUserCheckLists(data));
		} catch (e) { /* empty */ } finally {
			dispatch(checkListsActions.setUserCheckListsLoading(false));
		}
	},
);
export const getUserCheckListsById = createAsyncThunk(
	'checkLists/getUserCheckListsById',
	// eslint-disable-next-line consistent-return
	async (checkListId: string, { dispatch }) => {
		try {
			const { data } = await instance.get<TCheckListItem>(`/checklists/${checkListId}`);
			dispatch(checkListsActions.updateUserCheckListById({
				changes: data,
				id: checkListId,
			}));
			return data;
		} catch (e) { /* empty */ }
	},
);
export const getCheckListsByCallId = createAsyncThunk(
	'checkLists/getCheckLists',
	async (callId: string, { dispatch }) => {
		try {
			dispatch(checkListsActions.setCallsCheckListsLoading(true));
			const { data } = await instance.get<TCallCheckListsResponse>(`/checklists/call_checklists/${callId}`);

			dispatch(checkListsActions.setCallsCheckLists(data));
		} catch (e) { /* empty */ } finally {
			dispatch(checkListsActions.setCallsCheckListsLoading(false));
		}
	},
);
export const updateCurrentCallCheckListAnswerById = createAsyncThunk(
	'checkLists/updateCurrentCallCheckListAnswerById',
	async (payload: TUpdateCurrentCallCheckListAnswerById, { dispatch, getState }) => {
		const { checkListId, questionId, answerValue, callId } = payload;
		try {
			await instance.patch<void>(`/checklists/call_checklist/${checkListId}`, {
				question_id: questionId,
				answer_value: answerValue,
			});

			const { data } = await instance.get<TCallCheckList>(`/checklists/call_checklist/${checkListId}`);
			await dispatch(
				checkListsActions.updateCallsCheckListById({
					changes: data,
					id: data.id,
				}),
			);

			const state = getState() as RootState;
			const currentCall = selectCallById(callId)(state);

			if (currentCall) {
				const checkLists = selectCallsCheckLists(state);
				const currentScore = getCheckListValues(checkLists);
				const updatedCurrentCall = {
					...currentCall,
					points: currentScore,
				};
				dispatch(callsActions.updateCall(updatedCurrentCall));
			}
		} catch (e) { /* empty */ }
	},
);
export const updateUserCheckListById = createAsyncThunk(
	'checkLists/updateUserCheckListById',
	async (payload: TUpdateCurrentUserCheckList, { dispatch }) => {
		const { checkListId, checkList } = payload;
		try {
			await instance.patch<void>(`/checklists/${checkListId}`, checkList);
			dispatch(checkListsActions.updateUserCheckListById({
				changes: checkList,
				id: checkListId,
			}));
		} catch (e) {
			console.log(e);
		}
	},
);
// обновление вопросов чек листа
export const updateEditedUserCheckListById = createAsyncThunk(
	'checkLists/updateEditedUserCheckListById',
	// eslint-disable-next-line consistent-return
	async (payload: TUpdateEditedUserCheckList) => {
		const { checkListId, checkList } = payload;
		try {
			const { data } = await instance.patch<void>(`/checklists/${checkListId}`, checkList);
			return data;
		} catch (e) {
			console.log(e);
		}
	},
);
export const deleteUserCheckListById = createAsyncThunk(
	'checkLists/deleteUserCheckListById',
	async (checkListId: string, { dispatch }) => {
		try {
			await instance.delete(`/checklists/${checkListId}`).then(() => {
				dispatch(checkListsActions.deleteUserCheckList(checkListId));
			});
		} catch (e) { /* empty */ }
	},
);
export const createUserCheckList = createAsyncThunk(
	'checkLists/createUserCheckList',
	// eslint-disable-next-line consistent-return
	async (payload: TNewCheckListItem) => {
		try {
			const { data } = await instance.post('/checklists/', payload);
			return data;
		} catch (e) {
			console.log(e);
		}
	},
);
