import React, { useEffect, memo } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import 'rsuite/dist/rsuite.min.css';
import queryString from 'query-string';

import { useAppSelector } from 'hooks/redux';
import { langSlice, userSlice } from 'store';
import {
	getUserFilter, getUserToken, getUsersWithoutChildren, getMeAccessRights, getMe,
} from 'store/user/user.slice';

import { getLang } from 'store/lang/lang.slice';
import { WithAdminBar, WithAuthContent, WithMainLoader } from 'containers';
import { getTokens } from 'utils/tokens';

import { getAllSearchCriterias } from 'store/search/search.slice';


const Routes = memo(() => {
	const dispatch = useDispatch();
	const history = useHistory();
	const pathArray = history.location.pathname.split('/');

	const isAuth = useAppSelector(state => state.user.isAuth);

	const getBaseRequests = (role: string, adminToken: string, token: string) => {
		const baseRequests = [
			dispatch(getMe({ token: adminToken || token })),
			dispatch(getMeAccessRights()),
			dispatch(getAllSearchCriterias()), // Получение всех критериев поиска с названиями
		];
		if (['admin', 'manager'].includes(role)) baseRequests.push(dispatch(getUsersWithoutChildren()));

		return baseRequests;
	}

	// если загрузка, запустить движ
	useEffect(() => {
		const init = async () => {
			if (['ru', 'en'].find(item => item === pathArray[1])) {
				await dispatch(getLang(pathArray[1]));
				dispatch(langSlice.actions.setLang(pathArray[1]));
			}

			const { token, adminToken } = getTokens();
			const userData = await dispatch(getUserFilter({ token: adminToken || token })); // @ts-ignore
			const user = userData.payload;

			if (token && user) {
				dispatch(userSlice.actions.setCurrentUser(user));
				if (!adminToken && ['admin', 'manager'].includes(user.role)) await localStorage.setItem('adminToken', JSON.stringify({ adminToken: token }));
				if (adminToken) {
					if (pathArray[2] && pathArray[2] !== '_') {
						const tokenData = await dispatch(getUserToken(pathArray[2])); // @ts-ignore
						const tokenDataPayload = tokenData.payload;
						if (tokenDataPayload) { // @ts-ignore
							const localToken: string = tokenDataPayload.access_token;
							const childUserData = await dispatch(getUserFilter({ token: localToken })); // @ts-ignore
							const childUser = childUserData.payload;
							if (childUser) {
								dispatch(userSlice.actions.setChildUser(childUser));
							}
						}
					}
				}

				const baseRequests = getBaseRequests(user.role, adminToken, token);
				const promiseData = await Promise.all(baseRequests); // @ts-ignore
				const accessRights = promiseData[1].payload;

				if (accessRights) dispatch(userSlice.actions.setAccessRights(accessRights));

				dispatch(userSlice.actions.setAuth(true));
			} else {
				dispatch(userSlice.actions.setAuth(false));
			}
		};
		if (isAuth === 'loading') {
			init().then();
		}
	}, [isAuth]);


	// если в урле есть authToken, устанавливаю его
	useEffect(() => {
		const authByUrlToken = async () => {
			const urlObj = queryString.parse(history.location.search);
			if (urlObj.authToken) await localStorage.setItem('token', JSON.stringify({ token: urlObj.authToken }));
		};
		authByUrlToken().then();
	}, []);

	return (
		<WithMainLoader>
			<WithAdminBar>
				<WithAuthContent />
			</WithAdminBar>
		</WithMainLoader>
	);
});

export default Routes;
