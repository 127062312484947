import { createTheme } from '@mui/material/styles';
import variables from 'styles/variables.scss';

const theme = createTheme({
	typography: {
		body1: {
			fontFamily: 'Inter, sans-serif',
			fontSize: 14,
		},
		allVariants: {
			fontFamily: 'Inter, sans-serif',
			color: variables.gray_7,
		},
		fontFamily: ['Inter', 'sans-serif'].join(','),
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
	},
	palette: {
		primary: {
			main: variables.purple_5,
			contrastText: variables.gray_1,
		},
		secondary: {
			main: variables.purple_2,
		},
		error: {
			main: variables.red_6,
		},
	},
});

export default theme;
