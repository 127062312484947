import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import TextMobileStepper from './TextMobileStepper';
import '../../style.css';
import { FragmentDictionary } from '../../helpers/fragments';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

interface IOwnProps {
	selectionText: string,
	open: boolean;
	closeDictionaryFunc(): void
	fragmentDictionary: FragmentDictionary
}

const DictionaryStep: React.FC<IOwnProps> = ({ selectionText, closeDictionaryFunc, fragmentDictionary, open }) => {
	const [selectedText, setSelectedText] = useState<string>(selectionText);
	const [choosedDictId, setChoosedDictId] = useState<string>('');

	const handleClose = (): void => {
		closeDictionaryFunc();
	};

	return (
		<div>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
			>
				<TextMobileStepper
					fragmentDictionary={fragmentDictionary}
					closePopupFunc={handleClose}
					selectedText={selectedText}
					changeSelectedText={setSelectedText}
					choosedDictId={choosedDictId}
					setChoosedDictId={setChoosedDictId}
				/>
			</BootstrapDialog>
		</div>
	);
};

export default DictionaryStep;
