/* eslint-disable */
const FileIcon = () => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M15.0223 5.07305L11.2395 1.29023C11.134 1.18477 10.9916 1.125 10.8422 1.125H3.375C3.06387 1.125 2.8125 1.37637 2.8125 1.6875V16.3125C2.8125 16.6236 3.06387 16.875 3.375 16.875H14.625C14.9361 16.875 15.1875 16.6236 15.1875 16.3125V5.47207C15.1875 5.32266 15.1277 5.17852 15.0223 5.07305ZM13.8902 5.73047H10.582V2.42227L13.8902 5.73047ZM13.9219 15.6094H4.07812V2.39062H9.38672V6.1875C9.38672 6.3833 9.4645 6.57109 9.60296 6.70954C9.74141 6.848 9.9292 6.92578 10.125 6.92578H13.9219V15.6094ZM8.85938 10.8633H5.625C5.54766 10.8633 5.48438 10.9266 5.48438 11.0039V11.8477C5.48438 11.925 5.54766 11.9883 5.625 11.9883H8.85938C8.93672 11.9883 9 11.925 9 11.8477V11.0039C9 10.9266 8.93672 10.8633 8.85938 10.8633ZM5.48438 8.61328V9.45703C5.48438 9.53438 5.54766 9.59766 5.625 9.59766H12.375C12.4523 9.59766 12.5156 9.53438 12.5156 9.45703V8.61328C12.5156 8.53594 12.4523 8.47266 12.375 8.47266H5.625C5.54766 8.47266 5.48438 8.53594 5.48438 8.61328Z"
			fill="#2F3747"
		/>
	</svg>
);

export default FileIcon;
