import { makeStyles } from '@mui/styles';

export const useCallSearchStyles = makeStyles(({
	searchTitle: {
		marginBottom: '8px',
		height: '40px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	searchTitleLeft: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	searchTitleTitle: {
		marginRight: '16px !important',
		color: '#2F3747 !important',
		fontWeight: '500 !important',
	},
	searchTitleLeftStick: {
		marginLeft: '16px !important',
		color: '#CDD5DF !important',
		fontSize: '17px !important',
	},
	searchTitleRight: {
		display: 'flex',
		alignItems: 'center',
	},
	searchDeleteAllButton: {
		display: 'flex',
		alignItems: 'center',
		marginRight: '40px',
		cursor: 'pointer',
	},
	searchDeleteAllButtonText: {
		color: '#2F3747 !important',
	},
	questionWrapper: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
	},
	questionTitle: {
		color: '#A3AEBE',
		marginLeft: '8px',
	},
	searchButton: {
		fontSize: '14px !important', // @ts-ignore
		textTransform: 'none !important',
		color: '#22075E !important',
		'&:hover ': {
			textDecoration: 'underline !important',
			backgroundColor: '#e0b8ff !important',
		},
	},
	searchButtonIcon: {
		fill: '#722ED1 !important',
	},

	searchItems: {
		display: 'flex',
		width: '100%',
		flexWrap: 'wrap',
	},
	searchItem: {
		display: 'flex',
		alignItems: 'center',
		margin: '0 22px 16px 0',
		width: '100%',
	},
	searchItemBlock_1: {
		gridRowStart: 1,
		gridRowEnd: 2,
		display: 'flex',
		alignItems: 'center',
	},
	searchItemBlock_2: {
		gridRowStart: 2,
		gridRowEnd: 3,
		display: 'flex',
		alignItems: 'center',
	},
	searchItemBlock_3: {
		gridRowStart: 3,
		gridRowEnd: 4,
		display: 'flex',
		alignItems: 'center',
	},
	searchItemBlock_4: {
		gridRowStart: 4,
		gridRowEnd: 5,
		display: 'flex',
		alignItems: 'center',
	},
	searchItemBlock_noWide: {
		width: '30%',
		display: 'flex',
		alignItems: 'center',
	},
	searchItemBlock_Wide: {
		width: '70%',
		display: 'flex',
		alignItems: 'center',
	},
	searchText: {
		marginRight: '8px !important',
		color: '#2F3747 !important',
		width: '120px !important',
	},
	tagsBox: {
		minWidth: '70px',
		height: '32px',
		backgroundColor: '#F8FAFC',
		border: 'none !important',
		outline: 'none',
	},
	searchSearchButton: {// @ts-ignore
		textTransform: 'none !important',
		borderRadius: '5px !important',
	},
	selectArrow: {
		marginRight: '10px',
	},
	selectArrowOnArrow: {
		marginBottom: '5px',
	},
	btnsFlex: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: '8px',
	},
	btnClose: {
		cursor: 'pointer',
	},
	btnCloseTitle: {
		color: '#9254DE !important',
		fontWeight: 500,
	},
	filtersFlex: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	filterBlockLeft: {
		width: '60%',
	},
	filterBlockRight: {
		width: 'calc(100% - 60% - 48px)',
		paddingLeft: '24px',
	},
}));