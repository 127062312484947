import { getUrlForDev } from 'utils/url';
import { getCallPublicToken } from 'store/calls/calls.slice';
import { useAppDispatch, useAppSelector } from './redux';

// создание ссылки для звонка в новой вкладке с токеном

const useOpenCallWithToken = () => {
	const dispatch = useAppDispatch();
	const { language } = useAppSelector((state) => state.lang);
	const host = window.origin;

	const getNewHrefForCall = async (id: string) => {
		const publicTokenData = await dispatch(getCallPublicToken(id)); // @ts-ignore
		const publicToken: { access_token: string; token_type: string } = publicTokenData.payload;
		const hrefWithEnv = getUrlForDev(`${language}/call?id=${id}&token=${publicToken.access_token}`);
		const newHref = `${host}${hrefWithEnv}`;
		return newHref;
	};

	return { getNewHrefForCall };
};

export default useOpenCallWithToken;
