import { FC } from 'react';
import SwitchUnstyled from '@mui/base/SwitchUnstyled';
import { Tooltip } from '@mui/material';
import Root from './CustomSwitchStyles.jss';

export type CustomSwitchPropsType = {
	disabled?: boolean;
	onChecked?: (e: any) => void;
	checked: boolean;
	dataTestid?: string | number | null;
	switchTooltipText?: string;
	placement?: 'top' | 'bottom' | 'left' | 'right';
};

const CustomSwitch: FC<CustomSwitchPropsType> = ({
	onChecked,
	disabled,
	checked,
	dataTestid,
	switchTooltipText,
	placement,
}) => {
	const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };
	return (
		<Tooltip title={switchTooltipText || ''} placement={placement}>
			<SwitchUnstyled
				disabled={disabled}
				onChange={onChecked}
				component={Root}
				checked={checked}
				{...label}
				data-testid={dataTestid}
			/>
		</Tooltip>
	);
};

export default CustomSwitch;
