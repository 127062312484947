import { FC } from 'react';
import { Typography } from '@mui/material';
import { IconQuestion } from 'components/Icons/IconQuestion';
import Tooltip from 'components/common/Tooltip/Tooltip';
import cls from './styles.module.scss';

interface TitleWithTooltipProps {
	title: string;
	tooltipTitle: string;
	theme: 'gray' | 'black';
	size: 'lg' | 'md' | 'xs';
}

const TitleWithTooltip: FC<TitleWithTooltipProps> = ({ title, tooltipTitle, theme, size }) => (
	<div className={cls.root}>
		<Typography className={`${cls.text} ${cls[theme]} ${cls[size]}`}>{title}</Typography>

		<Tooltip title={tooltipTitle} placement="top">
			<IconQuestion />
		</Tooltip>
	</div>
);

export default TitleWithTooltip;
