const NotifyApiIcon = () => (
	<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="48" height="48" rx="24" fill="#EFDBFF" />
		<g opacity="0.8">
			<path
				d="M10 25.6248V23.7455L18.8467 19.6215V21.938L12.5609 24.6591L12.632 24.5351V24.8352L12.5609 24.7113L18.8467 27.4323V29.7488L10 25.6248Z"
				fill="#9254DE"
			/>
			<path d="M27.1615 16L22.8934 32H20.8434L25.1115 16H27.1615Z" fill="#9254DE" />
			<path
				d="M38 25.6248L29.1533 29.7488V27.4323L35.4391 24.7113L35.368 24.8352V24.5351L35.4391 24.6591L29.1533 21.938V19.6215L38 23.7455V25.6248Z"
				fill="#9254DE"
			/>
		</g>
	</svg>
);

export default NotifyApiIcon;
