/* eslint-disable */
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';
import { instance } from 'api/api.config';
import { unitsOfTime } from 'utils/unitsOfTime';
import { criteriaWithComplexValues } from 'configs/searchCriteria';
import { CriteriasType, ModeType, SP, SPWithDate, TemplateType } from './search.types';
import { DefaultCriteriasKeysResponse, SearchFilterHashResponse } from './namespaces/responses';
import { getCriteriaWithComplexValues, removeFromComplexCriteria } from './utils';
import { getPayloadForTemplate } from '../../utils/convertDataForSearchFilters/convertData';

/**
 * @deprecated
 */
export const getAllTemplates = createAsyncThunk('template/getAllTemplates', async (payload, thunkAPI) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const response = await instance.get('search_filters/', { headers: { Authorization: `Bearer ${token}` } });
		thunkAPI.dispatch(searchSlice.actions.setAllTemplates(response.data));
	} catch (e: any) {
		return thunkAPI.rejectWithValue({ error: e.message });
	}
});

export const getTemplate = createAsyncThunk('template/getTemplate', async (payload: string, thunkAPI) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const response = await instance.get(`search_filter/${payload}`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		thunkAPI.dispatch(searchSlice.actions.setCurrentTemplate(response.data));
	} catch (e: any) {
		return thunkAPI.rejectWithValue({ error: e.message });
	}
});

export const createTemplate = createAsyncThunk(
	'template/createTemplate',
	async (payload: { title: string; items: any[] }, thunkAPI) => {
		try {
			const requestBody = getPayloadForTemplate(payload);
			const { token } = JSON.parse(localStorage.getItem('token') || '{}');
			const { data } = await instance.post('search_filter', requestBody, {
				headers: { Authorization: `Bearer ${token}` },
			});
			await thunkAPI.dispatch(getTemplate(data));
			thunkAPI.dispatch(getAllTemplates());
			return data;
		} catch (e: any) {
			return thunkAPI.rejectWithValue({ error: e.message });
		}
	},
);

export const updateTemplate = createAsyncThunk('template/updateTemplate', async (payload: TemplateType, thunkAPI) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.put(`search_filter/${payload.id}`, payload, {
			headers: { Authorization: `Bearer ${token}` },
		});
		await thunkAPI.dispatch(getTemplate(data));
		thunkAPI.dispatch(getAllTemplates());
		return data;
	} catch (e: any) {
		return thunkAPI.rejectWithValue({ error: e.message });
	}
});

export const deleteTemplate = createAsyncThunk('template/updateTemplate', async (payload: string, thunkAPI) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const response = await instance.delete(`search_filter/${payload}`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		thunkAPI.dispatch(getAllTemplates());
		return response.data.title;
	} catch (e: any) {
		return thunkAPI.rejectWithValue({ error: e.message });
	}
});

/**
 * @deprecated
 */
export const getAllSearchCriterias = createAsyncThunk('search/getAllSearchCriterias', async (payload, thunkAPI) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.get('search_criterias/', { headers: { Authorization: `Bearer ${token}` } });
		thunkAPI.dispatch(searchSlice.actions.setAllCriterias(data));
		return data;
	} catch (e: any) {
		return thunkAPI.rejectWithValue({ error: e.message });
	}
});

/**
 * @deprecated
 */
export const getDefaultCriterias = createAsyncThunk('search/getBaseSearchCriterias', async (_, thunkAPI) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.get('search_criterias/default_keys', {
			headers: { Authorization: `Bearer ${token}` },
		});
		return data;
	} catch (e: any) {
		return thunkAPI.rejectWithValue({ error: e.message });
	}
});

export const getSearchHash = createAsyncThunk('search/getSearchHash', async (hashKey: string, thunkAPI) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.get<SearchFilterHashResponse>(`/search_filter_hash/${hashKey}`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		return data;
	} catch (e: any) {
		return thunkAPI.rejectWithValue({ error: e.message });
	}
});

type InitialStateType = {
	calls: {
		mode: ModeType;
		// TODO: Igor - Несовпадает тип
		date: (Moment | null)[];
		activeCriterias: CriteriasType[];
		allTemplates: TemplateType[];
		currentTemplate: TemplateType | null;
	};
	reports: {
		date: (Moment | null)[];
		activeCriterias: CriteriasType[];
		activeCriteriasColumn: CriteriasType[];
		search_filter_hash?: string | null;
	};
	checklists: {
		activeCriterias: CriteriasType[];
	};
	allCriterias: CriteriasType[];
	allCriteriasLoading: boolean;
	defaultCriteriasKeys: DefaultCriteriasKeysResponse;
};

const initialState: InitialStateType = {
	calls: {
		mode: 'LOADING',
		date: unitsOfTime.today,
		activeCriterias: [],
		allTemplates: [],
		currentTemplate: null,
	},
	reports: {
		date: unitsOfTime.today,
		activeCriterias: [],
		activeCriteriasColumn: [],
		search_filter_hash: null,
	},
	checklists: {
		activeCriterias: [],
	},
	allCriterias: [],
	allCriteriasLoading: true,
	defaultCriteriasKeys: [],
};

export const searchSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		// общее
		setAllCriterias(state, action: PayloadAction<CriteriasType[]>) {
			state.allCriterias = action.payload;
		},

		setDate(state, action: PayloadAction<SPWithDate<(Moment | null)[]>>) {
			state[action.payload.page].date = action.payload.data;
		},

		setActiveCriteria(state, action: PayloadAction<SP<CriteriasType>>) {
			state[action.payload.page].activeCriterias.push(action.payload.data);
		},

		setActiveCriterias(state, action: PayloadAction<SP<CriteriasType[]>>) {
			state[action.payload.page].activeCriterias = action.payload.data;
		},

		setActiveCriteriaValues(state, action: PayloadAction<SP<CriteriasType>>) {
			const { page, data } = action.payload;
			if (criteriaWithComplexValues.includes(data.key)) {
				// TODO: reports_block - костыль для решения DEV-698
				// добавление логических условий поиска для столбцов отчета
				// @ts-ignore
				if (page === 'reports_block') {
					state.reports.activeCriteriasColumn = getCriteriaWithComplexValues(
						data.key,
						current(state.reports.activeCriteriasColumn),
						data,
					);
				} else {
					state[page].activeCriterias = getCriteriaWithComplexValues(
						data.key,
						current(state[page].activeCriterias),
						data,
					);
				}
			} else {
				const criteriaIndex = state[page].activeCriterias.findIndex((item) => item.key === data.key);
				state[page].activeCriterias[criteriaIndex].values = data.values;
			}
		},

		removeActiveCriteria(state, action: PayloadAction<SP<CriteriasType>>) {
			state[action.payload.page].activeCriterias = state[action.payload.page].activeCriterias.filter(
				(item) => item.key !== action.payload.data.key,
			);
		},

		removeComplexCriteria(state, action: PayloadAction<SP<CriteriasType>>) {
			const { data, page } = action.payload;
			// TODO: reports_block - костыль для решения DEV-698
			// удаление логических условий поиска для столбцов отчета
			// @ts-ignore
			if (page === 'reports_block') {
				state.reports.activeCriteriasColumn = removeFromComplexCriteria(
					current(state.reports.activeCriteriasColumn),
					data.conditionItemId,
					data.key,
				);
			} else {
				state[page].activeCriterias = removeFromComplexCriteria(
					current(state[page].activeCriterias),
					data.conditionItemId,
					data.key,
				);
			}
		},

		removeAllActiveCriterias(state, action: PayloadAction<SP<null>>) {
			state[action.payload.page].activeCriterias = [];
		},

		// только для звонков
		setMode(state, action: PayloadAction<ModeType>) {
			state.calls.mode = action.payload;
		},

		setAllTemplates(state, action: PayloadAction<TemplateType[]>) {
			state.calls.allTemplates = action.payload;
		},

		setCurrentTemplate(state, action: PayloadAction<TemplateType | null>) {
			state.calls.currentTemplate = action.payload;
		},

		// только для отчетов
		setActiveCriteriaReportsColumn(state, action: PayloadAction<CriteriasType>) {
			state.reports.activeCriteriasColumn.push(action.payload);
		},

		setActiveCriteriasReportsColumn(state, action: PayloadAction<CriteriasType[]>) {
			state.reports.activeCriteriasColumn = action.payload;
		},

		setActiveCriteriaReportsColumnValues(state, action: PayloadAction<CriteriasType>) {
			const { key } = action.payload;
			const criteriaIndex = current(state.reports.activeCriteriasColumn).findIndex((item) => item.key === key);
			state.reports.activeCriteriasColumn[criteriaIndex].values = action.payload.values;
		},

		setComplexCriteriaReportsColumn(state, action: PayloadAction<CriteriasType>) {
			state.reports.activeCriteriasColumn = getCriteriaWithComplexValues(
				action.payload.key,
				current(state.reports.activeCriteriasColumn),
				action.payload,
			);
		},

		removeActiveCriteriaColumnReports(state, action: PayloadAction<CriteriasType>) {
			state.reports.activeCriteriasColumn = state.reports.activeCriteriasColumn.filter(
				(item) => item.key !== action.payload.key,
			);
		},

		removeAllActiveCriteriasReports(state) {
			state.reports.activeCriteriasColumn = [];
		},

		setDefaultCriteriasKeys(state, action: PayloadAction<DefaultCriteriasKeysResponse>) {
			state.defaultCriteriasKeys = action.payload;
		},

		setReportSearchFilterHash(state, action: PayloadAction<string>) {
			state.reports.search_filter_hash = action.payload;
		},

		searchReset: () => initialState,
	},
	extraReducers: {
		[getAllSearchCriterias.pending.type]: (state) => {
			state.allCriteriasLoading = true;
		},
		[getAllSearchCriterias.fulfilled.type]: (state) => {
			state.allCriteriasLoading = false;
		},
		[getAllSearchCriterias.rejected.type]: (state) => {
			state.allCriteriasLoading = false;
		},
	},
});
