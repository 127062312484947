import { LoadingButton } from '@mui/lab';
import { Ellipsis } from 'components/Icons/Ellipsis';
import { LabelSelect, IconButton, ModalWindow } from 'components/common';
import { OptionType } from 'components/common/Selects/LabelSelect/LabelSelect';
import { translate } from 'localizations';
import { useAppSelector } from 'hooks/redux';
import STT from 'pages/Settings/Category/STT/STT';
import { Values } from 'pages/Settings/Category/STT/namespaces';
import { FC, useState } from 'react';
import { LangType } from 'store/lang/lang.slice';
import { AccessRights, UserType } from 'store/user/user.types';
import useCallsActions from 'hooks/useCallsActions';
import { useToggleOpen } from 'hooks';
import { useHistory, useParams } from 'react-router-dom';
import { RouteParams } from 'pages/Settings/types';
import style from './ActionsSelect.module.scss';

interface ActionsSelectProps {
	accessRights: AccessRights | null;
	language: LangType | string;
	pageCount: number | null;
	onIconClick: (action: string) => void;
	onIconClickSTT: (values: Values) => void;
	userInfo: UserType | null;
	isSingleCall?: boolean;
	callsCount?: number;
	callId?: string;
}

const ActionsSelect: FC<ActionsSelectProps> = ({
	accessRights,
	language,
	pageCount,
	onIconClick,
	onIconClickSTT,
	userInfo,
	isSingleCall,
	callsCount,
	callId,
}) => {
	const history = useHistory();
	const { lang, userId } = useParams<RouteParams>();

	const { STTLanguages, STTEngines, STTModels } = useAppSelector((state) => state.user);

	const { userActionsArray } = useCallsActions(accessRights, isSingleCall);

	const [downloadingLoading, setDownloadingLoading] = useState<boolean>(false);

	// stt modal
	const { toggle: toggleModalSTT, isShowing: isOpenModalSTT } = useToggleOpen();
	// delete modal
	const { toggle: toggleModalDelete, isShowing: isOpenModalDelete } = useToggleOpen();

	const textForSTT = isSingleCall
		? translate('setLangEngineModel', language)
		: translate('areSureSttCalls', language);
	const textForDelete = isSingleCall
		? translate('areSureDeleteCall', language)
		: `${translate('areSureDeleteCalls', language)} ${translate('allFoundCalls', language)} ${callsCount})`;

	return (
		<div>
			{userActionsArray.length && (
				<>
					<LabelSelect
						notPlusMinus
						isSingle
						isDisable={downloadingLoading || pageCount === 0}
						closeMenuSelect
						valueHandler={async (value: OptionType) => {
							const valueResult = value.value;
							setDownloadingLoading(true);

							switch (valueResult) {
								case 'stt':
									toggleModalSTT();
									break;
								case 'delete':
									toggleModalDelete();
									break;
								case 'editNotificationRule':
									history.push(`/${lang}/${userId}/notifications/create-rule-from-call/${callId}`);
									break;
								default:
									onIconClick(valueResult);
							}

							setDownloadingLoading(false);
						}}
						value={[]}
						options={userActionsArray}
						customLabel={
							<IconButton
								mainLoading={downloadingLoading}
								disabled={downloadingLoading || pageCount === 0}
								backgroundColor="#F8FAFC"
								icon={<Ellipsis />}
								margin={isSingleCall ? '0 0 0 8px' : '0 0 0 16px'}
							/>
						}
					/>
					<ModalWindow
						isMWOpen={isOpenModalSTT}
						handleMWClose={toggleModalSTT}
						text={textForSTT}
						width="600px"
						height="350px"
					>
						<div>
							{!isSingleCall && (
								<>
									<div className={style.sttAllFoudCalls}>{`${translate(
										'allFoundCalls',
										language,
									)} ${callsCount})`}</div>
									<div className={style.sttTitle}>{translate('setLangEngineModel', language)} </div>
								</>
							)}
							<STT
								language={language}
								user={userInfo}
								onSubmitFunction={(values) => {
									toggleModalSTT();
									onIconClickSTT(values);
								}}
								STTLanguagesList={STTLanguages}
								STTEnginesList={STTEngines}
								STTModelsList={STTModels}
							/>
						</div>
					</ModalWindow>

					<ModalWindow isMWOpen={isOpenModalDelete} handleMWClose={toggleModalDelete} text={textForDelete}>
						<div className={style.modalBtns}>
							<LoadingButton variant="outlined" color="primary" onClick={toggleModalDelete}>
								{translate('cancelButton', language)}
							</LoadingButton>
							<LoadingButton
								variant="contained"
								color="primary"
								onClick={() => {
									onIconClick('delete');
									toggleModalDelete();
								}}
							>
								{translate('deleteButton', language)}
							</LoadingButton>
						</div>
					</ModalWindow>
				</>
			)}
		</div>
	);
};

export default ActionsSelect;
