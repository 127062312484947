import React from 'react';
import styles from './styles.module.scss';
import ScorePercent from './components/ScorePercent/ScorePercent';

interface IOwnProps {
	scorePercent: number | null;
	score: string | number;
}

const CallScore: React.FC<IOwnProps> = ({ scorePercent, score }) => {
	let condition;
	if (scorePercent !== undefined && scorePercent !== null) {
		condition = scorePercent > 30 ? (scorePercent < 70 ? 'yellow' : 'green') : 'red';
	}

	return (
		<div className={styles.callScoreWrapper}>
			{condition && <ScorePercent condition={condition} scorePercent={scorePercent} />}
			<div className={styles.callScore}>{score}</div>
		</div>
	);
};

export default CallScore;
