const NotifyEmailIcon = () => (
	<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="48" height="48" rx="24" fill="#EFDBFF" />
		<g clipPath="url(#clip0_3640_105249)">
			<mask id="mask0_3640_105249" maskUnits="userSpaceOnUse" x="11" y="16" width="25" height="15">
				<path d="M36 16H11V31H36V16Z" fill="white" />
			</mask>
			<g mask="url(#mask0_3640_105249)">
				<path
					d="M22.7096 25.2802L11.536 16.777C11.4611 16.7213 11.4064 16.6462 11.3791 16.5617C11.3517 16.4772 11.3529 16.3871 11.3825 16.3033C11.4121 16.2194 11.4688 16.1456 11.5451 16.0915C11.6214 16.0374 11.7138 16.0055 11.8103 16H35.1912C35.2876 16.0056 35.3799 16.0376 35.4562 16.0917C35.5324 16.1458 35.589 16.2196 35.6186 16.3034C35.6482 16.3872 35.6494 16.4772 35.6221 16.5617C35.5948 16.6462 35.5403 16.7212 35.4656 16.777L24.2911 25.2802C24.0699 25.4504 23.7898 25.5437 23.5003 25.5437C23.2109 25.5437 22.9308 25.4504 22.7096 25.2802Z"
					fill="#9254DE"
				/>
				<path
					d="M18.6958 24.1034L11.7879 29.5293C11.7186 29.5835 11.6336 29.6184 11.5432 29.6299C11.4527 29.6413 11.3605 29.6289 11.2776 29.5941C11.1946 29.5592 11.1244 29.5034 11.0752 29.4333C11.026 29.3632 10.9999 29.2817 11 29.1984V18.5167C11.0002 18.434 11.0262 18.353 11.0751 18.2834C11.1239 18.2137 11.1936 18.1581 11.2758 18.1232C11.3581 18.0883 11.4495 18.0755 11.5395 18.0862C11.6294 18.097 11.7142 18.1309 11.7837 18.1839L18.6916 23.441C18.7447 23.4815 18.7875 23.532 18.8171 23.5891C18.8466 23.6462 18.8622 23.7085 18.8627 23.7716C18.8632 23.8347 18.8486 23.8971 18.82 23.9546C18.7914 24.0121 18.7494 24.0632 18.6969 24.1044"
					fill="#9254DE"
				/>
				<path
					d="M35.3446 30.892C35.3222 30.9475 35.2424 30.9851 35.1276 30.9995H12.0253C11.9104 30.9851 11.8307 30.9483 11.8082 30.892C11.7826 30.8283 11.8239 30.7497 11.9205 30.6598C11.9486 30.6342 11.9873 30.6043 12.0286 30.5723L19.4998 24.7046C19.6172 24.6129 19.7665 24.5619 19.9212 24.5608C20.0758 24.5597 20.226 24.6084 20.345 24.6984L22.0543 25.9981C22.4602 26.3078 22.9727 26.4775 23.502 26.4775C24.0313 26.4775 24.5438 26.3078 24.9497 25.9981L26.7353 24.6416C26.8543 24.5518 27.0043 24.5031 27.1588 24.5041C27.3133 24.5051 27.4625 24.5558 27.58 24.6473L35.1247 30.5723C35.166 30.6046 35.2045 30.6332 35.2329 30.6598C35.3289 30.7497 35.3702 30.8283 35.3452 30.892"
					fill="#9254DE"
				/>
				<path
					d="M35.9995 18.5172V29.0799C35.9995 29.1631 35.9732 29.2446 35.9239 29.3146C35.8745 29.3847 35.8042 29.4403 35.7212 29.475C35.6382 29.5097 35.546 29.522 35.4556 29.5104C35.3652 29.4988 35.2803 29.4638 35.211 29.4095L28.381 24.0458C28.3284 24.0046 28.2863 23.9534 28.2576 23.8959C28.2289 23.8384 28.2143 23.7759 28.2149 23.7127C28.2154 23.6495 28.231 23.5872 28.2606 23.53C28.2902 23.4729 28.3331 23.4223 28.3863 23.3819L35.2164 18.1829C35.2861 18.1299 35.3709 18.0961 35.4609 18.0855C35.5509 18.0749 35.6424 18.0879 35.7246 18.123C35.8068 18.1581 35.8763 18.2138 35.925 18.2836C35.9737 18.3534 35.9996 18.4344 35.9995 18.5172Z"
					fill="#9254DE"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_3640_105249">
				<rect width="25" height="15" fill="white" transform="translate(11 16)" />
			</clipPath>
		</defs>
	</svg>
);

export default NotifyEmailIcon;
