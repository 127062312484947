import { useEffect, useRef, useState } from 'react';

export const useDialogItemsSelector = (ref: any, open: boolean): [() => void] => {
	const anchorNode = useRef<Node | null | undefined>(null);
	const focusNode = useRef<Node | null | undefined>(null);
	const [anchorOffset, setAnchorOffset] = useState<number | undefined>();
	const [focusOffset, setFocusOffset] = useState<number | undefined>();

	// TODO: пофиксить, ломает логику клика на текст звонка (перемотка аудио по клику на слово, селект с переводом)

	// useEffect(() => {
	// 	if (anchorNode.current && focusNode.current && typeof anchorOffset === 'number' && typeof focusOffset === 'number') {
	// 		window.getSelection()?.setBaseAndExtent(anchorNode.current, anchorOffset, focusNode.current, focusOffset);
	// 	}
	// });

	const selector = (): void => {
		const test = window.getSelection();
		anchorNode.current = test?.anchorNode;
		focusNode.current = test?.focusNode;
		setAnchorOffset(test?.anchorOffset);
		setFocusOffset(test?.focusOffset);
	};

	// TODO: пофиксить, ломает логику клика на текст звонка (перемотка аудио по клику на слово, селект с переводом)

	// useEffect(() => {
	// 	if (!ref.current) { return; }

	// 	ref.current.addEventListener('click', (event: any) => {
	// 		if (event.currentTarget === ref.current) {
	// 			event.stopPropagation();
	// 		}
	// 	});

	// 	window.addEventListener('click', () => {
	// 		if (open) { return; }

	// 		window.getSelection()?.removeAllRanges();
	// 	});
	// });

	return [selector];
};
