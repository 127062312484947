/* eslint-disable */
import { useEffect, useState } from 'react';
import { translate } from 'localizations';
import { UserType } from 'store/user/user.types';
import { labelValueObj, objProp } from 'widgets/PeopleList/types';
import { useAppSelector } from './redux';

const useRolesTranslate = (): objProp => {
	const { language } = useAppSelector((state) => state.lang);
	return {
		admin: `${translate('admin', language)}`,
		manager: `${translate('integrator', language)}`,
		user: `${translate('companyRole', language)}`,
		operator: `${translate('employee', language)}`,
	};
};

const useRoles = () => {
	const { user } = useAppSelector((state) => state.user);

	const rolesTranslate = useRolesTranslate();

	const [rolesArr, setRolesArr] = useState<labelValueObj[]>([]);

	const getRolesForForm = (currentUser: UserType | null) => {
		if (currentUser && currentUser.role === 'manager') {
			return ['user', 'operator'];
		}
		if (currentUser && currentUser.role === 'user') {
			return ['operator'];
		}
		if (currentUser && currentUser.role === 'operator') {
			return [];
		}
		return ['user', 'operator', 'manager', 'admin'];
	};

	const getObjectForRoles = (roles: string[]): labelValueObj[] => {
		const rolesByUser: objProp = {};
		const rolesWithTranslate: labelValueObj[] = [];
		for (const roleItem of roles) {
			rolesByUser[roleItem] = rolesTranslate[roleItem];
		}
		for (const roleByUser in rolesByUser) {
			// Оператора решили убрать из создания пользователя
			if (roleByUser !== 'operator') {
				rolesWithTranslate.push({ value: roleByUser, label: rolesByUser[roleByUser] });
			}
		}
		return rolesWithTranslate;
	};

	const handleUserRole = () => {
		const rolesByStatus = getRolesForForm(user);
		return getObjectForRoles(rolesByStatus as string[]);
	};

	useEffect(() => {
		if (user) {
			const roles: labelValueObj[] = handleUserRole();
			setRolesArr(roles);
		}
	}, [user]);

	return rolesArr;
};

export { useRoles, useRolesTranslate };
