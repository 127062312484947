import React, { FC } from 'react';

interface ReusableTagProps {
	title: string;
	backgroundColor: string;
	textColor: string;
	borderColor: string;
	additionalStyles?: React.CSSProperties;
	onClick?: () => void;
}

const TagComponent: FC<ReusableTagProps> = ({
	title,
	backgroundColor,
	textColor,
	borderColor,
	additionalStyles,
	onClick,
}) => (
	<span
		onClick={onClick}
		style={{
			backgroundColor,
			color: textColor,
			border: `1px solid ${borderColor}`,
			padding: '1px 8px',
			margin: '0 4px 4px 0',
			borderRadius: '5px',
			cursor: 'pointer',
			...additionalStyles,
		}}
	>
		{title}
	</span>
);

export default TagComponent;
