import { useAppSelector } from './redux';
import { RootState } from '../store/store';

const useAccessToLimits = () => {
	const { loggedUser, childUser, user } = useAppSelector((state: RootState) => state.user);

	return () => {
		if (loggedUser?.role === 'admin' && childUser?.role === 'operator') {
			return true;
		}
		// TODO: сейчас пользователь, в которого провалился менеджер записывается в user,
		//  а не в childUser. Это связано с проверкой на adminToken в src/Routes стр. 43
		if (loggedUser?.role === 'manager' && user?.role === 'operator') {
			return true;
		}
		return false;
	};
};

export default useAccessToLimits;
