import { Route, Switch } from 'react-router-dom';
import React, { Suspense } from 'react';
import { CircularPreloader } from 'components/common';

import s from 'styles/general.module.scss';

const renderRoutes = (routes: any) => {
	if (!routes) return null;
	return (
		<Switch>
			{routes.map((route: any, i: number) => {
				const key = route.key || i;
				return (
					<Route
						key={key}
						path={route.path}
						exact={route.exact}
						strict={route.strict}
						render={(props) => {
							if (route.routes) {
								return (
									<Suspense fallback={<CircularPreloader className={s.loader} />}>
										<route.component {...props}>{renderRoutes(route.routes)}</route.component>
									</Suspense>
								);
							}
							if (route.component) {
								return (
									<Suspense fallback={<CircularPreloader  className={s.loader} />}>
										<route.component {...props} />
									</Suspense>
								);
							}
							if (route.render) {
								return route.render(props);
							}
							return null;
						}}
					/>
				);
			})}
		</Switch>
	);
};

export default renderRoutes;
