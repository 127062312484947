import { FC, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import ContainedSelect from 'components/common/Selects/ContainedSelect/ContainedSelect';
import cls from './styles.module.scss';

interface ContainedSelectWithHeaderProps {
	title: string;
	options?: Array<{ label: string; value: any }>;
	initialValue?: any;
	onChange?: (value: any) => void;
	disabled?: boolean;
}

const ContainedSelectWithHeader: FC<ContainedSelectWithHeaderProps> = ({
	title,
	options = [],
	initialValue,
	onChange,
	disabled,
}) => {
	const [selectedOption, setSelectedOption] = useState<any>(initialValue || options[0] || null);

	useEffect(() => {
		if (initialValue) {
			setSelectedOption(options.find((option) => option.value === initialValue));
		}
	}, [initialValue, options]);

	return (
		<div className={cls.root}>
			<Typography className={cls.title}>{title}</Typography>

			<ContainedSelect
				width="100%"
				onSelectChange={(selected) => {
					setSelectedOption(selected);
					if (onChange) {
						onChange(selected);
					}
				}}
				value={selectedOption}
				options={options}
				disabled={disabled}
			/>
		</div>
	);
};

export default ContainedSelectWithHeader;
