import React from 'react';

export const ArrowPaginationRight = (props: any) => (
	<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10.4685 6.65545L4.30521 1.84159C4.28911 1.82891 4.26975 1.82103 4.24937 1.81885C4.22899 1.81668 4.20841 1.8203 4.18999 1.82929C4.17157 1.83828 4.15607 1.85229 4.14525 1.8697C4.13444 1.88711 4.12875 1.90722 4.12885 1.92772V2.98456C4.12885 3.05155 4.16029 3.11581 4.21224 3.15682L9.13411 6.99999L4.21224 10.8432C4.15892 10.8842 4.12885 10.9484 4.12885 11.0154V12.0723C4.12885 12.1639 4.23412 12.2144 4.30521 12.1584L10.4685 7.34452C10.5209 7.30366 10.5632 7.25139 10.5924 7.19168C10.6215 7.13198 10.6367 7.06642 10.6367 6.99999C10.6367 6.93355 10.6215 6.86799 10.5924 6.80829C10.5632 6.74858 10.5209 6.69632 10.4685 6.65545Z"
			fill="#2F3747"
			{...props}
		/>
	</svg>
);
