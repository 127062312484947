import React, { useState } from 'react';
import { getGlobalStyles } from 'styles';
import { translate } from 'localizations';
import { useAppSelector } from 'hooks/redux';
import { CallTagType } from 'store/calls/calls.types';
import { ISearchedWord } from '../TagsBlock/TagsBlock';
import { getFormattedTags } from '../../CallBody/utils';

interface IOwnProps {
	expanded: boolean;
	tagsAndFragmentsArray: {
		tags: any[];
		fragments: any[];
		searchedWords?: ISearchedWord[];
	};
	setCurrentFragmentHandle: (value: CallTagType | ISearchedWord) => void;
}

const CallsFragments: React.FC<IOwnProps> = ({ tagsAndFragmentsArray, expanded, setCurrentFragmentHandle }) => {
	const { blueTag, purpleTag, grayTag } = getGlobalStyles();
	const { language } = useAppSelector(state => state.lang);
	const { trimmedTags, reducedTags } = getFormattedTags(tagsAndFragmentsArray.fragments);

	const [allTags, setAllTags] = useState(trimmedTags);
	const [showAllTags, setShowAllTags] = useState(false);

	const handleShowMoreTags = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		if (showAllTags) {
			setAllTags(trimmedTags);
		} else {
			setAllTags(tagsAndFragmentsArray.fragments)
		}
		setShowAllTags(!showAllTags);
	};

	return (
		<div style={{ display: 'flex', marginBottom: '5px' }}>
			<div style={{
				display: 'flex',
				alignItems: 'center',
				flexWrap: 'wrap',
				width: '100%',
			}}>
				{(tagsAndFragmentsArray.searchedWords || []).map((word: ISearchedWord) => (
					<div
						key={word.fragment}
						className={blueTag}
						onClick={async (e) => {
							setCurrentFragmentHandle(word);
							if (expanded) {
								e.stopPropagation();
							}
						}}
					>
						{word.text}
					</div>
				))}
				{(allTags || []).map((fragment: { tag: CallTagType; count: number }) => (
					<div
						key={fragment.tag.name}
						className={purpleTag}
						onClick={async (e) => {
							setCurrentFragmentHandle(fragment.tag);
							if (expanded) {
								e.stopPropagation();
							}
						}}
					>
						{fragment.tag.name} {fragment.count > 1 && <>x{fragment.count}</>}
					</div>
				))}
				{reducedTags.length > 0 && (
					<button
						className={grayTag}
						onClick={handleShowMoreTags}
					>
						{showAllTags ? translate('tagHide', language) : `${translate('show_more', language)} ${reducedTags.length}`}
					</button>
				)}
			</div>
		</div>
	);
};

export default CallsFragments;
