/* eslint-disable */
import cloneDeep from 'lodash/cloneDeep';
import { ColsGroupByArrType, ReportParametersType } from 'store/reports/reports.types';
import { CriteriasType } from 'store/search/search.types';
import { convertCriteriasForRequest } from 'utils/convertDataForSearchFilters/convertData';
import { criteriaWithComplexValues } from 'configs/searchCriteria';

// TODO: необходим рефакторинг
// TODO: необходима типизация

export const convertColumnRows = (obj: any, parameters: any) => {
	const activeReport = cloneDeep(obj);
	const local = [...parameters];
	const groupByName = activeReport.cols_group_by[0].group_by;
	if (groupByName === 'tag') return activeReport;
	if (groupByName === 'search_items') {
		const localObj = { ...activeReport.cols_group_by[0].value, search_items: local };
		activeReport.cols_group_by[0].value = localObj;
	} else if (groupByName === 'tag_name_value' || groupByName === 'tag_name_list') {
		const localArr = [];
		for (let i = 0; i < activeReport.cols_group_by[0].value.values.length; i++) {
			const item = activeReport.cols_group_by[0].value.values[i];
			if (item.value) localArr.push(item.value);
			else localArr.push(item);
		}
		activeReport.cols_group_by[0].value.values = localArr;
	} else {
		activeReport.cols_group_by[0] = { group_by: groupByName };
	}

	if (activeReport.rows_group_by.group_by === 'tag_name_value') {
		const localArr = [];
		for (let i = 0; i < activeReport.rows_group_by.value.values.length; i++) {
			const item = activeReport.rows_group_by.value.values[i];
			if (item.value) localArr.push(item.value);
			else localArr.push(item);
		}
		activeReport.rows_group_by.value.values = localArr;
	}
	return activeReport;
};
export const convertColsGroupBy = (obj: any, parameters: any): ReportParametersType | undefined => {
	const activeReport = { ...obj };
	const local: any = [];
	for (let i = 0; i < parameters.length; i++) {
		if (!parameters[i][0].select.value) {
			return;
		}
		if (parameters[i][0].select.value.value === 'tag') {
			local.push({
				group_by: parameters[i][0].select.value.value,
				value: parameters[i][0].tagsVal.value.value,
			});
		} else if (parameters[i][0].select.value.value === 'search_items') {
			const searchItemsArr = [];
			const activeValues = parameters[i][0].callFilters.activeValues;
			for (let y = 0; y < activeValues.length; y++) {
				const newSearchItems = criteriaWithComplexValues.includes(activeValues[y].key)
					? {
							key: activeValues[y].key,
							complexValues: activeValues[y].values.map((value: string) => JSON.parse(value)),
							logic: activeValues[y].logic || 'and',
					  }
					: {
							key: activeValues[y].key,
							values: activeValues[y].values,
					  };
				searchItemsArr.push(newSearchItems);
			}
			local.push({
				group_by: parameters[i][0].select.value.value,
				value: {
					col_name: parameters[i][0].nameColumn.value,
					search_items: searchItemsArr,
				},
			});
		} else if (parameters[i][0].select.value.value === 'tag_name_list') {
			const tagsNameList = parameters[i][0].tagsNameList;
			const localValues = [];
			for (let i = 0; i < tagsNameList.values.length; i++) {
				localValues.push(tagsNameList.values[i].value);
			}
			local.push({
				group_by: parameters[i][0].select.value.value,
				value: {
					values: localValues,
					col_name: tagsNameList.col_name,
					split: tagsNameList.split,
				},
			});
		} else if (parameters[i][0].select.value.value === 'tag_name_value') {
			const tagNameValue = parameters[i][0].tagNameValue;
			const localValues = [];
			for (let i = 0; i < tagNameValue.values.length; i++) {
				localValues.push(tagNameValue.values[i].value);
			}
			local.push({
				group_by: parameters[i][0].select.value.value,
				value: {
					name: tagNameValue.name,
					values: localValues,
					split: tagNameValue.split,
				},
			});
		} else {
			local.push({ group_by: parameters[i][0].select.value.value });
		}
	}
	const colGroup = activeReport.cols_group_by[0];
	const localArr = [...[colGroup], ...local];
	activeReport.cols_group_by = localArr;
	return activeReport;
};

export const convertCallSearch = (callSearchArr: any, requestBody: any) => ({
	...requestBody,
	call_search_items: callSearchArr,
});

export const joinReportIdWithBodyReport = (requestBody: any, reportId: string | undefined) => {
	if (reportId) {
		return { ...requestBody, report_id: reportId };
	}
	return requestBody;
};

export const requestHandler = (
	activeCriterias: CriteriasType[],
	activeCriteriasColumn: CriteriasType[],
	activeReport: ReportParametersType,
	activeParameters: ColsGroupByArrType[][],
	reportId?: string | undefined,
) => {
	let requestData = [];
	const requestActiveCriterias = convertCriteriasForRequest(activeCriterias);
	const requestSearchItemsColumn = convertCriteriasForRequest(activeCriteriasColumn);
	const requestFilters = convertColumnRows(activeReport, requestSearchItemsColumn);

	const requestColsGroupBy = convertColsGroupBy(requestFilters, activeParameters);
	const requestDataWithCallSearch = convertCallSearch([...requestActiveCriterias], requestColsGroupBy);
	requestData = joinReportIdWithBodyReport(requestDataWithCallSearch, reportId);

	return requestData;
};
