/* eslint-disable react/destructuring-assignment */
import React from 'react';

export const PlusIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.00034 2.66602C8.38454 2.66602 8.69599 2.97747 8.69599 3.36167V12.637C8.69599 13.0212 8.38454 13.3327 8.00034 13.3327C7.61614 13.3327 7.30469 13.0212 7.30469 12.637V3.36167C7.30469 2.97747 7.61614 2.66602 8.00034 2.66602Z"
			fill={props.fill || '#73D13D'}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.66602 8.00034C2.66602 7.61614 2.97747 7.30469 3.36167 7.30469H12.637C13.0212 7.30469 13.3327 7.61614 13.3327 8.00034C13.3327 8.38454 13.0212 8.69599 12.637 8.69599H3.36167C2.97747 8.69599 2.66602 8.38454 2.66602 8.00034Z"
			fill={props.fill || '#73D13D'}
		/>
	</svg>
);
