import React, { FC, KeyboardEvent, ChangeEvent } from 'react';
import { IconButton } from '@mui/material';
import { CheckSvg } from 'components/Icons/CheckIcon';
import { CloseSvg } from 'components/Icons/CloseIcon';
import styles from './styles.module.scss';

type EditableInputProps = {
	value: string;
	onConfirm: (newValue: string) => void;
	onCancel: () => void;
	size?: 'small' | 'medium';
};

const EditableInput: FC<EditableInputProps> = ({ value, onConfirm, onCancel, size = 'medium' }) => {
	const [currentValue, setCurrentValue] = React.useState(value);

	const handleKeyDown = async (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.stopPropagation();
			await onConfirm(currentValue);
		}
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setCurrentValue(event.target.value);
	};

	return (
		<div className={styles.root}>
			<input
				className={styles.input}
				value={currentValue}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				onClick={(event): void => {
					event.stopPropagation();
				}}
			/>
			<IconButton size={size} onClick={async () => onConfirm(currentValue)}>
				<CheckSvg />
			</IconButton>
			<IconButton size={size} onClick={onCancel}>
				<CloseSvg />
			</IconButton>
		</div>
	);
};

export default EditableInput;
