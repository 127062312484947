import { useAppSelector } from 'hooks/redux';
import { translate } from 'localizations';

const useHintTranslations = () => {
	const { language } = useAppSelector(state => state.lang);

	return {
		name: translate('hint_name', language),
		login: translate('hint_login', language),
		email: translate('hint_email', language),
		password: translate('hint_password', language),
		role: translate('hint_role', language),
		comment: translate('hint_comment', language),
		sttLanguage: translate('hint_stt_lang', language),
		sttEngine: translate('hint_stt_engine', language),
		sttModel: translate('hint_stt_model', language),
		time: translate('hint_time', language),
		phone: translate('hint_phone', language),
	}
};

export default useHintTranslations;
