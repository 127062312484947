import { createAsyncThunk } from '@reduxjs/toolkit';
import { CallsActionDataType } from './calls.types';
import { instance } from '../../api/api.config';
import { langSlice } from '../lang/lang.slice';
import { callsActions } from '.';

export const getAllUserDicts = createAsyncThunk('dicts/getAllUserDicts', async () => {
	const { token } = JSON.parse(localStorage.getItem('token') || '{}');
	const { data } = await instance.get(`/dicts/?show_disabled=false&only_local=false`, {
		headers: {
			Authorization: `Bearer ${token}`,
			accept: `application/json`,
		},
	}); // @ts-ignore
	// await thunkAPI.dispatch(callsActions.setAllUserDicts({allUserDicts: data}));
	return data;
});
export const getAllWordInDictionary = createAsyncThunk(
	'dicts/getAllWordInDictionary',
	async (payload: { id: string }) => {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.get(`/dict/${payload.id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
				accept: `application/json`,
			},
		});
		return data;
	},
);
export const updateDict = createAsyncThunk(
	'dicts/updateDictPhrases',
	async (payload: { dictId: string; phrases: any[] }) => {
		try {
			const { token } = JSON.parse(localStorage.getItem('token') || '{}');
			const sendData = { phrases: payload.phrases }; // @ts-ignore
			const response = await instance.put<string>(`/dict/${payload.dictId}`, JSON.stringify(sendData), {
				headers: {
					'Content-Type': 'application/json',
					accept: 'application/json',
					Authorization: `Bearer ${token}`,
				},
			});
		} catch (error) {}
	},
);

export const getCallPublicToken = createAsyncThunk('calls/getCallPublicToken', async (id: string) => {
	const { token } = JSON.parse(localStorage.getItem('token') || '{}');
	const { data } = await instance.get(`call/${id}/public_token`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	return data;
});

export const getCallStt = createAsyncThunk(
	'calls/getCallStt',
	async (payload: { id: string | any; token?: string | any }, thunkAPI) => {
		try {
			const { token } = JSON.parse(localStorage.getItem('token') || '{}');
			const { data } = await instance.get(`call/${payload.id}/stt`, {
				headers: { Authorization: `Bearer ${payload.token ? payload.token : token}` },
			});
			thunkAPI.dispatch(callsActions.setStt(data));
			return data;
		} catch (e) {
			thunkAPI.dispatch(
				langSlice.actions.setSnackbar({
					type: 'error',
					time: 2000,
					text: 'Что-то пошло не так',
				}),
			);
		}
	},
);

export const getCallAudio = createAsyncThunk(
	'calls/getCallAudio',
	async (payload: { id: string | any; token?: string | any }, thunkAPI) => {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.get(`call/${payload.id}/audio`, {
			responseType: 'arraybuffer',
			headers: {
				Authorization: `Bearer ${payload.token ? payload.token : token}`,
				'Content-Type': 'audio/wav',
			},
		});
		const blob = new Blob([data], { type: 'audio/wav' });
		const blobUrl = URL.createObjectURL(blob);
		return blobUrl;
	},
);

export const getTokenForCallAudio = createAsyncThunk(
	'calls/getTokenForCallAudio',
	async (payload: { id: string | any; token?: string | any }, thunkAPI) => {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const response = await instance.get(`call/${payload.id}/token_for_audio`, {
			headers: { Authorization: `Bearer ${payload.token ? payload.token : token}` },
		});
		thunkAPI.dispatch(callsActions.setToken(response.data.access_token));
	},
);

export const deleteCall = createAsyncThunk('calls/deleteCall', async (id: string, thunkAPI) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		return await instance.delete(`call/${id}`, { headers: { Authorization: `Bearer ${token}` } });
	} catch (error) {
		thunkAPI.dispatch(
			langSlice.actions.setSnackbar({
				type: 'error',
				time: 2000,
				text: 'Что-то пошло не так',
			}),
		);
	}
});

export const callsAction = createAsyncThunk('calls/callsAction', async (payload: CallsActionDataType, thunkAPI) => {
	try {
		const { token } = JSON.parse(localStorage.getItem('token') || '{}');
		const { data } = await instance.post(`calls/action`, payload, {
			headers: { Authorization: `Bearer ${token}` },
		});
		return data;
	} catch (error) {
		thunkAPI.dispatch(
			langSlice.actions.setSnackbar({
				type: 'error',
				time: 2000,
				text: 'Something wrong : (',
			}),
		);
	}
});

