/* eslint-disable */
const CopyToClipboardIcon = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.0013 2.16669C3.88421 2.16669 2.16797 3.88293 2.16797 6.00002V10.738C2.16797 11.0141 2.39183 11.238 2.66797 11.238C2.94411 11.238 3.16797 11.0141 3.16797 10.738V6.00002C3.16797 4.43521 4.4365 3.16669 6.0013 3.16669H10.6766C10.9527 3.16669 11.1766 2.94283 11.1766 2.66669C11.1766 2.39055 10.9527 2.16669 10.6766 2.16669H6.0013Z"
			fill="#722ED1"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.2671 4.52883C10.1064 4.28735 7.89097 4.28735 5.73035 4.52883C5.11483 4.59762 4.62021 5.08201 4.54766 5.70231C4.29141 7.89332 4.29141 10.1067 4.54766 12.2977C4.62021 12.918 5.11483 13.4023 5.73035 13.4711C7.89097 13.7127 10.1064 13.7127 12.2671 13.4711C12.8826 13.4023 13.3772 12.918 13.4498 12.2977C13.706 10.1067 13.706 7.89332 13.4498 5.70231C13.3772 5.08201 12.8826 4.59762 12.2671 4.52883ZM5.84143 5.52264C7.92823 5.28941 10.0692 5.28941 12.156 5.52264C12.3142 5.54032 12.4386 5.66546 12.4565 5.81847C12.7038 7.93225 12.7038 10.0677 12.4565 12.1815C12.4386 12.3345 12.3142 12.4597 12.156 12.4773C10.0692 12.7106 7.92823 12.7106 5.84143 12.4773C5.68319 12.4597 5.55879 12.3345 5.54089 12.1815C5.29367 10.0677 5.29367 7.93225 5.54089 5.81847C5.55879 5.66546 5.68319 5.54032 5.84143 5.52264Z"
			fill="#722ED1"
		/>
	</svg>
);

export default CopyToClipboardIcon;
