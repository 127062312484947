import React from 'react';
import { CircularProgress } from '@mui/material';
import style from './CallProgress.module.scss';

const CallProgress = () => (
	<div className={style.circularWrapper}>
		<CircularProgress className={style.circular} />
	</div>
);

export default CallProgress;
