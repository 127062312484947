import { ContainedSelect } from 'components/common';
import React, { FC } from 'react';
import { FormikErrors } from 'formik';
import { translate } from 'localizations';
import { LangType } from 'store/lang/lang.slice';
import '../../../../Settings.css';

interface STTSelectProps {
	title: string;
	placeholder: string;
	isDisabled: boolean;
	onSelectionChange: (e: any) => void;
	options: { value: string; label: string }[];
	value: { value: string; label: string } & string;
	errors: FormikErrors<string> | undefined;
	language: LangType | string;
}

const STTSelect: FC<STTSelectProps> = ({
	isDisabled,
	onSelectionChange,
	options,
	value,
	errors,
	title,
	placeholder,
	language,
}) => (
	<div className="select-required">
		<p className="section-sub-sub-title">
			{title}
			{' *'}
		</p>
		<ContainedSelect
			height="38px"
			width="100%"
			justify="flex-end"
			marginRight="10px"
			placeholder={placeholder}
			disabled={isDisabled}
			onSelectChange={(e) => {
				if (e) onSelectionChange(e);
			}}
			options={options}
			value={value}
			isSearchable
		/>
		{errors ? <div className="select-required-title">{translate('required', language)}</div> : null}
	</div>
);

export default STTSelect;
