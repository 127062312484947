import { useAppSelector } from './redux';

/**
 * возвращает:
 * для админа и менеджера - пользователя, выбранного в селекте пользователей (админ бар) или они их самих
 * для компании (user) - саму компанию
 * для сотрудника (operator) - вернет его компанию
 */
const useCurrentUser = () => {
	const { childUser, user } = useAppSelector(state => state.user);
	return childUser || user;
};

export default useCurrentUser;
