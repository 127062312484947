import { FC, useEffect } from 'react';
import { getMeAccessRights } from 'store/user/user.slice';
import { useAppDispatch } from 'hooks/redux';
import CallPage from '../Call/CallPage';

import s from './styles.module.scss';

const CalliFrame: FC = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getMeAccessRights());
	}, []);

	return (
		<CallPage
			callPageBoxStyles={s.box}
			showOpenLink={false}
			showParams={false}
			marginTop="0"
		/>
	)
};

export default CalliFrame;
