const base = 'markup';

const getTestId = (name: string) => `${base}_${name}`;

const markupTestIds = {
	addButton: (page: string) => page !== 'checklists' ? getTestId('addGroup') : getTestId('addChecklists'),
	importButton: (page: string) => page === 'tags' ? getTestId('importTagRules') : getTestId('importDicts'),
	addItemButton: (page: string) => page === 'dicts' ? getTestId('addDict') : getTestId('addTag'),
	pageNav: (page: string) => getTestId(`nav_${page}`),
	itemsSearch: (page: string) => getTestId(`${page}_search}`),
	itemsSort: (page: string) => getTestId(`${page}_sort}`),
	importUserSelect: () => getTestId('importUserSelect'),
	importNav: (page: string) => getTestId(`importNav_${page}`),
	importItemsSearch: (page: string) => getTestId(`${page}_importSearch}`),
	newRuleInput: () => getTestId('newRuleInput'),
	newRuleApply: () => getTestId('newRuleApply'),
	newRuleCancel: () => getTestId('newRuleCancel'),
};

export default markupTestIds;
