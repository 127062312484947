import React from 'react';

export const SortUpIcon: React.FC = () => (
	<svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8.38784 6.72827L5.1808 3.00952C5.08901 2.90308 4.91225 2.90308 4.81948 3.00952L1.61244 6.72827C1.4933 6.86694 1.60073 7.07007 1.79311 7.07007H8.20717C8.39955 7.07007 8.50698 6.86694 8.38784 6.72827Z"
			fill="#2F3747"
		/>
		<path
			d="M8.20717 10.9297H1.79311C1.60073 10.9297 1.4933 11.1328 1.61244 11.2715L4.81948 14.9902C4.91127 15.0967 5.08803 15.0967 5.1808 14.9902L8.38784 11.2715C8.50698 11.1328 8.39955 10.9297 8.20717 10.9297Z"
			fill="#A3AEBE"
		/>
	</svg>
);
