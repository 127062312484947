import { OptionType } from '../components/common/Selects/LabelSelect/LabelSelect';
import { SavedReportType } from '../store/reports/reports.types';
import { TemplateType } from '../store/search/search.types';
import { translate } from '../localizations';

// TODO: разобраться с any
export interface ValueObj {
	value: any;
	label: string;
}

/** return array of objects */

// TODO: разобраться с any
export function optionsCreator(state: any | null) {
	if (state) {
		const local: { value: any; label: string }[] = [];
		for (let i = 0; i < state.length; i++) {
			local.push({ value: state[i], label: state[i].title });
		}
		return local;
	}
	return [];
}

// VEL - value equal label
export function optionsCreatorVEL(state: string[]): OptionType[] {
	if (state) {
		return state.map((item) => ({ value: item, label: item }));
	}
	return [];
}

export function optionsCreatorWithName(state: SavedReportType[] | null): ValueObj[] {
	if (state) {
		return state.map((item) => ({ value: item.id, label: item.name }));
	}
	return [];
}

/** return object */

export function optionsCreatorSimple(state: string): ValueObj {
	let local: ValueObj = { value: '', label: '' };
	if (state) {
		local = { value: state, label: state };
	}
	return local;
}

export function optionsCreatorSimpleTitle(state: TemplateType | null): ValueObj | null {
	if (state) {
		return { value: state, label: state.title };
	}
	return null;
}

/** return array of string */

export function optionsDeConverter(state: OptionType[]): string[] {
	return state.map((item) => item.value);
}

export const optionTranslate = (values: string[], language: string): OptionType[] =>
	values.map((value) => ({ value, label: translate(value, language) }));
