export const hasError = (
	key: string,
	form: Record<string, any>,
) => key in form.errors;

export const showError = (
	name: string,
	form: Record<string, any>,
	displayErrors: string[],
) => hasError(name, form) && displayErrors.includes(name) ? 'red' : '';
