/* eslint-disable indent */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { IUserIntegrationsList } from 'pages/Settings/Integrations/types/integrationTypes';
import { API_URL } from '../config';
import { getTokens } from '../utils/tokens';

export const integrationsAPI = createApi({
	reducerPath: 'integrationsAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: API_URL,
		prepareHeaders: (headers) => {
			const { token } = getTokens();
			if (token) {
				headers.set('authorization', `Bearer ${token}`);
			}
			return headers;
		},
	}),
	tagTypes: ['UserIntegrationsList', 'UserIntegration'],
	endpoints: (build) => ({
		fetchAllIntegrations: build.query({
			query: (detail = false) => ({
				url: `/integrations/services${detail ? '?detail=true' : ''}`,
			}),
		}),
		getAccessParamsByService: build.query({
			query: (service_name) => ({
				url: `/integrations/services/${service_name}/access_params`,
			}),
		}),
		getSettingsByService: build.query({
			query: (service_name) => ({
				url: `/integrations/services/${service_name}/settings`,
			}),
		}),
		getServiceDetail: build.query({
			query: (service_name) => ({
				url: `/integrations/services/${service_name}/detail`,
			}),
		}),
		getUserIntegrationsList: build.query<IUserIntegrationsList[], string | undefined>({
			query: (user_id) => ({
				url: `/integrations/${user_id}`,
			}),
			providesTags: (result) =>
				result
					? [
							...result.map(({ id }) => ({ type: 'UserIntegrationsList' as const, id })),
							{ type: 'UserIntegrationsList' as const },
					  ]
					: [{ type: 'UserIntegrationsList' as const }],
		}),
		getUserIntegratinSettings: build.query({
			query: ({ user_id, integration_id }) => ({
				url: `/integrations/${user_id}/${integration_id}`,
			}),
			providesTags: (result, error, { integration_id }) => [{ type: 'UserIntegration', id: integration_id }],
		}),
		getIntegrationStatus: build.query({
			query: ({ user_id, integration_id }) => ({
				url: `/integrations/tasks/${user_id}?integration_id=${integration_id}&latest=true`,
			}),
		}),
		createUserIntegration: build.mutation({
			query: ({ user_id, integrationParams }) => ({
				url: `/integrations/${user_id}`,
				method: 'POST',
				body: integrationParams,
			}),
			invalidatesTags: ['UserIntegrationsList'],
		}),
		updateUserIntegratinSettings: build.mutation({
			query: ({ user_id, integration_id, integrationSettings }) => ({
				url: `/integrations/${user_id}/${integration_id}`,
				method: 'PUT',
				body: integrationSettings,
			}),
			invalidatesTags: (result, error, { integration_id }) => [{ type: 'UserIntegration', id: integration_id }],
		}),
		deleteUserIntegration: build.mutation({
			query: ({ user_id, integration_id }) => ({
				url: `/integrations/${user_id}/${integration_id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['UserIntegrationsList'],
		}),
		validateFiltrationRule: build.mutation({
			query: ({ user_id, jsonBody }) => ({
				url: `/integrations/${user_id}/validation/filtration_rules`,
				method: 'POST',
				body: jsonBody,
			}),
		}),
		createFiltrationRule: build.mutation({
			query: ({ user_id, integration_id, jsonBody }) => ({
				url: `/integrations/${user_id}/${integration_id}/filtration_rules`,
				method: 'POST',
				body: jsonBody,
			}),
		}),
		getUserTasks: build.query({
			query: ({ userId, filters }) => ({
				url: `/integrations/tasks/${userId}`,
				params: filters,
			}),
		}),
		getTaskStatus: build.query({
			query: ({ taskId }) => ({
				url: `/integrations/tasks/${taskId}`,
			}),
		}),
		cancelUserTask: build.mutation({
			query: ({ taskId }) => ({
				url: `/integrations/tasks/${taskId}`,
				method: 'DELETE',
			}),
		}),
		getTaskLogs: build.query({
			query: ({ taskId, page }) => ({
				url: `/integrations/tasks/${taskId}/logs`,
				params: page,
			}),
		}),
		createUserTask: build.mutation({
			query: ({ user_id, taskData }) => ({
				url: `/integrations/tasks/${user_id}`,
				method: 'POST',
				body: taskData,
			}),
		}),
	}),
});
