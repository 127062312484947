import React from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DisableMask, IconButton } from 'components/common';
import { translate } from 'localizations';
import { getCallAudio, getCallPublicToken } from 'store/calls/calls.slice';
import { getHash, callAction } from 'store/calls/actions';
import { createSnackbarOptions } from 'components/common/Snackbar/Snackbar';
import { useAppSelector } from 'hooks/redux';
import { CallInfoType, CallInitialStateType } from 'store/calls/calls.types';
import { getActionFiles, getTaskStatus } from 'store/tasks/tasks.slice';
import { ShareIcon } from 'components/Icons/ShareIcon';
import ActionsSelect from 'pages/Calls/components/CallsHeader/components/CallHeadersSettings/components/ActionsSelect/ActionsSelect';
import { selectCalls, selectFilterCallsHash } from 'store/calls/selectors';
import { UserType } from 'store/user/user.types';
import { useActionsFunctions } from 'pages/Calls/components/CallsHeader/components/CallHeadersSettings/hooks/useActionsFunctions';
import { getFullCriteria } from '../../CallsSearch/Search';
import { DownloadSvg, HistorySvg, RebootSvg } from './CallBodyButtons.svg';

interface IOwnProps {
	callInfo: CallInfoType;
	currentCall: CallInitialStateType['currentCall'];
	language: string;
	isAuth: boolean | 'loading';
	userInfo: UserType | null;
	currentPage: number | undefined;
}

const CallBodyButtons: React.FC<IOwnProps> = ({ callInfo, currentCall, language, isAuth, userInfo, currentPage }) => {
	const { enqueueSnackbar } = useSnackbar();
	const allCriterias = useAppSelector((state) => state.search.allCriterias);
	const dispatch = useDispatch();
	const callId = callInfo.id.toUpperCase();
	const history = useHistory();
	const pathArray = history.location.pathname.split('/');
	const urlId = pathArray[2];

	const accessRights = useAppSelector((state) => state.user.accessRights);
	const host = window.origin;

	const callsData = useAppSelector(selectCalls);
	const pageCount = callsData?.found ?? null;

	const filterHash = useAppSelector(selectFilterCallsHash);

	const { onActionClick, onActionSTTClick } = useActionsFunctions(filterHash, callInfo.id);

	// onClick's действий со звонком

	async function formPublicToken(id: string) {
		enqueueSnackbar(
			null,
			createSnackbarOptions({
				type: 'info',
				text: translate('generateLink', language),
				time: 2000,
			}),
		);

		const publicTokenData = await dispatch(getCallPublicToken(id)); // @ts-ignore
		const publicToken: { access_token: string; token_type: string } = publicTokenData.payload;
		await navigator.clipboard.writeText(`${host}/${language}/call?id=${id}&token=${publicToken.access_token}`);

		enqueueSnackbar(
			null,
			createSnackbarOptions({
				type: 'success',
				text: translate('publicLinkСopied', language),
				time: 2000,
			}),
		);
	}

	async function downloadAudio() {
		if (currentCall && currentCall.info) {
			const blobData = await dispatch(getCallAudio({ id: currentCall.id })); // @ts-ignore
			const blob = blobData.payload;
			if (blob) {
				const a = document.createElement('a');
				a.href = blob;
				a.download = `${currentCall.id}.wav`;
				document.body.appendChild(a);
				a.click();
				enqueueSnackbar(
					null,
					createSnackbarOptions({
						type: 'success',
						text: translate('callLoaded', language),
						time: 2000,
					}),
				);
			}
		}
	}

	async function excelAction() {
		if (currentCall && currentCall.id) {
			const blobData = await dispatch(callAction({ id: currentCall.id, data: { action: 'stt_export' } })); // @ts-ignore
			const blob = blobData.payload;
			if (blob) {
				const taskId = blob.task_id;
				if (taskId) {
					const taskData = await dispatch(getTaskStatus(taskId)); // @ts-ignore
					const task = taskData.payload;
					if (task) {
						await dispatch(getActionFiles({ taskId, action: 'xlsx_export' }));
						enqueueSnackbar(
							null,
							createSnackbarOptions({
								type: 'success',
								text: translate('exportExcelSuccess', language),
								time: 2000,
							}),
						);
					}
				}
			}
		}
	}

	async function rebootAction() {
		if (currentCall && currentCall.stt) {
			onActionClick('analyze');
		}
	}

	async function historyAction() {
		const getFullCriteriaOutput = getFullCriteria('client_phone', allCriterias);
		if (getFullCriteriaOutput) {
			const clientPhoneCriteria = { ...getFullCriteriaOutput, values: [callInfo.clientPhone] };
			const hashData = await dispatch(getHash({ searchParams: [clientPhoneCriteria] })); // @ts-ignore
			const hash = hashData.payload;
			window.open(`${host}/${language}/${urlId}/calls?searchHash=${hash}`);
		}
	}

	const hasAccessToExport = accessRights && accessRights.export && accessRights.audio;
	const hasAccessToActions = accessRights && accessRights.calls_actions;
	const hasAccessToRetag = isAuth && accessRights && accessRights.retag;

	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
				{/* Перетегирование */}
				{hasAccessToRetag && (
					<IconButton
						margin="0 8px 0 0"
						backgroundColor="#F8FAFC"
						disabled={false}
						icon={<RebootSvg />}
						onClick={async () => {
							await rebootAction();
						}}
						tooltipTitle={`${translate('retagCall', language)}`}
						tooltipPlacement="top"
					/>
				)}

				{/* Скачать */}
				{hasAccessToExport && (
					<IconButton
						onClick={async () => {
							await downloadAudio();
						}}
						disabled={Boolean(currentCall && !accessRights.export && !accessRights.audio)}
						icon={<DownloadSvg />}
						margin="0 9px 0 0"
						backgroundColor="#F8FAFC"
						tooltipTitle={`${translate('download', language)}`}
						tooltipPlacement="top"
					/>
				)}

				{/* Скачать excel */}
				{accessRights && accessRights.export && (
					<IconButton
						onClick={async () => {
							await excelAction();
						}}
						disabled={Boolean(currentCall && !accessRights.export)}
						icon={<div style={{ color: '#738094', fontWeight: '700' }}>EX</div>}
						margin="0 9px 0 0"
						backgroundColor="#F8FAFC"
						tooltipTitle={`${translate('exportExcel', language)}`}
						tooltipPlacement="top"
					/>
				)}

				{/* История */}
				{isAuth && (
					<IconButton
						onClick={async () => {
							await historyAction();
						}}
						icon={<HistorySvg />}
						backgroundColor="#F8FAFC"
						margin="0 8px 0 0"
						tooltipTitle={translate('history', language)}
						tooltipPlacement="top"
						disabled={!callInfo.clientPhone}
					/>
				)}

				{/* Публичная ссылка */}
				{isAuth && (
					<IconButton
						onClick={async () => {
							await formPublicToken(callId);
						}}
						icon={<ShareIcon />}
						backgroundColor="#F8FAFC"
						tooltipTitle={translate('copyPublicUrl', language)}
						tooltipPlacement="top"
					/>
				)}

				{/* Другие действия внутри селекта */}
				<DisableMask disable={!hasAccessToActions}>
					<ActionsSelect
						accessRights={accessRights}
						language={language}
						pageCount={pageCount}
						onIconClick={onActionClick}
						onIconClickSTT={onActionSTTClick}
						userInfo={userInfo}
						isSingleCall
						callId={callInfo.id}
					/>
				</DisableMask>
			</div>
		</div>
	);
};

export default CallBodyButtons;
