import { TInitialState } from './namespace';
import { callsCheckListsAdapter, userCheckListsAdapter } from './adapters';

export const initialState: TInitialState = {
	isCallsCheckListsLoading: false,
	callsCheckLists: { ...callsCheckListsAdapter.getInitialState() },
	isUserCheckListsLoading: false,
	userCheckLists: { ...userCheckListsAdapter.getInitialState() },
	currentCheckList: null,
	isNewCheckList: false,
};
