import React, { useState } from 'react';

import { CircularProgress, Typography } from '@mui/material';
import { translate } from 'localizations';
import { Tooltip } from 'components/common';
import { AccessRights } from 'store/user/user.types';
import { CallInitialStateType, CallTagType, CallTranslatedText, CommunicationType } from 'store/calls/calls.types';
import DialogItem from './DialogItem';
import ChatItem from './ChatItem';
import CallBodyCallTopTranslate from '../CallBodyCallTopTranslate/CallBodyCallTopTranslate';
import cn from 'classnames';

interface IOwnProps {
	language: string;

	onMouseUpFunction(event: React.MouseEvent<HTMLDivElement>): void;

	accessRights: AccessRights | null;
	currentCall: CallInitialStateType['currentCall'];
	callId: string;
	fragments?: CallTagType[];
	onCallBodyFragmentClick: (fragments: CallTagType) => Promise<void>;
	innerRef: React.RefObject<HTMLDivElement>;
	// TOOD: Fix any types
	classes: any;
	solo?: boolean;
	audioPlayerRef: any;
	languagesList: { value: string; label: string }[];
	callTranslated: CallTranslatedText[] | null;
	isCallLoading: boolean;
	getTranslate: (callIdForTranslate: string, languageCode: string) => void;
	communicationType: CommunicationType;
	hasAccessToTags: boolean;
	tagsAreActive?: boolean;
}

const CallBodyDialog: React.FC<IOwnProps> = ({
	language,
	onMouseUpFunction,
	classes,
	accessRights,
	solo,
	currentCall,
	callId,
	fragments,
	audioPlayerRef,
	onCallBodyFragmentClick,
	languagesList,
	innerRef,
	callTranslated,
	isCallLoading,
	getTranslate,
	communicationType,
	hasAccessToTags,
	tagsAreActive = true,
}) => {
	const isCallHasText = currentCall && currentCall.stt && currentCall.stt.fragments.length > 0;
	const [lang, setLang] = useState<{ value: string; label: string } | null>(null);

	const getTranslateHandle = (callIdForTranslate: string, languageValue: { value: string; label: string }) => {
		setLang(languageValue);
		getTranslate(callIdForTranslate, languageValue.value);
	};

	const TextComponent = communicationType === 'call' ? DialogItem : ChatItem;
	const dialogTitle = communicationType === 'call'
		? translate('translateCall', language) : translate('chatText', language);

	return (
		<div className={classes.callBodyDialogWrapper} onMouseUp={onMouseUpFunction} ref={innerRef}>
			{accessRights?.audio || accessRights?.admin || solo ? (
				<div style={{ height: '100%' }}>
					{currentCall && currentCall.stt && !isCallLoading ? (
						<div className={classes.cbDialog}>
							<div className={classes.cbDialogItems} id={callId}>
								{isCallHasText && (
									<CallBodyCallTopTranslate
										title={dialogTitle}
										language={language}
										callId={callId}
										languagesList={languagesList}
										langItem={lang}
										callTranslated={callTranslated}
										getTranslateHandle={getTranslateHandle}
									/>
								)}
								{isCallHasText ? (
									currentCall.stt.fragments.map((phrase, i, array) => {
										const uniqueFragmentNames = new Set();

										return (
											<div
												className={classes.cbPgrase}
												id={`${phrase.id}-phrase`}
												key={`${phrase.id}-phrase`}
											>
												<div className={classes.cbPgraseDialod}>
													<TextComponent
														audioPlayerRef={audioPlayerRef}
														prevFragment={
															array[i - 1]
																? array[i - 1]
																: {
																	direction:
																			phrase.direction === 'client'
																				? 'operator'
																				: 'client',
																  }
														}
														phrase={phrase}
														callId={callId}
														fragmentIndex={i}
														language={language}
													/>
													{callTranslated && (
														<div className={classes.callTranslated}>
															{callTranslated[i].text}
														</div>
													)}
												</div>
												{hasAccessToTags && (
													<div className={classes.cbFragments}>
														{fragments?.map((fragment) => {
															if (
																phrase.begin === fragment.fBegin &&
																phrase.end === fragment.fEnd &&
																!uniqueFragmentNames.has(fragment.name)
															) {
																uniqueFragmentNames.add(fragment.name);
																return (
																	<div
																		key={fragment.name}
																		onClick={() => {
																			if (tagsAreActive) onCallBodyFragmentClick(fragment);
																		}}
																	>
																		<Tooltip title={fragment.matchData}>
																			<div className={cn(classes.cbFragmentName, {
																				[classes.cbFragmentNameDisabled]: !tagsAreActive,
																			})}>
																				{fragment.name}
																			</div>
																		</Tooltip>
																	</div>
																);
															}
															return null;
														})}
													</div>
												)}
											</div>
										);
									})
								) : (
									<Typography>{translate('noCallTextMessage', language)}</Typography>
								)}
							</div>
						</div>
					) : (
						<div>
							{isCallLoading && (
								<div style={{ textAlign: 'center', height: '100%' }}>
									<CircularProgress size={80} />
								</div>
							)}
							{(currentCall && currentCall.stt === null && (
								<div style={{ textAlign: 'center' }}>
									<CircularProgress size={80} />
								</div>
							)) ||
								(currentCall === false && (
									<div>
										<Typography>{translate('somethingWentWrong', language)}</Typography>
									</div>
								))}
						</div>
					)}
				</div>
			) : (
				<Typography>You can&apos;t see text</Typography>
			)}
		</div>
	);
};

export default React.memo(CallBodyDialog);
