import { ContainedSelect } from 'components/common';
import React, { FC } from 'react';
import { LangType } from 'store/lang/lang.slice';
import { translate } from 'localizations';
import { CallTranslatedText } from 'store/calls/calls.types';
import styles from './styles.module.scss';

interface CallBodyCallTopTranslateProps {
	language: LangType | string;
	callId: string;
	title: string;
	languagesList: { value: string; label: string }[];
	langItem: { value: string; label: string } | null;
	callTranslated: CallTranslatedText[] | null;
	getTranslateHandle: (callIdForTranslate: string, languageValue: { value: string; label: string }) => void;
}

const CallBodyCallTopTranslate: FC<CallBodyCallTopTranslateProps> = ({
	language,
	callId,
	title,
	languagesList,
	langItem,
	callTranslated,
	getTranslateHandle,
}) => {
	const languagesOptions = [{ value: 'original', label: `${translate('original', language)}` }, ...languagesList];

	return (
		<div className={styles.topFlex}>
			<div className={styles.topTitle}>
				<div className={styles.topTitleLeft}>{title}</div>
				{callTranslated && (
					<div className={styles.topTitleRight}>{translate('translatedCall', language)}</div>
				)}
			</div>
			<ContainedSelect
				placeholder={translate('translate', language)}
				width="125px"
				height="32px"
				onSelectChange={(event) => {
					getTranslateHandle(callId, event);
				}}
				options={languagesOptions}
				value={langItem}
			/>
		</div>
	);
};

export default CallBodyCallTopTranslate;
