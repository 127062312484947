import axios from 'axios';
import { API_URL } from '../config';

export const instance = axios.create({
	baseURL: API_URL,
});

export const newInstance = axios.create({
	baseURL: API_URL,
});

newInstance.interceptors.request.use((config) => {
	const { token } = JSON.parse(localStorage.getItem('token') || '{}'); // @ts-ignore;

	config.headers.Authorization = `Bearer ${token}`;

	return config;
});