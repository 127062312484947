const NotifyTelegramIcon = () => (
	<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="48" height="48" rx="24" fill="#EFDBFF" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.7117 23.6258C17.4212 20.8123 21.8878 18.9427 24.1307 18.0357C30.5143 15.4628 31.8562 15.0185 32.7189 15C32.9106 15 33.3323 15.037 33.6199 15.2591C33.8499 15.4442 33.9074 15.6849 33.9458 15.87C33.9841 16.0551 34.0225 16.4438 33.9841 16.74C33.6391 20.2569 32.1438 28.7902 31.377 32.7144C31.0511 34.3804 30.4185 34.9357 29.8051 34.9912C28.4632 35.1023 27.4471 34.1397 26.1628 33.3253C24.1307 32.0481 22.9997 31.2521 21.0252 29.9934C18.744 28.5496 20.22 27.7537 21.5236 26.4579C21.8687 26.1247 27.7539 20.9418 27.8689 20.4791C27.8881 20.4235 27.8881 20.2014 27.7539 20.0904C27.6197 19.9793 27.428 20.0163 27.2746 20.0533C27.0637 20.0904 23.8432 22.1635 17.5746 26.2543C16.6544 26.8652 15.8301 27.1613 15.0825 27.1428C14.2582 27.1243 12.6862 26.6986 11.4977 26.3283C10.0599 25.8841 8.90974 25.6435 9.00559 24.866C9.0631 24.4588 9.6382 24.0516 10.7117 23.6258Z"
			fill="#9254DE"
		/>
	</svg>
);

export default NotifyTelegramIcon;
