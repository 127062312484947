import moment from 'moment';

export const unitsOfTime = {
	yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
	today: [moment().startOf('day'), moment().endOf('day')],
	this_week: [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
	this_month: [moment().subtract(1, 'month').add(1, 'day').startOf('day'), moment().endOf('day')],
	this_year: [moment().subtract(1, 'year').add(1, 'day').startOf('day'), moment().endOf('day')],
	all_time: [null, null],
};
