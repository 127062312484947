import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import cn from 'classnames';
import { Typography } from '@mui/material';

import s from './styles.module.scss';

interface IOwnProps extends React.HTMLProps<HTMLTextAreaElement> {
	hasPlaceholder?: boolean;
	dataTestid?: string | number | null;
	className?: string;
	title?: any;
	classNameTitle?: string;
	classNameRoot?: string;
	labelBrother?: React.ReactNode;
}

export const TextArea = forwardRef<HTMLTextAreaElement, IOwnProps>(
	(
		{
			className = '',
			hasPlaceholder,
			onChange,
			title,
			classNameTitle,
			classNameRoot,
			dataTestid,
			labelBrother,
			rows,
			cols,
			value,
			...props
		},
		ref: ForwardedRef<HTMLTextAreaElement>,
	) => {
		const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
			if (onChange) {
				onChange(event);
			}
		};

		return (
			<div className={cn(s.textarea__wrap, classNameRoot)}>
				{title && (
					<div className={s.textarea__head}>
						<Typography className={cn(s.textarea__title, classNameTitle)}>{title}</Typography>
						{labelBrother}
					</div>
				)}
				<textarea
					ref={ref}
					data-testid={dataTestid}
					onChange={handleChange}
					value={value}
					className={cn(s.textarea, className, {
						hasPlaceholder,
					})}
					rows={rows}
					cols={cols}
					{...props}
				/>
			</div>
		);
	},
);
