import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
	sttLanguage: Yup.object().shape({
		value: Yup.string().required(),
		label: Yup.string().required(),
	}),
	sttEngine: Yup.object().shape({
		value: Yup.string().required(),
		label: Yup.string().required(),
	}),
	sttModel: Yup.object().shape({
		value: Yup.string().required(),
		label: Yup.string().required(),
	}).nullable().required(),
});