import { unitsOfTime } from 'utils/unitsOfTime';
import { ReportInitialStateType } from './reports.types';

const calls = {
	call_ids: [],
	call_ids_hash: '',

	calls_count: 0,
	calls_seconds: 0,
	percent_from_total_count: 0,

	client_talk_time: 0,
	client_talk_time_percentage: 0,

	first_call_dt: '',
	last_call_dt: '',

	operator_talk_time: 0,
	operator_talk_time_percentage: 0,

	silence_duration: 0,
	silence_duration_percentage: 0,

	tags_count: 0,
};

const diffCalls = {
	calls_count: 0,
	calls_seconds: 0,
	percent_from_total_count: 0,

	client_talk_time: 0,
	client_talk_time_percentage: 0,

	operator_talk_time: 0,
	operator_talk_time_percentage: 0,

	silence_duration: 0,
	silence_duration_percentage: 0,

	tags_count: 0,
};

export const initialState: ReportInitialStateType = {
	allReports: [],
	currentSavedReport: { value: '', label: '' },
	isSavedReport: false,
	isReportByHash: false,
	isReportById: false,
	isReportCollapsed: false,
	callReport: {
		report: {
			cols: [
				{
					group_id: 0,
					col_name: '',
				},
			],
			cols_info: {
				any: {
					any: {
						col_all_calls: calls,
						col_missed_calls: calls,
						col_sum_calls: calls,
					},
				},
			},
			filtered_calls_count: 0,
			row_group_header: '',
			rows: [],
			total_calls_count: 0,

			values: {
				any: {
					col_groups: {
						any: {
							any: calls,
						},
					},
					row_info: {
						row_all_calls: calls,
						row_missed_calls: calls,
						row_sum_calls: calls,
					},
				},
			},
		},
		report_hash: '',

		report_parameters_hash: '',
	},

	reportWebSocket: null,

	diffReport: {
		cols_info: {
			any: {
				any: {
					col_all_calls: diffCalls,
					col_missed_calls: diffCalls,
					col_sum_calls: diffCalls,
				},
			},
		},
		rows: {
			any: {
				col_groups: {
					any: {
						any: diffCalls,
					},
				},
				row_info: {
					row_all_calls: diffCalls,
					row_missed_calls: diffCalls,
					row_sum_calls: diffCalls,
				},
			},
		},
	},
	diffReportIsLoaded: false,

	activeReport: {
		report_name: '',
		report_type: 'calls',
		period: 'today',
		from_dt: unitsOfTime.today[0].utc(true).format(),
		to_dt: unitsOfTime.today[1].utc(true).format(),
		view_options: {
			show_table: true,
			show_chart: false,
			show_diff_report: false,
			show_zero_rows: true,
			show_zero_cols: true,
			show_zero_grouping_rows: true,
			show_zero_grouping_cols: true,
			show_calls_count: true,
			show_minutes: false,
			show_percentage: false,
			show_row_calls_sum: true,
			show_col_calls_sum: true,
			show_row_all_calls_sum: false,
			show_col_all_calls_sum: false,
			show_row_calls_missed: false,
			show_col_calls_missed: false,

			show_client_time: false,
			show_call_dt: false,
			show_operator_time: false,
			show_silence_time: false,
			show_tags_count: false,
			show_checklist_average: true,
			show_checklist_average_percent: true,
			show_client_phones: false,
			show_operator_phones: false,
			show_points: false,
			show_max_points: false,
			show_from_services: false,
		},
		rows_group_by: {
			group_by: '',
			value: null,
		},
		cols_group_by: [
			{
				group_by: '',
			},
		],
		call_search_items: [],
	},
	activeParameters: [],

	// сегодня
	date: unitsOfTime.today,
	selectors: {
		cols_groupings: [],
		groupings_by_time: [],
		report_types: [],
		rows_groupings: [],
	},

	tagNames: [],
	tagNamesWithValues: [],
	tagRowValues: [],
	tagColumnValues: [],
	tagColumnsAddValues: [],

	tableRows: [],
	tableColumns: [],
	tableAllRows: [],

	isResetParameters: false,
	isLoading: false,
	reportLoadingIsStarted: false,
	loadingReport: {
		status: '',
		stage_name: '',
		stage_idx: 0,
		done: 0,
		total: 0,
		done_part: 0,
	},
	chartType: { value: '', label: '' },

	isValidTagList: false,
	isValidTagTitle: false,
	isValidSearchTitle: false,
};

export default initialState;
