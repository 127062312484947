/* eslint-disable react/no-unstable-nested-components */
import React, { FC, useEffect, useState, memo } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { searchSlice } from '../../../store';
import { CrossSvgWithBg, MinusSvg, OnBottomArrow, OnTopArrow, PlusSvg } from './AddFilterSelect.svg';
import { createTextSelectStyles, useMuiTextSelectStyles } from './AddFilterSelect.jss';
import { useAppSelector } from '../../../hooks/redux';
import { CriteriasType } from '../../../store/search/search.types';

// TYPES BLOCK
export type SelectValueFormat = {
	value: any | null;
	label: string | null;
};

type TextSelectPropsType = {
	value: SelectValueFormat | null;
	options: SelectValueFormat[];

	iconPosition?: 'left' | 'right';
	icon?: any;

	customControl: any;

	menuPosition: 'left' | 'right';

	width?: string;
	height?: string;
	ifArrowColor?: string;

	name: string;

	setCriterias?: (keys: CriteriasType[]) => void;
	removeCriterias?: (keys: CriteriasType[]) => void;
	setCriteria: (key: CriteriasType) => void;
	removeCriteria: (key: CriteriasType) => void;
	activeCriterias: CriteriasType[];
};

const AddFilterSelect: FC<TextSelectPropsType> = ({
	value,
	options,
	icon,
	iconPosition,
	customControl,
	menuPosition,
	width,
	height,
	ifArrowColor,

	name,
	setCriterias,
	removeCriterias,
	removeCriteria,
	setCriteria,
	activeCriterias,
}) => {
	const customSelectStyles = createTextSelectStyles({ width, menuPosition });
	const classes = useMuiTextSelectStyles({ arrowColor: ifArrowColor });
	const dispatch = useDispatch();

	// открыте и закрытие менюшки.
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	// выполняется клике на селекта.
	const handleSelectClick = () => {
		setIsMenuOpen(true);
	};

	// COMPONENTS BLOCK
	const Arrow = memo(() => {
		if (isMenuOpen) {
			return (
				<div className={classes.selectArrowOnTopBox}>
					<OnTopArrow className={classes.selectArrow} />
				</div>
			);
		}
		return <OnBottomArrow className={classes.selectArrow} />;
	});
	const Control: FC<any> = ({ children, ...props }) => (
		<div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', margin: '2px' }}>
			<div style={{ display: 'flex', alignItems: 'center' }} onClick={handleSelectClick}>
				{iconPosition === 'left' ? icon || <Arrow /> : null}
				{customControl}
				{iconPosition === 'right' ? icon || <Arrow /> : null}
			</div>
			{name === 'templatesSelect' && value && (
				<CrossSvgWithBg
					style={{ fill: '#722ED1', cursor: 'pointer', marginLeft: '15px' }}
					onClick={() => {
						dispatch(searchSlice.actions.setCurrentTemplate(null));
						dispatch(searchSlice.actions.removeAllActiveCriterias({ page: 'calls', data: null }));
					}}
				/>
			)}
		</div>
	);
	const [expanded, setExpanded] = React.useState<string[]>([]);

	// LOGIC BLOCK

	const CustomMenuList = memo(({ selectProps, ...props }: any) => {
		const allCriterias = useAppSelector((state) => state.search.allCriterias);

		function handleChange(panel: string) {
			let localExpanded = [...expanded];
			if (!localExpanded.includes(panel)) {
				localExpanded = [...expanded, panel];
			} else {
				const panelInExpanded = localExpanded.find((key) => key === panel);
				if (panelInExpanded) {
					const panelIndex = localExpanded.indexOf(panelInExpanded);
					localExpanded.splice(panelIndex, 1);
				}
			}
			setExpanded(localExpanded);
		}

		const Accordion = styled((props: AccordionProps) => (
			<MuiAccordion disableGutters elevation={0} square {...props} />
		))(({ theme }) => ({
			border: 'none !important',
			// marginBottom: '18px',
			// border: `1px solid ${theme.palette.divider}`,
			// '&:not(:last-child)': {
			//   borderBottom: 0,
			// },
			'&:before': {
				display: 'none',
			},
		}));

		const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(
			({ theme }) => ({
				backgroundColor: '#FFF',
				border: 'none !important',
				padding: '0px 12px',
				marginBottom: '3px',
				'& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
					transform: 'rotate(90deg)',
				},
				'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
					transform: 'rotate(180deg)',
				},
				minHeight: '0 !important',
				'& .MuiAccordionSummary-content': {
					margin: '0 !important',
				},
			}),
		);

		const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
			padding: theme.spacing(2),
			// borderTop: '1px solid rgba(0, 0, 0, .125)',
		}));

		function sortCriteriasByGroups() {
			const groups: { groupName: string; criterias: CriteriasType[] }[] = [];

			allCriterias.forEach((criteria) => {
				let index = 0;
				const examination = groups.find((group) => {
					index = groups.indexOf(group);
					return group.groupName === criteria.groupName;
				});
				if (examination) {
					groups[index].criterias.push(criteria);
				} else {
					groups.push({ groupName: criteria.groupName, criterias: [criteria] });
				}
			});
			return groups;
		}

		const sortedCriteriasByGroups = sortCriteriasByGroups();

		function isCriteriasActive(criterias: CriteriasType[], activeCriteriasArray: CriteriasType[]): boolean {
			for (let i = 0; i < criterias.length; i++) {
				const currentCriteria = criterias[i];
				if (!activeCriteriasArray.find((activeCriteria) => activeCriteria.key === currentCriteria.key)) {
					return false;
				}
			}
			return true;
		}

		return (
			<div style={{ maxHeight: '400px' }}>
				{sortedCriteriasByGroups.map((group) => (
					<Accordion expanded key={group.groupName}>
						<AccordionSummary id={group.groupName}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									width: '100%',
								}}
							>
								<Typography className={classes.groupName}>{group.groupName}</Typography>
							</div>
						</AccordionSummary>
						<AccordionDetails style={{ padding: '0' }}>
							{group.criterias.map((criteria) => (
								<div
									key={criteria.key}
									onClick={() => {
										if (!isCriteriasActive([criteria], activeCriterias)) setCriteria(criteria);
										else removeCriteria(criteria);
									}}
									className={classes.selectOption}
								>
									<Typography className={classes.groupItem}>{criteria.title}</Typography>
									{!isCriteriasActive([criteria], activeCriterias) ? (
										<PlusSvg style={{ width: '10px' }} />
									) : (
										<MinusSvg style={{ width: '10px' }} />
									)}
								</div>
							))}
						</AccordionDetails>
					</Accordion>
				))}
			</div>
		);
	});

	// костылечек для загрытия менюшки.
	useEffect(() => {
		document.addEventListener('mousedown', () => setIsMenuOpen(false));
		return () => {
			document.removeEventListener('mousedown', () => setIsMenuOpen(false));
		};
	}, []);

	return (
		<div className={classes.selectBox}>
			<Select
				styles={customSelectStyles}
				options={options}
				value={value}
				openMenuOnFocus
				menuIsOpen={isMenuOpen}
				isClearable
				closeMenuOnSelect={false}
				isSearchable={false}
				hideSelectedOptions={false}
				menuPlacement="auto"
				components={{
					IndicatorsContainer: () => null,
					Control,
					MenuList: CustomMenuList,
					Option: () => null,
				}}
			/>
		</div>
	);
};

export default AddFilterSelect;
