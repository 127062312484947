import { FC, useState } from 'react';
import { Typography } from '@mui/material';
import { Pensil } from 'components/Icons/PensilIcon';
import { RedTrash } from 'components/Icons/RedTrashIcon';
import { EditableListItemProps } from './types';
import styles from './styles.module.scss';
import EditableInput from '../EditableInput/EditableInput';
import TagComponent from '../TagComponent/TagComponent';
import ActionIconButton from '../ActionIconButton/ActionIconButton';
import CustomSwitch from '../CustomSwitch/CustomSwitch';

const EditableListItem: FC<EditableListItemProps> = (props) => {
	const {
		editableValue,
		onEditableConfirm,
		editableSize = 'medium',
		tagTitle,
		tagBackgroundColor = '#EFDBFF',
		tagTextColor = '#531DAB',
		tagBorderColor = '#B37FEB',
		tagAdditionalStyles,
		editTooltipText,
		language,
		buttonSize = 'small',
		onDelete,
		deleteTooltipText,
		deletePlacement,
		deleteButtonSize,
		switchChecked,
		onSwitchChecked,
		switchDisabled,
		switchDataTestid,
		switchTooltipText,
		switchPlacement,
		onListItemClick,
		isSelectedRule,
	} = props;

	const [loading, setLoading] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [localSwitchChecked, setLocalSwitchChecked] = useState(switchChecked);

	const onChecked = (event: React.MouseEvent) => {
		event.stopPropagation();
		setLocalSwitchChecked(!localSwitchChecked);
		if (onSwitchChecked) {
			onSwitchChecked();
		}
	};

	return (
		<div
			className={`${styles.root} ${isSelectedRule ? styles.selected : ''}`}
			onClick={() => {
				if (onListItemClick) {
					onListItemClick();
				}
			}}
		>
			<div className={styles.content}>
				<div className={styles.title}>
					{editMode ? (
						<EditableInput
							value={editableValue}
							onConfirm={(newValue) => {
								setLoading(true);
								onEditableConfirm(newValue);
								setLoading(false);
								setEditMode(false);
							}}
							onCancel={() => {
								setEditMode(false);
							}}
							size={editableSize}
						/>
					) : (
						<Typography className={styles.defaultTitle}>{editableValue}</Typography>
					)}
				</div>
				<TagComponent
					title={tagTitle || ''}
					backgroundColor={tagBackgroundColor}
					textColor={tagTextColor}
					borderColor={tagBorderColor}
					additionalStyles={tagAdditionalStyles}
				/>
			</div>
			<div className={styles.controls}>
				<div className={styles.buttons}>
					<ActionIconButton
						tooltipText={editTooltipText}
						language={language}
						onClickHandler={(event: React.MouseEvent) => {
							event.stopPropagation();
							setEditMode(!editMode);
						}}
						icon={<Pensil />}
						size={buttonSize}
					/>
					<ActionIconButton
						tooltipText={deleteTooltipText}
						language={language}
						onClickHandler={async (event: React.MouseEvent) => {
							event.stopPropagation();
							setLoading(true);
							await onDelete();
							setLoading(false);
						}}
						icon={<RedTrash />}
						placement={deletePlacement}
						setLoading={setLoading}
						size={deleteButtonSize}
					/>
				</div>

				<CustomSwitch
					onChecked={onChecked}
					disabled={switchDisabled}
					checked={localSwitchChecked || false}
					dataTestid={switchDataTestid}
					switchTooltipText={switchTooltipText}
					placement={switchPlacement}
				/>
			</div>
		</div>
	);
};

export default EditableListItem;
