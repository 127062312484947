import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/redux';
import { reportsSlice, searchSlice } from 'store';
import { useHistory, useParams } from 'react-router-dom';
import { pathCreateReport } from '../const/const';
import { RouteParams } from '../types/ReportsTypes';

const useZeroParameters = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const selectorsValues = useAppSelector((state) => state.reports.selectors);
	const { lang, userId } = useParams<RouteParams>();

	const zeroParameters = () => {
		dispatch(searchSlice.actions.removeAllActiveCriteriasReports());
		dispatch(reportsSlice.actions.setInitialSavedReport());
		dispatch(reportsSlice.actions.setInitialChart());
		dispatch(reportsSlice.actions.resetReportParameters());
		dispatch(reportsSlice.actions.setInitialIsSavedReport());
		dispatch(reportsSlice.actions.setInitialReportParameters(selectorsValues));
		dispatch(searchSlice.actions.removeAllActiveCriterias({ page: 'reports', data: null }));
	};

	const clearValidate = () => {
		dispatch(reportsSlice.actions.setValidTagList(false));
		dispatch(reportsSlice.actions.setValidTagTitle(false));
		dispatch(reportsSlice.actions.setValidSearchTitle(false));
	};

	const setReportCollapsedOpen = () => {
		dispatch(reportsSlice.actions.setReportCollapsed(false));
	};

	const btnResetParameters = () => {
		dispatch(reportsSlice.actions.setResetParameters(true));
	};

	const resetUrl = () => {
		history.push(`/${lang}/${userId}/${pathCreateReport}`);
	};

	const resetAllForNewReport = () => {
		zeroParameters();
		clearValidate();
		setReportCollapsedOpen();
	};

	return {
		zeroParameters,
		clearValidate,
		btnResetParameters,
		resetAllForNewReport,
		resetUrl,
	};
};

export default useZeroParameters;
