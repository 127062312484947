import React, { FC } from 'react';
import { InputBase } from '@mui/material';
import { LangType } from 'store/lang/lang.slice';
import Field from 'components/common/Field/Field';
import { CheckSvg } from 'components/Icons/CheckIcon';
import { CloseSvg } from 'components/Icons/CloseIcon';
import { EditSvg } from 'components/Icons/EditSvg';
import TooltipCustom from 'components/common/Tooltip/Tooltip';
import styles from './EditableInputBordered.module.scss';
import ActionIconButton from '../ActionIconButton/ActionIconButton';

interface EditTilteProps {
	isOpenedEditing: boolean;
	title: string;
	name: string;
	language: LangType | string;
	autoFocus: boolean;
	required: boolean;
	onChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClickCheck: () => void;
	onClickClose: () => void;
	onClickHandlerEditIcon: () => void;
	hasTooltip?: boolean;
}

const EditableInputBordered: FC<EditTilteProps> = ({
	isOpenedEditing,
	title,
	name,
	language,
	autoFocus,
	required,
	onChangeInput,
	onClickCheck,
	onClickClose,
	onClickHandlerEditIcon,
	hasTooltip,
}) => {
	const onKeyDownInput = async (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.stopPropagation();
			onClickCheck();
		}
	};

	return (
		<div className={styles.editBlock}>
			{isOpenedEditing ? (
				<div className={styles.wrapper}>
					<Field label="" width="100%" height="100%" borderColor="#9254DE" margin="0 8px 0 0 ">
						<InputBase
							autoFocus={autoFocus}
							required={required}
							value={title}
							onChange={onChangeInput}
							onKeyDown={onKeyDownInput}
							name={name}
							type="text"
							fullWidth
						/>
					</Field>

					<ActionIconButton
						tooltipText="saveButton"
						language={language}
						onClickHandler={onClickCheck}
						icon={<CheckSvg />}
					/>

					<ActionIconButton
						tooltipText="resetButton"
						language={language}
						onClickHandler={onClickClose}
						icon={<CloseSvg />}
					/>
				</div>
			) : (
				<div className={styles.wrapper}>
					{hasTooltip ? (
						<TooltipCustom background="dark" title={title} placement="bottom" followCursor={false}>
							<div className={styles.title}>{title}</div>
						</TooltipCustom>
					) : (
						<div className={styles.title}>{title}</div>
					)}
					<ActionIconButton
						tooltipText="editName"
						language={language}
						onClickHandler={onClickHandlerEditIcon}
						icon={<EditSvg />}
					/>
				</div>
			)}
		</div>
	);
};

export default EditableInputBordered;
