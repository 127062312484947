export const getRequestURL = (
	type: 'group' | 'item',
	page: string,
	id: string,
) => {
	let base = '';
	if (type === 'group' && page === 'tags') {
		base = 'tag_rule_group';
	}
	if (type === 'group' && page === 'dicts') {
		base = 'dict_group';
	}
	if (type === 'item' && page === 'tags') {
		base = 'tag_rule';
	}
	if (type === 'item' && page === 'dicts') {
		base = 'dict';
	}
	return `${base}/${id}/clone`;
};
